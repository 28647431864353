export const PROJECT_STATUS_NEGOTIATION = 1;
export const PROJECT_STATUS_CONTRACTED = 2;
export const PROJECT_STATUS_DEPOSIT_COMPLETED = 3;
export const PROJECT_ORDER_REGISTRATION = 1;
export const PROJECT_ORDER_SCHEDULE = 2;

// 5 MB
export const IMAGE_FILE_MAX_SIZE = 5 * 1000 * 1000;

// 10 MB
export const CONTRACT_FILE_MAX_SIZE = 10 * 1000 * 1000;

export const CONTRACT_FILE_ALLOWED_FILE_TYPES = [
  'application/pdf',
  'application/zip',
  'application/msword',
  'application/epub+zip',
  'application/ogg',
  'application/rtf',
  'application/vnd.apple.pages',
  'application/x-iwork-pages-sffpages',
  'application/vnd.apple.keynote',
  'application/x-iwork-keynote-sffkey',
  'application/x-iwork-numbers-sffnumbers',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  'audio/ogg',
  'video/ogg',
  'image/bmp',
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/webp',
  'image/svg+xml',
  'image/tiff',
  'image/heif',
  'image/heic',
  'image/heif-sequence',
  'image/vnd.microsoft.icon',
  'text/plain',
  'text/calendar',
  'text/csv',
];
