import { ref, getDownloadURL, getBlob } from 'firebase/storage';

import { storage } from 'utils/firebase';

export const useGetFirebaseFile = () => {
  const getFileDownload = async (fileName: string) => {
    const fileBlob = await getBlob(ref(storage, fileName));
    const fileURL = await getDownloadURL(ref(storage, fileName));
    return { fileBlob, fileURL };
  };

  return { getFileDownload };
};
