import { IonCard, IonCardContent, IonContent, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { FC, useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import { useGetMessage } from 'api/hooks/chat/useGetMessage';
import styles from './ChatModelContent.module.scss';
import { ChatAttachmentFiles } from './ChatAttachmentFiles';
import { SENDER_CREATOR, IMAGE_TYPES } from 'consts';
import { dayjs } from 'utils/dayjs';
import { useAtom } from 'jotai';
import { projectListAtom } from 'stores';

const SHOW_PROJECT_LIMIT = 20;

const isImage = (contentURL: string) => {
  const fileType = contentURL?.split('.')?.pop() ?? '';
  return IMAGE_TYPES.includes(fileType);
};

interface ChatModelContentProps {
  clientName: string;
}

export const ChatModelContent: FC<ChatModelContentProps> = ({ clientName }) => {
  const chatRoomKey = useParams<{ chatRoomKey: string }>().chatRoomKey;
  const scrollBottomRef = useRef<HTMLDivElement>(null);
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);

  const { messages } = useGetMessage(chatRoomKey, SENDER_CREATOR);
  const [offset, setOffset] = useState(-1);
  const [, setProjectListAtomValue] = useAtom(projectListAtom);

  const showMoreProjects = () => {
    if (offset > 0 && messages.length > 0) {
      // offsetが設定済の場合、もっと多く表示する
      let newOffset = offset - SHOW_PROJECT_LIMIT;
      if (newOffset < 0) {
        newOffset = 0;
      }
      setOffset(newOffset);
    }
    // 無限スクロール動画表示を消す
    infiniteScrollRef.current?.complete();
  };

  useEffect(() => {
    setProjectListAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
  }, []);

  useEffect(() => {
    if (offset === -1 && messages.length > 0) {
      // offsetが未設定の場合、最大Limit件数まで表示する
      let newOffset = messages.length - SHOW_PROJECT_LIMIT;
      if (newOffset < 0) {
        newOffset = 0;
      }
      setOffset(newOffset);
    }

    if (messages.length > 0) {
      // 新しいメッセージを送信した場合、再描画が時間かかるためスクロール処理を遅延する
      setTimeout(() => {
        scrollBottomRef?.current?.scrollIntoView();
      }, 2000);
    }
  }, [messages]);

  // offsetが設定されるまでは描画しない
  if (offset === -1) return null;

  // デフォルトでは最新のLIMIT件のみを表示
  const displayingMessages = offset > 0 ? messages.slice(offset) : messages;

  return (
    <IonContent className={styles.chatBackGround}>
      <IonInfiniteScroll
        onIonInfinite={showMoreProjects}
        ref={infiniteScrollRef}
        position="top"
        disabled={offset === 0}
      >
        <IonInfiniteScrollContent className={styles.chatInfiniteScrollContent}></IonInfiniteScrollContent>
      </IonInfiniteScroll>
      {displayingMessages?.map((message) => (
        <div className={styles.chatContent} key={message.createdAt.toMillis()}>
          {message.senderType === SENDER_CREATOR ? (
            <>
              <div className={styles.chatContentModelHeader}>
                <p className={styles.chatContentHeaderDatetime}>
                  {dayjs.tz(message.createdAt.toDate()).format('MM.DD.HH:mm')}
                </p>
              </div>
              {message.body ? (
                <div className={styles.chatCardWrap}>
                  <IonCard className={styles.chatModelCard}>
                    <IonCardContent className={styles.chatCardContent}>{message.body}</IonCardContent>
                  </IonCard>
                </div>
              ) : null}
              {message.attachmentFiles[0].contentURL && (
                <div className={styles.chatCardWrap}>
                  <IonCard
                    className={
                      isImage(message.attachmentFiles[0].contentURL) ? styles.chatModelCardImg : styles.chatModelCard
                    }
                  >
                    <ChatAttachmentFiles
                      contentURL={message.attachmentFiles[0].contentURL}
                      fileName={message.attachmentFiles[0].name}
                    />
                  </IonCard>
                </div>
              )}
              <div ref={scrollBottomRef} />
            </>
          ) : (
            <>
              <div className={styles.chatContentClientHeader}>
                <p className={styles.chatContentHeaderCompanyName}>{clientName}</p>
                <p className={styles.chatContentHeaderDatetime}>
                  {dayjs.tz(message.createdAt.toDate()).format('MM.DD.HH:mm')}
                </p>
              </div>
              {message.body ? (
                <div>
                  <IonCard className={styles.chatClientCard}>
                    <IonCardContent className={styles.chatCardContent}>{message.body}</IonCardContent>
                  </IonCard>
                </div>
              ) : null}
              {message.attachmentFiles[0].contentURL && (
                <IonCard
                  className={
                    isImage(message.attachmentFiles[0].contentURL) ? styles.chatClientCardImg : styles.chatClientCard
                  }
                >
                  <ChatAttachmentFiles
                    contentURL={message.attachmentFiles[0].contentURL}
                    fileName={message.attachmentFiles[0].name}
                  />
                </IonCard>
              )}
              <div ref={scrollBottomRef} />
            </>
          )}
        </div>
      ))}
    </IonContent>
  );
};
