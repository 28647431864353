import { Client } from './client';

export interface Project {
  id: number;
  name: string;
  status: number;
  startDate: string;
  endDate: string;
  client: Client;
  contractPrice: number;
  description: string;
  thumbnailFileUrl: string;
  contractFileUrl: string;
  paymentDueDate: string;
  paymentDate: string | null;
}

export enum ProjectStatus {
  // 交渉中
  Negotiating = 1,
  // 契約済み
  Contracted = 2,
  // 入金済み
  Paid = 3,
}

export interface ProjectFormValues {
  name: string | null;
  status: number | null;
  startDate: string | null;
  endDate: string | null;
  clientId: number | null;
  description: string | null;
  contractPrice: number | null;
  imageFile: FileList | null;
  contractFile: FileList | null;
  paymentDueDate: string | null;
  paymentDate: string | null;
}
