import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFooter } from '@ionic/react';

import { Setting } from 'components/setting/Setting';
import { TabBar } from 'components/common/TabBar';

import { withLogin } from 'pages/hocs/withLogin';

export const SettingPage: React.FC = withLogin(() => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonTitle>設定</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Setting />
      </IonContent>
      <IonFooter>
        <TabBar selectedTab="setting" />
      </IonFooter>
    </IonPage>
  );
});
