import { IonButton, IonInput, useIonAlert } from '@ionic/react';
import { FC, useState } from 'react';

import styles from './EditForm.module.scss';

import { DeepMap, DeepPartial, FieldError, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { WarningMessage } from 'components/common/WarningMessage';
import { Client, ClientFormValues } from 'models';
import { isIos } from 'utils/device';

interface ClientRegisterFormProps {
  register: UseFormRegister<ClientFormValues>;
  setValue: UseFormSetValue<ClientFormValues>;
  errors: DeepMap<DeepPartial<ClientFormValues>, FieldError>;
  watch: UseFormWatch<ClientFormValues>;
  chatUpdate: () => void;
  hasEmail: boolean;
  originalClient: Client | undefined;
}

export const EditForm: FC<ClientRegisterFormProps> = ({
  register,
  setValue,
  errors,
  watch,
  chatUpdate,
  hasEmail,
  originalClient,
}) => {
  const [isMailAddressFocused, setIsMailAddressFocused] = useState(false);
  const [presentAlert] = useIonAlert();

  const phoneNumberValidation = (value: string | null): string | true => {
    // 値が入力されていない場合、必須入力ではないためtrueとして扱う
    if (!value) return true;

    if (!/^0/.test(value)) {
      return '0から始まる半角数字を入力してください';
    }

    if (!/^\d+$/.test(value)) {
      return '半角数字のみを入力してください';
    }

    if (!/^\d{10,11}$/.test(value)) {
      return '10桁または11桁の半角数字で入力してください';
    }
    return true;
  };

  // 入力フォーム内容が一つでも変更されたか
  const isValueChanged = () => {
    return (
      watch('name') !== originalClient?.name ||
      watch('departmentName') !== originalClient?.departmentName ||
      watch('staff') !== originalClient?.staff ||
      watch('address') !== originalClient?.address ||
      watch('phone') !== originalClient?.phone ||
      watch('email') !== originalClient?.email
    );
  };

  const handleClickChatUpdateButton = () => {
    if (isValueChanged()) {
      presentAlert({
        message: '保存していない情報があります。URLを更新する場合、先に保存をしてください。',
        buttons: [
          {
            text: 'OK',
          },
        ],
        mode: 'ios',
      });
    } else {
      chatUpdate();
    }
  };

  const focusedCls = isIos() && isMailAddressFocused ? styles.focused : '';

  return (
    <div className={`${styles.wrap} ${focusedCls}`}>
      <div className="clientInputBlock">
        <div className={styles.inputInfoSection}>企業名※</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="株式会社COLORFULLY"
          type="text"
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('name', e.target.value)}
          maxlength={150}
          {...register('name', {
            required: {
              value: true,
              message: '企業名の入力が必要です',
            },
          })}
        />
        <div className="attentionBlock">{errors.name?.message && <WarningMessage message={errors.name.message} />}</div>
        <div className={styles.inputInfoSection}>担当部署</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="クリエイティブ事業部"
          type="text"
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('departmentName', e.target.value)}
          maxlength={150}
          {...register('departmentName')}
        />
        <div className={styles.inputInfoSection}>担当者※</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="山田太郎"
          type="text"
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('staff', e.target.value)}
          maxlength={150}
          {...register('staff', {
            required: {
              value: true,
              message: '担当者の入力が必要です',
            },
          })}
        />
        <div className="attentionBlock">
          {errors.staff?.message && <WarningMessage message={errors.staff.message} />}
        </div>
        <div className={styles.inputInfoSection}>住所</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="東京都渋谷区神宮前1-2-3 ABCビル456"
          type="text"
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('address', e.target.value)}
          maxlength={150}
          {...register('address')}
        />
        <div className={styles.inputInfoSection}>連絡先電話番号</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="09090001234"
          type="tel"
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('phone', e.target.value)}
          maxlength={11}
          {...register('phone', {
            validate: phoneNumberValidation,
          })}
        />
        <div className="attentionBlock">
          {errors.phone?.message && <WarningMessage message={errors.phone.message} />}
        </div>
        <div className={styles.inputInfoSection}>連絡先メールアドレス</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="example@colorfully.jp"
          type="email"
          onIonFocus={() => {
            setIsMailAddressFocused(true);
          }}
          onIonBlur={() => {
            setIsMailAddressFocused(false);
          }}
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('email', e.target.value)}
          {...register('email', {
            pattern: {
              value: /[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z.-_]{2,}/,
              message: '正しいメールアドレスを入力してください',
            },
          })}
        />
        <div className="attentionBlock">
          {errors.email?.message && <WarningMessage message={errors.email.message} />}
        </div>
        <IonButton
          expand="block"
          fill="outline"
          className={styles.chatUrlButton}
          size="large"
          strong
          mode="ios"
          disabled={!hasEmail}
          onClick={handleClickChatUpdateButton}
        >
          チャットURLの更新
        </IonButton>
      </div>
    </div>
  );
};
