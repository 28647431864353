import React from 'react';
import { IonButton, IonContent, IonImg, IonPage } from '@ionic/react';
import { useHistory } from 'react-router-dom';

import styles from './WelcomeContent.module.scss';

export const WelcomeContent: React.FC = () => {
  const history = useHistory();

  const handleClickLoginButton = () => {
    history.push('/login?prev_url=welcome');
  };

  const handleClickRegisterButton = () => {
    history.push('/user/register?prev_url=welcome');
  };

  return (
    <IonPage>
      <IonContent no-onbounce forceOverscroll={false}>
        <div className={styles.welcome}>
          <div className={styles.eyecatch}>
            <div className={styles.logo}>
              <IonImg src={'assets/welcome/logo.png'} alt="COLORFULLY" />
            </div>
          </div>
          <div className={styles.buttons}>
            <IonButton className={styles.button} mode="ios" fill="clear" expand="full" onClick={handleClickLoginButton}>
              ログイン
            </IonButton>
            <IonButton
              className={styles.button}
              mode="ios"
              fill="clear"
              expand="full"
              onClick={handleClickRegisterButton}
            >
              ユーザー登録
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
