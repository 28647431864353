import React, { useState, useRef } from 'react';
import {
  IonContent,
  IonHeader,
  IonInput,
  IonModal,
  IonIcon,
  IonTitle,
  IonToolbar,
  IonButton,
  useIonAlert,
  useIonLoading,
} from '@ionic/react';

import { useBasicAuth } from 'api/hooks/admin';
import { useBasicAuthInfo, setCredentialsToLocalStorage } from 'basicAuth';
import { dayjs } from 'utils/dayjs';

import styles from './BasicAuthModal.module.scss';

export const BasicAuthModal: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);

  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const { getBasicAuth } = useBasicAuth();
  const { updateAuthInfo } = useBasicAuthInfo();

  // 入力中の要素のRef、パスワードToggleボタンクリックした後にフォーカスを元に戻すために利用する
  const [inputtingElement, setInputtingElement] = useState<(EventTarget & HTMLIonInputElement) | null>(null);
  // Blur後に記憶した入力中要素をクリアするか
  // ・他の入力項目にフォーカスまたはToogleボタンをクリックした場合はクリアしない
  // ・それ以外の場合は、クリアする
  const shouldClearInputRef = useRef(true);

  const handleInputBlur = () => {
    // パスワードToggleボタンのクリックであるかを判断するために遅延する
    setTimeout(() => {
      // パスワードToggleボタンがクリックされなかった場合、フォーカスを戻す必要がないため、入力中項目をクリアする
      if (shouldClearInputRef.current) {
        setInputtingElement(null);
      }
      shouldClearInputRef.current = true;
    }, 300);
  };

  const handleClickTogglePassword = () => {
    inputtingElement?.focus();
    shouldClearInputRef.current = false;
    setPasswordShown(!passwordShown);
  };

  const doLogin = () => {
    if (!username || !password) return;

    // BASIC認証文字列
    const credentials = window.btoa(`${username}:${password}`);

    // ここでベーシック認証の処理を実装します
    // ユーザー名とパスワードはusernameとpasswordで利用できます
    // 認証が成功した場合は、onDismiss()を呼び出してモーダルを閉じます
    presentLoading({
      duration: 2000,
    });

    // BASIC認証API コール
    getBasicAuth(credentials)
      .then(() => {
        // 認証成功した場合
        const authInfo = {
          credentials,
          expiresAt: dayjs().add(1, 'days').valueOf(), // 1日有効にする
        };
        // 認証情報をローカルストレージに保存
        setCredentialsToLocalStorage(authInfo);
        // 認証情報をコンテキストに更新
        updateAuthInfo(authInfo);
      })
      .catch(() => {
        presentAlert({
          header: '認証エラー',
          message: '基本認証が失敗しました。',
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      })
      .finally(() => {
        dismissLoading();
      });
  };

  return (
    <IonModal isOpen canDismiss={false}>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonTitle>ログイン</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={styles.wrap}>
          <div className="mypage__inputBlock">
            <div className="input__infoSection">ユーザー名</div>
            <IonInput
              value={username}
              className="ion-input__commonText"
              type="text"
              maxlength={150}
              onIonChange={(e) => setUsername(e.detail?.value || '')}
              onFocus={(e) => {
                setInputtingElement(e.target);
                shouldClearInputRef.current = false;
              }}
              onBlur={handleInputBlur}
            />
          </div>
          <div className="mypage__inputBlock">
            <div className="input__infoSection">パスワード</div>
            <div className={styles.row}>
              <IonInput
                value={password}
                className="ion-input__commonText"
                type={passwordShown ? 'text' : 'password'}
                maxlength={150}
                onIonChange={(e) => setPassword(e.detail.value || '')}
                onFocus={(e) => {
                  setInputtingElement(e.target);
                  shouldClearInputRef.current = false;
                }}
                onBlur={handleInputBlur}
              />
              {passwordShown ? (
                <IonIcon
                  className={styles.eye}
                  onClick={handleClickTogglePassword}
                  slot="end"
                  src="assets/common/icn__eye-close.svg"
                />
              ) : (
                <IonIcon
                  className={styles.eye}
                  onClick={handleClickTogglePassword}
                  slot="end"
                  src="assets/common/icn__eye.svg"
                />
              )}
            </div>
          </div>
          <div className={styles.buttons}>
            <IonButton className={styles.button} onClick={doLogin}>
              ログイン
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};
