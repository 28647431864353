import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import { IonButton } from '@ionic/react';
import Modal from 'react-modal';

import styles from './UnpaidProjectItem.module.scss';
import { SelectPaidDateModal } from './SelectPaidDateModal';
import { Project } from 'models';

interface UnpaidProjectItemProps {
  project: Project;
  refreshProjects: () => void;
}

Modal.setAppElement('#root');

export const UnpaidProjectItem: FC<UnpaidProjectItemProps> = ({ project, refreshProjects }) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const linkToChatPage = () => {
    history.push({
      pathname: `/chat/${project.client.chatRoomKey}/model`,
      search: `?returnUrl=${history.location.pathname}`,
    });
  };
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      backgroundColor: 'transparent',
      inset: '0',
      padding: '0',
    },
    overlay: {
      backgroundColor: 'rgba(0 ,0 ,0 ,0.8)',
    },
  };

  return (
    <div className={styles.wrap}>
      <div
        className={styles.projectInfoArea}
        onClick={() => {
          history.push({
            pathname: `/project/${project.id}`,
            search: `?returnUrl=${history.location.pathname}`,
          });
        }}
      >
        <p className={styles.salesDate}>
          {project.startDate ? `${dayjs(project.startDate).format('YYYY年M月D日')}〜` : ''}
          {dayjs(project.endDate).format('YYYY年M月D日')}
        </p>
        <p className={styles.projectName}>{project.name}</p>
        <p className={styles.clientName}>{project.client?.name}</p>
        <p className={styles.contractPrice}>{project.contractPrice.toLocaleString()}円</p>
      </div>
      <div className={styles.buttons}>
        <IonButton className={styles.chatButton} fill="outline" onClick={linkToChatPage}>
          チャットで確認
        </IonButton>
        <IonButton className={styles.openPaymentConfirmModalButton} onClick={() => openModal()}>
          入金確認
        </IonButton>

        <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles}>
          <SelectPaidDateModal project={project} closeModal={closeModal} refreshProjects={refreshProjects} />
        </Modal>
      </div>
    </div>
  );
};
