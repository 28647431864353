import React from 'react';
import { IonIcon, IonTabBar, IonTabButton, IonLabel } from '@ionic/react';
import { useLocation } from 'react-router';
import classnames from 'classnames';

import styles from './TabBar.module.scss';

type TabMenu = 'project' | 'sale' | 'profile_setting' | 'setting';

type TabBarProps = {
  selectedTab: TabMenu;
};

export const TabBar: React.FC<TabBarProps> = (props: TabBarProps) => {
  const { pathname } = useLocation();
  const { selectedTab } = props;
  const activeTab = (tabMenu: TabMenu) => {
    return selectedTab === tabMenu ? styles.active : null;
  };

  // 下記操作で案件タブのhrefがionicのTab使用により勝手に案件詳細のURLに更新されてしまう
  // 売上タブ→未入金リスト→案件詳細→戻る→戻る→売上一覧画面で案件タブをクリック
  // それを防ぐために各TabのTopページ外の場合は、TabBarを明記的に非表示にする
  // Topページに戻った場合、TabBarが再描画されるので、上記変な動きを防げる
  const isDisplay = ['/project', '/sale/history', '/profile_setting', '/setting'].includes(pathname);
  if (!isDisplay) return null;

  return (
    <IonTabBar className={styles.tabBar}>
      <IonTabButton className={styles.tab} tab="project" href="/project">
        <IonIcon
          aria-hidden="true"
          className={classnames(styles.icon, activeTab('project'))}
          src="assets/tabs/icn__project.svg"
        />
        <IonLabel className={styles.ionLabel}>案件</IonLabel>
      </IonTabButton>
      <IonTabButton className={styles.tab} tab="sale" href="/sale/history">
        <IonIcon
          aria-hidden="true"
          className={classnames(styles.icon, activeTab('sale'))}
          src="assets/tabs/icn__sale.svg"
        />
        <IonLabel className={styles.ionLabel}>売上</IonLabel>
      </IonTabButton>
      <IonTabButton className={styles.tab} tab="profile" href="/profile_setting">
        <IonIcon
          aria-hidden="true"
          className={classnames(styles.icon, activeTab('profile_setting'))}
          icon="assets/tabs/icn__profile.svg"
        />
        <IonLabel className={styles.ionLabel}>プロフ</IonLabel>
      </IonTabButton>
      <IonTabButton className={styles.tab} tab="setting" href="/setting">
        <IonIcon
          aria-hidden="true"
          className={classnames(styles.icon, activeTab('setting'))}
          icon="assets/tabs/icn__setting.svg"
        />
        <IonLabel className={styles.ionLabel}>設定</IonLabel>
      </IonTabButton>
    </IonTabBar>
  );
};
