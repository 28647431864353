import React from 'react';
import { useState } from 'react';
import { IonButton, IonInput, useIonAlert } from '@ionic/react';
import { AxiosError } from 'axios';

import { usePasswordReset } from 'api/hooks/password/usePasswordReset';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

import { WarningMessage } from 'components/common/WarningMessage';

import styles from './PasswordResetForm.module.scss';

interface PasswordResetFormValues {
  email: string;
}

export const PasswordResetForm: React.FC = () => {
  const { postPasswordReset } = usePasswordReset();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [isPosting, setIsPosting] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<PasswordResetFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const resetPassword = async (data: PasswordResetFormValues): Promise<void> => {
    setIsPosting(true);

    // パスワードリセットAPI コール
    postPasswordReset(data)
      .then(() => {
        presentAlert({
          header: '送信完了',
          message:
            'ご登録のメールアドレス宛に、パスワード変更のためのメールを送信しました。<br />24時間以内にメール内のリンクからパスワード変更を行なってください。',
          buttons: [
            {
              text: 'ログイン画面に移動',
              handler: () => {
                history.push('/login');
              },
            },
          ],
          mode: 'ios',
          backdropDismiss: false,
        });
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        if (e.response?.status === 404) {
          // 未登録メールアドレスの場合も、セキュリティ面の配慮で送信済をみなす
          presentAlert({
            header: '送信完了',
            message:
              'ご登録のメールアドレス宛に、パスワード変更のためのメールを送信しました。<br />24時間以内にメール内のリンクからパスワード変更を行なってください。',
            buttons: [
              {
                text: 'ログイン画面に移動',
                handler: () => {
                  history.push('/login');
                },
              },
            ],
            mode: 'ios',
            backdropDismiss: false,
          });
        } else {
          presentAlert({
            header: '送信エラー',
            message: e.response?.data?.message,
            buttons: [
              {
                text: 'OK',
              },
            ],
            mode: 'ios',
          });
        }
      })
      .finally(() => {
        setIsPosting(false);
      });
  };

  return (
    <div className={styles.wrap}>
      <p className={styles.note}>ご登録のメールアドレス宛にパスワード変更のためのメールを送信します。</p>
      <div className="mypage__inputBlock">
        <div className="input__infoSection">メールアドレス</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="example@colorfully.jp"
          type="email"
          maxlength={150}
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('email', e.target.value)}
          {...register('email', {
            required: {
              value: true,
              message: '未入力です',
            },
            pattern: {
              value: /[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z.-_]{2,}/,
              message: '正しいメールアドレスを入力してください',
            },
          })}
        />
        <div className="attentionBlock">
          {errors.email?.message && <WarningMessage message={errors.email.message} />}
        </div>
      </div>
      <div className={styles.buttons}>
        <IonButton className={styles.button} disabled={isPosting} onClick={handleSubmit(resetPassword)}>
          送信
        </IonButton>
      </div>
    </div>
  );
};
