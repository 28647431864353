import React from 'react';

import styles from './NotFound.module.scss';

export const NotFound: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <p className={styles.note}>
        お探しのページはURLが変更されたか、削除された可能性があります。
        <br />
        また、正しくURLが入力されているかご確認ください。
      </p>
    </div>
  );
};
