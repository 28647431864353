import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IonButton, IonButtons, IonHeader, IonImg, IonPage, IonTitle, IonToolbar, useIonToast } from '@ionic/react';

import { inMemoryPersistence, signInAnonymously } from '@firebase/auth';
import { auth, fireStore } from 'utils/firebase';
import { doc, DocumentReference, getDoc } from '@firebase/firestore';
import { AxiosError } from 'axios';

import { useGetProfileUser } from 'api/hooks/profile/useGetProfileUser';

import { ChatClientContent } from 'components/chat/ChatClientContent';
import { ChatFooterForm } from 'components/chat/ChatFooterForm';
import { Chat, ProfileUser } from 'models';
import { SENDER_CLIENT } from 'consts';

import styles from './ChatClientPage.module.scss';

export const ChatClientPage: FC = () => {
  const [chat, setChat] = useState<Chat>();
  const [profileUser, setProfileUser] = useState<ProfileUser>();
  const chatRoomKey = useParams<{ chatRoomKey: string }>().chatRoomKey;

  const [presentToast] = useIonToast();
  const { getProfileUser } = useGetProfileUser();

  const handleClickProfileButton = () => {
    window.open(
      `${process.env.REACT_APP_MANAGEMENT_PROFILE_HOST}/${profileUser?.accountName}`,
      '_blank',
      'noopener noreferrer'
    );
  };

  const getProfileUserInfo = (userId: number) => {
    getProfileUser(userId)
      .then((res) => {
        setProfileUser(res);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  const getChat = async () => {
    await auth.setPersistence(inMemoryPersistence);
    signInAnonymously(auth)
      .then(async () => {
        const chatDoc = doc(fireStore, 'chats', chatRoomKey) as DocumentReference<Chat>;
        const chat = await getDoc<Chat>(chatDoc);
        const chatData = chat.data();
        setChat(chatData);
        if (chatData?.userID) {
          getProfileUserInfo(chatData.userID);
        }
      })
      .catch((e) => {
        console.error(e);
        presentToast({
          message: '通信エラーが発生しました。しばらくたってからもう一度お試しください。',
          duration: 2000,
        });
      });
  };

  useEffect(() => {
    getChat();
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar className="header">
          <IonButtons slot="end" className={styles.headerRightButton}>
            <IonButton onClick={handleClickProfileButton} disabled={!chat || !profileUser?.accountName}>
              <IonImg src={'assets/chat/icn__browser.svg'} alt="profile link" />
            </IonButton>
          </IonButtons>
          <IonTitle className={styles.headerTitle}>{profileUser?.name}</IonTitle>
        </IonToolbar>
      </IonHeader>
      {chat ? <ChatClientContent modelName={profileUser?.name || ''} /> : null}
      <ChatFooterForm senderType={SENDER_CLIENT} />
    </IonPage>
  );
};
