import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';

export const useBasicAuth = () => {
  const getBasicAuth = async (credentials: string) => {
    const apiClient = new ApiClient('admin/basic_auth', '', process.env.REACT_APP_BACK_END_API_ENDPOINT, true);
    const res = await apiClient
      .get(
        {},
        {
          headers: {
            Authorization: `Basic ${credentials}`,
          },
        }
      )
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res.data;
  };

  return { getBasicAuth };
};
