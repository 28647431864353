import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { ProfileDetail } from 'models';

export const useGetProfile = () => {
  const { authInfo, currentUser } = useAuthInfo();
  const getProfile = async () => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`users/${authInfo.userId}/profile`, idToken);
    const res = await apiClient.get<ProfileDetail>().catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { getProfile };
};
