import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Sale } from 'models';

export const useGetSales = () => {
  const { currentUser } = useAuthInfo();
  const getSales = async (year: number) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`sales`, idToken);
    const res = await apiClient.get<Sale>({ year: year }).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { getSales };
};
