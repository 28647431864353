import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from '@ionic/react';

import styles from './ClientRegisterPage.module.scss';
import { BackButton } from 'components/common/buttons/BackButton';

import { RegisterForm } from 'components/client/RegisterForm';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useClientRegister } from 'api/hooks/client';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router';
import { useAtom } from 'jotai';
import { projectEditAtom } from 'stores/project';
import { ClientFormValues } from 'models';
import { withLogin } from 'pages/hocs/withLogin';

export const ClientRegisterPage: FC = withLogin(() => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ClientFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });
  const { postClientInfo } = useClientRegister();
  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [, setProjectEditAtomValue] = useAtom(projectEditAtom);

  const history = useHistory();
  const location = useLocation();
  const returnPathname = location.pathname.split('/').slice(0, -2).join('/');
  const returnUrl = `${returnPathname}${location.search}`;

  const execPostClient = (data: ClientFormValues) => {
    // emailとphoneは空文字の場合バックエンド側のバリデーションに引っかかるため、
    // ここでnullに置き換える処理が必要
    const requestData: ClientFormValues = {
      ...data,
      email: data.email || null,
      phone: data.phone || null,
    };

    postClientInfo(requestData)
      .then(() => {
        setProjectEditAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
        history.replace(returnUrl);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data.message,
          duration: 2000,
        });
      });
  };

  const registerClient = async (data: ClientFormValues) => {
    if (!data.email) {
      presentAlert({
        mode: 'ios',
        header: '確認',
        message: 'メールアドレスの登録がない場合、チャットURLは発行されません。',
        buttons: [
          {
            text: '　編集に戻る　',
          },
          {
            text: '　このまま保存　',
            handler: () => {
              execPostClient(data);
            },
          },
        ],
      });
      return;
    }

    execPostClient(data);
  };

  return (
    <>
      <IonPage>
        <IonHeader mode="ios">
          <IonToolbar>
            <IonButtons className="header">
              <BackButton defaultHref={returnUrl} />
              <IonTitle className={styles.title}>クライアント新規登録</IonTitle>
              <IonButton className={styles.saveButton} onClick={handleSubmit(registerClient)}>
                保存
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <RegisterForm register={register} setValue={setValue} errors={errors} />
        </IonContent>
      </IonPage>
    </>
  );
});
