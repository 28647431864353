import { IAuthInfo } from './authContext';

const STORAGE_KEY_AUTH_INFO = 'COLORFULLY_MANAGEMENT_AUTH';

export const getAuthInfoFromStorage = (): Partial<IAuthInfo> => {
  const authInfoString = window.localStorage.getItem(STORAGE_KEY_AUTH_INFO);
  return JSON.parse(authInfoString || '{}');
};

export const saveAuthInfoToStorage = (authInfo: IAuthInfo): void => {
  const authInfoString = JSON.stringify(authInfo);
  window.localStorage.setItem(STORAGE_KEY_AUTH_INFO, authInfoString);
};

export const removeAuthInfoFromStorage = (): void => {
  window.localStorage.removeItem(STORAGE_KEY_AUTH_INFO);
};
