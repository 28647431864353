import { useContext } from 'react';
import { AuthContext } from './authContext';

export const useAuthInfo = () => {
  const { currentUser, authInfo, isAuthChecking, updateAuthInfo } = useContext(AuthContext);

  return {
    currentUser,
    authInfo,
    isAuthChecking,
    updateAuthInfo,
  };
};
