import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { ellipse } from 'ionicons/icons';
import { Client } from 'models';

import styles from './ProjectMessageFab.module.scss';

interface ProjectMessageFabProps {
  chatRoomKey: Client['chatRoomKey'];
  unread: boolean;
}

export const ProjectMessageFab: React.FC<ProjectMessageFabProps> = ({ chatRoomKey, unread }) => {
  const history = useHistory();
  if (!chatRoomKey) return null;

  return (
    <Link
      to={`/chat/${chatRoomKey}/model`}
      onClick={(e) => {
        // iOS Safariの一部の機種はLinkのデフォルト動きは物理遷移になってしまうため、history.pushを利用する
        e.preventDefault();
        history.push({
          pathname: `/chat/${chatRoomKey}/model`,
          search: `?returnUrl=${history.location.pathname}`,
        });
      }}
    >
      <IonFab vertical="bottom" horizontal="end">
        {unread && <IonIcon aria-hidden="true" icon={ellipse} className={styles.addFabIcon} />}
        <IonFabButton className={styles.projectMessageFabButton}>
          <IonIcon src="assets/common/icn_message.svg" className={styles.projectMessageFab} />
        </IonFabButton>
      </IonFab>
    </Link>
  );
};
