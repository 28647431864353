import { createContext, useState, useEffect } from 'react';

import { getCredentialsFromLocalStorage } from './localStorage';

export interface IBasicAuthInfo {
  // undefined -> 未認証
  // あり -> BASIC認証済
  credentials?: string;
  expiresAt?: number;
}

interface IBasicAuthContext {
  authInfo: IBasicAuthInfo;
  isAuthChecking: boolean;
  updateAuthInfo: (authInfo: IBasicAuthInfo) => void;
}

const initialContext: IBasicAuthContext = {
  authInfo: {
    credentials: '',
    expiresAt: 0,
  },
  isAuthChecking: true,
  updateAuthInfo: () => {} /* eslint-disable-line @typescript-eslint/no-empty-function */,
};

interface Props {
  children: JSX.Element;
}

export const BasicAuthContext = createContext<IBasicAuthContext>(initialContext);

export const BasicAuthProvider = ({ children }: Props): JSX.Element => {
  const [authInfo, setAuthInfo] = useState<IBasicAuthInfo>(initialContext.authInfo);
  const [isAuthChecking, setIsAuthChecking] = useState<boolean>(initialContext.isAuthChecking);

  const updateAuthInfo = (authInfo: IBasicAuthInfo) => {
    setAuthInfo(authInfo);
  };

  useEffect(() => {
    const authInfo2 = getCredentialsFromLocalStorage();
    setAuthInfo(authInfo2);
    setIsAuthChecking(false);
  }, []);

  return (
    <BasicAuthContext.Provider value={{ authInfo, isAuthChecking, updateAuthInfo }}>
      {children}
    </BasicAuthContext.Provider>
  );
};
