export const ACCEPT_FILE_TYPE = [
  'application/pdf',
  'application/msword',
  'application/ogg',
  'application/rtf',
  'application/vnd.apple.pages',
  'application/x-iwork-pages-sffpages',
  'application/vnd.apple.keynote',
  'application/x-iwork-keynote-sffkey',
  'application/x-iwork-numbers-sffnumbers',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.text',
  // image
  'image/bmp',
  'image/gif',
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/webp',
  'image/svg+xml',
  'image/tiff',
  'image/heif',
  'image/heic',
  'image/heif-sequence',
  'image/vnd.microsoft.icon',
  // text
  'text/plain',
  'text/calendar',
  'text/csv',
];

export const IMAGE_TYPES = ['bmp', 'gif', 'png', 'jpg', 'jpeg', 'webp', 'svg', 'tiff', 'heif', 'heic', 'ico'];
