import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';

interface PostPasswordResetRequest {
  email: string;
}

interface PostPasswordResetResponse {
  email: string;
}

export const usePasswordReset = () => {
  const postPasswordReset = async (data: PostPasswordResetRequest): Promise<PostPasswordResetResponse> => {
    const apiClient = new ApiClient('password/reset', '');
    const res = await apiClient
      .post<PostPasswordResetResponse>(data)
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res.data;
  };

  return { postPasswordReset };
};
