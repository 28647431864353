import { IonFooter, IonButton, IonButtons, IonIcon, IonTextarea, IonToolbar, useIonToast } from '@ionic/react';
import { FC, useState } from 'react';
import { Controller, FieldErrors, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import classNames from 'classnames';

import styles from './ChatFooterForm.module.scss';
import { SENDER_CREATOR, SENDER_CLIENT, FILE_SIZE_SMALL_LIMIT_MB, FILE_SIZE_LARGE_LIMIT_MB } from 'consts';
import { ACCEPT_FILE_TYPE } from 'consts/fileType';
import { useUploadChatFile } from 'api/hooks/chat/useUploadChatFile';
import { useCreateMessage } from 'api/hooks/chat/useCreateMessage';
import { FileType } from 'models';
import { usePostNotifyEmail } from 'api/hooks/chat/usePostNotifyMail';
import { useAuthInfo } from 'auth';
import { useClientGet } from 'api/hooks/client';
import { useAtom } from 'jotai';
import { projectListAtom } from 'stores';
import { isAndroid } from 'utils/device';

interface CreateMessageParams {
  chatRoomKey: string;
  senderType: typeof SENDER_CREATOR | typeof SENDER_CLIENT;
  body: string;
  file: FileType[];
}

interface ChatForm {
  body?: string;
  file: FileList | null;
}

interface ChatFooterFormProps {
  senderType: typeof SENDER_CREATOR | typeof SENDER_CLIENT;
  clientID?: number;
  modelName?: string;
}

export const ChatFooterForm: FC<ChatFooterFormProps> = ({ senderType, clientID, modelName }) => {
  const [isTextareaFocused, setIsTextareaFocused] = useState(false);

  const [presentToast] = useIonToast();
  const chatRoomKey = useParams<{ chatRoomKey: string }>().chatRoomKey;
  const { uploadChatFile } = useUploadChatFile();
  const { createMessage } = useCreateMessage();
  const { postNotifyEmail } = usePostNotifyEmail();
  const [, setProjectListAtomValue] = useAtom(projectListAtom);

  const { currentUser } = useAuthInfo();
  const { getClientInfo } = useClientGet();
  const { register, handleSubmit, setValue, watch, control } = useForm<ChatForm>();
  const { body, file } = watch();
  const isNotInputed = !body && !file?.length;

  const registerMessage = async (data: ChatForm) => {
    let fileUrl = '';
    let fileName = '';
    if (data.file?.length) {
      const file = data.file[0];
      fileName = data.file[0].name;
      if (!isSizeOverError(file)) {
        return;
      }
      await uploadChatFile(file, chatRoomKey)
        .then((result) => {
          fileUrl = result.metadata.fullPath;
        })
        .catch((e) => {
          console.error(e);
          presentToast({
            message: '通信エラーが発生しました。しばらくたってからもう一度お試しください。',
            duration: 2000,
          });
        });
    }

    const createMessageParams: CreateMessageParams = {
      chatRoomKey,
      senderType,
      body: data.body ?? '',
      file: [
        {
          contentType: data.file?.length ? data.file[0].type : '',
          contentURL: fileUrl,
          name: fileName,
        },
      ],
    };

    // メッセージを送信
    // クライアントからのメッセージの場合未読ありに更新する
    createMessage(createMessageParams, senderType)
      .then(async () => {
        setValue('body', '');
        setValue('file', null);
        if (senderType === SENDER_CLIENT) {
          setProjectListAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
        }

        // モデルからのメッセージ送信時にcloud functionsからメールを送信する
        if (senderType === SENDER_CREATOR && clientID) {
          const idToken = await currentUser?.getIdToken();
          const clientInfo = await getClientInfo(clientID);
          const param = {
            subject: `${modelName}さんからの新着メッセージ`,
            text: `新着メッセージが届いたので、以下リンクをご確認ください。\n${window.location.origin}/chat/${chatRoomKey}/client`,
            to: clientInfo.email,
            token: idToken ?? '',
            chatRoomKey,
          };
          postNotifyEmail(param).catch((e) => {
            console.error(e);
            presentToast({
              message: '通信エラーが発生しました。しばらくたってからもう一度お試しください。',
              duration: 2000,
            });
          });
        }
      })
      .catch((e) => {
        console.error(e);
        presentToast({
          message: '通信エラーが発生しました。しばらくたってからもう一度お試しください。',
          duration: 2000,
        });
      });
  };

  const onError = (errors: FieldErrors<ChatForm>): void => {
    let message = '';
    if (errors.body) {
      message += `${errors.body.message}\n`;
    }
    if (errors.file) {
      message += `${errors.file.message}`;
    }
    presentToast({
      message,
      duration: 2000,
    });
  };

  const isSizeOverError = (file: File): boolean => {
    const isImageFile = ['image/jpg', 'image/jpeg', 'image/png'].includes(file.type);
    const fileSizeLimitMb = isImageFile ? FILE_SIZE_SMALL_LIMIT_MB : FILE_SIZE_LARGE_LIMIT_MB;
    const fileSizeLimit = fileSizeLimitMb * 1000 * 1000;
    if (file.size > fileSizeLimit) {
      const message = isImageFile
        ? `画像ファイルサイズ容量は${fileSizeLimitMb}MBが上限になります。`
        : `ファイルサイズ容量は${fileSizeLimitMb}MBが上限になります。`;
      onError({
        file: {
          message,
          type: 'file',
        },
      });
      return false;
    }
    return true;
  };

  return (
    <IonFooter mode="ios" className={classNames(styles.footer, isTextareaFocused ? styles.footerFocused : null)}>
      <IonToolbar className={styles.footerToolbar}>
        <Controller
          render={({ field }) => (
            <IonTextarea
              className={classNames(
                'ion-input__commonText',
                styles.ionInputFooterText,
                isTextareaFocused ? styles.ionInputFooterTextFocused : null
              )}
              value={field.value}
              rows={isTextareaFocused ? 4 : 1}
              maxlength={10_000}
              onIonChange={(e) => field.onChange(e.detail.value)}
              onFocus={() => {
                // Androidの場合はバーチャルキーボード動画表示が終わってから高さを調整する（そうしないと失敗になる）
                if (isAndroid()) {
                  setTimeout(() => {
                    setIsTextareaFocused(true);
                  }, 100);
                } else {
                  setIsTextareaFocused(true);
                }
              }}
              onBlur={() => {
                setTimeout(() => {
                  setIsTextareaFocused(false);
                }, 300);
              }}
            />
          )}
          control={control}
          name="body"
        />
        <IonButtons slot="end" className={styles.footerFileButton}>
          <IonButton>
            <label className={styles.footerInputFile}>
              <input type="file" {...register('file')} accept={ACCEPT_FILE_TYPE.join(',')} hidden={true} />
              <IonIcon
                icon={'assets/chat/icn__picture.svg'}
                className={file?.length ? styles.footerFileActiveImg : styles.footerFileDisabledImg}
              />
            </label>
          </IonButton>
          <IonButton
            strong
            className={isNotInputed ? styles.footerSendDisabledBtn : styles.footerSendActiveBtn}
            onClick={handleSubmit(registerMessage, onError)}
            disabled={isNotInputed}
          >
            送信
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonFooter>
  );
};
