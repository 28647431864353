import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Project } from 'models/project';

export const useGetSaleHistories = () => {
  const { currentUser } = useAuthInfo();
  const getSaleHistories = async (year: number, limit: number, offset: number) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient('sales/histories', idToken);
    const res = await apiClient
      .get<Project[]>({ year: year, limit: limit, offset: offset })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res.data;
  };

  return { getSaleHistories };
};
