import React from 'react';
import { useState, useRef } from 'react';
import { IonButton, IonIcon, IonInput, useIonAlert, useIonLoading } from '@ionic/react';
import { AxiosError } from 'axios';
import classNames from 'classnames';

import { useMailChange } from 'api/hooks/setting/useMailChange';
import { useForm } from 'react-hook-form';

import { auth } from 'utils/firebase';
import { isIos } from 'utils/device';

import { WarningMessage } from 'components/common/WarningMessage';

import styles from './MailChangeForm.module.scss';

interface MailChangeFormValues {
  currentEmail: string;
  password: string;
  newEmail: string;
}

export const MailChangeForm: React.FC = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const { patchUserMail } = useMailChange();
  const [presentAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();

  const [isNewEmailFocused, setIsNewEmailFocused] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<MailChangeFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  // 入力中の要素のRef、パスワードToggleボタンクリックした後にフォーカスを元に戻すために利用する
  const [inputtingElement, setInputtingElement] = useState<(EventTarget & HTMLIonInputElement) | null>(null);
  // Blur後に記憶した入力中要素をクリアするか
  // ・他の入力項目にフォーカスまたはToogleボタンをクリックした場合はクリアしない
  // ・それ以外の場合は、クリアする
  const shouldClearInputRef = useRef(true);

  const handleInputBlur = () => {
    // パスワードToggleボタンのクリックであるかを判断するために遅延する
    setTimeout(() => {
      // パスワードToggleボタンがクリックされなかった場合、フォーカスを戻す必要がないため、入力中項目をクリアする
      if (shouldClearInputRef.current) {
        setInputtingElement(null);
      }
      shouldClearInputRef.current = true;
    }, 300);
  };

  const handleClickTogglePassword = () => {
    inputtingElement?.focus();
    shouldClearInputRef.current = false;
    setPasswordShown(!passwordShown);
  };

  const changeMail = async (data: MailChangeFormValues): Promise<void> => {
    presentLoading({
      duration: 2000,
    });

    // メールアドレス変更API コール
    patchUserMail(data)
      .then(() => {
        presentAlert({
          header: '送信完了',
          message:
            'ご登録のメールアドレス宛に、認証のためのメールを送信しました。<br />24時間以内にリンクをクリックして認証を終えてください。',
          buttons: [
            {
              text: 'ログイン画面に移動',
              handler: () => {
                auth.signOut().finally(() => {
                  window.location.href = '/login';
                });
              },
            },
          ],
          mode: 'ios',
          backdropDismiss: false,
        });
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentAlert({
          header: '送信エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      })
      .finally(() => {
        dismissLoading();
      });
  };

  const focusedCls = isIos() && isNewEmailFocused ? styles.focused : '';

  return (
    <div className={classNames(styles.wrap, focusedCls)}>
      <div className="mypage__inputBlock">
        <div className="input__infoSection">現在のメールアドレス</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="example@colorfully.jp"
          type="email"
          maxlength={150}
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('currentEmail', e.target.value)}
          {...register('currentEmail', {
            required: {
              value: true,
              message: '未入力です',
            },
            pattern: {
              value: /[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z.-_]{2,}/,
              message: '正しいメールアドレスを入力してください',
            },
          })}
          onFocus={(e) => {
            setInputtingElement(e.target);
            shouldClearInputRef.current = false;
          }}
          onBlur={handleInputBlur}
        />
        <div className="attentionBlock">
          {errors.currentEmail?.message && <WarningMessage message={errors.currentEmail.message} />}
        </div>
      </div>
      <div className="mypage__inputBlock">
        <div className="input__infoSection">現在のパスワード</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            placeholder="半角英数8文字以上"
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('password', e.target.value)}
            clearOnEdit={false}
            type={passwordShown ? 'text' : 'password'}
            maxlength={150}
            {...register('password', {
              required: {
                value: true,
                message: '未入力です',
              },
              pattern: {
                value: /^[a-zA-Z0-9]{8,}$/,
                message: '半角文字で8文字以上の入力が必要です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
          {passwordShown ? (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye-close.svg"
            />
          ) : (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye.svg"
            />
          )}
        </div>
        <div className="attentionBlock">
          {errors.password?.message && <WarningMessage message={errors.password.message} />}
        </div>
      </div>
      <div className="mypage__inputBlock">
        <div className="input__infoSection">新しいメールアドレス</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="example@colorfully.jp"
          type="email"
          maxlength={150}
          onIonFocus={() => {
            setIsNewEmailFocused(true);
          }}
          onIonBlur={() => {
            setIsNewEmailFocused(false);
          }}
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('newEmail', e.target.value)}
          {...register('newEmail', {
            validate: (value, formValues) => value !== formValues['currentEmail'],
            required: {
              value: true,
              message: '未入力です',
            },
            pattern: {
              value: /[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z.-_]{2,}/,
              message: '正しいメールアドレスを入力してください',
            },
          })}
          onFocus={(e) => {
            setInputtingElement(e.target);
            shouldClearInputRef.current = false;
          }}
          onBlur={handleInputBlur}
        />
        <div className="attentionBlock">
          {errors.newEmail?.message && <WarningMessage message={errors.newEmail.message} />}
        </div>
        <div className="attentionBlock">
          {getValues('newEmail') &&
            !errors.currentEmail?.message &&
            !errors.newEmail?.message &&
            getValues('newEmail') === getValues('currentEmail') && (
              <WarningMessage message="現在のメールアドレスと同じです" />
            )}
        </div>
      </div>
      <div className={styles.buttons}>
        <IonButton className={styles.button} onClick={handleSubmit(changeMail)}>
          送信
        </IonButton>
      </div>
    </div>
  );
};
