import { IonButton, IonIcon, IonInput, IonSelect, IonSelectOption, IonTextarea, useIonToast } from '@ionic/react';
import { FC, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import styles from './ProjectForm.module.scss';
import './ProjectForm.css';

import { addOutline, calendarOutline, refreshOutline, trashOutline } from 'ionicons/icons';
import { Client, ProjectFormValues, ProjectStatus } from 'models';
import {
  Control,
  Controller,
  DeepMap,
  DeepPartial,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { WarningMessage } from 'components/common/WarningMessage';
import Modal from 'react-modal';
import { SelectDateModal } from './SelectDateModal';
import ImageCropperModal from 'components/common/ImageCropperModal';
import { useGetClients } from 'api/hooks/client/useGetClients';
import { AxiosError } from 'axios';
import { CONTRACT_FILE_ALLOWED_FILE_TYPES, CONTRACT_FILE_MAX_SIZE, IMAGE_FILE_MAX_SIZE } from 'consts';
import { useAtom } from 'jotai';
import { convertDateFormat } from 'utils/date';
import { removeHyphenAndE } from 'utils/string';
import { projectRegisterAtom, projectEditAtom } from 'stores/project';

interface ProjectFormProps {
  setCroppedImageFileBlob: (blob: Blob) => void;
  onImageFileDeleted?: () => void;
  onContractFileDeleted?: () => void;
  register: UseFormRegister<ProjectFormValues>;
  setValue: UseFormSetValue<ProjectFormValues>;
  watch: UseFormWatch<ProjectFormValues>;
  control: Control<ProjectFormValues>;
  errors: DeepMap<DeepPartial<ProjectFormValues>, FieldError>;
  // 編集フォームのときのみ利用
  imageFileUrl?: string;
  // 編集フォームのときのみ利用
  contractFileUrl?: string;
  isEdit?: boolean;
}

export const ProjectForm: FC<ProjectFormProps> = ({
  setCroppedImageFileBlob,
  onImageFileDeleted = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  onContractFileDeleted = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  register,
  setValue,
  watch,
  control,
  errors,
  imageFileUrl,
  contractFileUrl,
  isEdit = false,
}) => {
  const [startDateModalIsOpen, setStartDateModalIsOpen] = useState(false);
  const [endDateModalIsOpen, setEndDateModalIsOpen] = useState(false);
  const [paymentDateModalIsOpen, setPaymentDateModalIsOpen] = useState(false);
  const [paymentDueDateModalIsOpen, setPaymentDueDateModalIsOpen] = useState(false);
  const [imageCropperModalIsOpen, setImageCropperModalIsOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [croppedImgSrc, setCroppedImgSrc] = useState('');
  const [contractFileSrc, setContractFileSrc] = useState('');
  const [contractFileName, setContractFileName] = useState('');
  const [clients, setClients] = useState<Client[]>([]);

  const { getClients } = useGetClients();

  const [presentToast] = useIonToast();

  const history = useHistory();
  const location = useLocation();

  const [projectRegisterAtomValue, setProjectRegisterAtomValue] = useAtom(projectRegisterAtom);
  const [projectEditAtomValue, setProjectEditAtomValue] = useAtom(projectEditAtom);

  const fetchClients = () => {
    getClients()
      .then((clients) => {
        setClients(clients);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  const modalStyles = {
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      backgroundColor: 'transparent',
      inset: '0',
      padding: '0',
    },
    overlay: {
      backgroundColor: 'rgba(0 ,0 ,0 ,0.8)',
    },
  };

  /**
   * 画像ファイルアップロード後
   * 画像ファイルのURLをセットしモーダルを表示する
   */
  const onImageFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // サイズが5MB以下であることを確認
      if (e.target.files[0].size > IMAGE_FILE_MAX_SIZE) {
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || '');
          setImageCropperModalIsOpen(true);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);

  const onContractFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      // サイズが100MB以下であることを確認
      if (e.target.files[0].size > CONTRACT_FILE_MAX_SIZE) {
        return;
      }

      const fileObjectUrl = URL.createObjectURL(file);
      setContractFileName(file.name);
      setContractFileSrc(fileObjectUrl);
    }
  }, []);

  const setImageFileAndContractFile = () => {
    if (imageFileUrl) {
      setImgSrc(imageFileUrl);
      setCroppedImgSrc(imageFileUrl);
    }

    if (contractFileUrl) {
      setContractFileSrc(contractFileUrl);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setContractFileName(contractFileUrl.split('/').pop()!);
    }
  };

  // クライアント登録またはクライアント編集画面から戻った場合、クライアント一覧を再検索する
  useEffect(() => {
    if (projectRegisterAtomValue.isNeedRefresh || projectEditAtomValue.isNeedRefresh) {
      // 再描画させるために一旦クリアする
      setClients([]);
      // 再検索する
      fetchClients();
    }
    if (projectRegisterAtomValue.isNeedRefresh) {
      setProjectRegisterAtomValue((prevState) => ({ ...prevState, isNeedRefresh: false }));
    }
    if (projectEditAtomValue.isNeedRefresh) {
      setProjectEditAtomValue((prevState) => ({ ...prevState, isNeedRefresh: false }));
    }
  }, [projectRegisterAtomValue.isNeedRefresh, projectEditAtomValue.isNeedRefresh]);

  // クライアント一覧を検索する
  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    setImageFileAndContractFile();
  }, [imageFileUrl, contractFileUrl]);

  return (
    <div className={styles.wrap}>
      <div className={styles.projectInputBlock}>
        <div className={styles.dateInputBlock}>
          <div className={styles.dateLabel}>開始日</div>
          <div className={styles.dateText}>{convertDateFormat(watch('startDate'))}</div>
          <IonButton fill="clear" onClick={() => setStartDateModalIsOpen(true)}>
            <IonIcon icon={calendarOutline} className={styles.dateCalendarIcon} />
          </IonButton>

          <Controller
            control={control}
            name="startDate"
            render={() => (
              <Modal
                isOpen={startDateModalIsOpen}
                onRequestClose={() => setStartDateModalIsOpen(false)}
                style={modalStyles}
              >
                <SelectDateModal
                  onChange={(e) => {
                    typeof e.target.value == 'string' && setValue('startDate', e.target.value);
                  }}
                  closeModal={() => setStartDateModalIsOpen(false)}
                  value={watch('startDate')}
                />
              </Modal>
            )}
          />
        </div>
        <div className="attentionBlock">
          {errors.startDate?.message && <WarningMessage message={errors.startDate.message} />}
        </div>

        <div className={styles.dateInputBlock}>
          <div className={styles.dateLabel}>最終日※</div>
          <div className={styles.dateText}>{convertDateFormat(watch('endDate'))}</div>
          <IonButton fill="clear" onClick={() => setEndDateModalIsOpen(true)}>
            <IonIcon icon={calendarOutline} className={styles.dateCalendarIcon} />
          </IonButton>

          <Controller
            control={control}
            name="endDate"
            rules={{
              required: {
                value: true,
                message: '最終日の入力が必要です',
              },
              validate: (value, formValues) => {
                if (formValues['startDate'] && dayjs(value).isBefore(dayjs(formValues['startDate']))) {
                  return '最終日は開始日より後の日付を入力してください。';
                }

                return true;
              },
            }}
            render={({ field: { onBlur } }) => (
              <Modal
                isOpen={endDateModalIsOpen}
                onRequestClose={() => setEndDateModalIsOpen(false)}
                style={modalStyles}
              >
                <SelectDateModal
                  onChange={(e) => {
                    typeof e.target.value == 'string' && setValue('endDate', e.target.value);
                    setValue(
                      'paymentDueDate',
                      dayjs(e.target.value as string)
                        .add(1, 'M')
                        .endOf('month')
                        .toISOString()
                    );
                    onBlur();
                  }}
                  closeModal={() => setEndDateModalIsOpen(false)}
                  value={watch('endDate')}
                />
              </Modal>
            )}
          />
        </div>
        <div className="attentionBlock">
          {errors.endDate?.message && <WarningMessage message={errors.endDate.message} />}
        </div>
        <div className={styles.dateDescription}>
          1日のみの案件の場合は<b>最終日のみ</b>設定してください
        </div>

        <div className={styles.inputInfoSection}>ステータス※</div>

        <Controller
          control={control}
          name="status"
          rules={{
            validate: (value: number | null): string | true => {
              // 未入力でも数字0が入ってしまうためvalidateでチェックする
              if (!value) {
                return 'ステータスの入力が必要です';
              }
              return true;
            },
          }}
          render={({ field: { value } }) => (
            <IonSelect
              value={value}
              className={styles.selectInput}
              onIonChange={(e) => {
                setValue('status', Number(e.target.value));
              }}
            >
              <IonSelectOption key={ProjectStatus.Negotiating} value={ProjectStatus.Negotiating}>
                交渉中
              </IonSelectOption>
              <IonSelectOption key={ProjectStatus.Contracted} value={ProjectStatus.Contracted}>
                契約済み
              </IonSelectOption>
              {isEdit && (
                <IonSelectOption key={ProjectStatus.Paid} value={ProjectStatus.Paid}>
                  入金済み
                </IonSelectOption>
              )}
            </IonSelect>
          )}
        />

        <div className="attentionBlock">
          {errors.status?.message && !watch('status') && <WarningMessage message={errors.status.message} />}
        </div>

        {isEdit && Number(watch('status')) === ProjectStatus.Paid && (
          <>
            <div className={styles.dateInputBlock}>
              <div className={styles.dateLabel}>決済日</div>
              <div className={styles.dateText}>{convertDateFormat(watch('paymentDate'))}</div>
              <IonButton fill="clear" onClick={() => setPaymentDateModalIsOpen(true)}>
                <IonIcon icon={calendarOutline} className={styles.dateCalendarIcon} />
              </IonButton>

              <Controller
                control={control}
                name="paymentDate"
                rules={{
                  required: {
                    value: true,
                    message: '決済日の入力が必要です',
                  },
                  validate: (value, formValues) => {
                    if (formValues['endDate'] && dayjs(value).isBefore(dayjs(formValues['endDate']))) {
                      return '決済日は最終日より後の日付を入力してください。';
                    }

                    return true;
                  },
                }}
                render={() => (
                  <Modal
                    isOpen={paymentDateModalIsOpen}
                    onRequestClose={() => setPaymentDateModalIsOpen(false)}
                    style={modalStyles}
                  >
                    <SelectDateModal
                      onChange={(e) => {
                        typeof e.target.value == 'string' && setValue('paymentDate', e.target.value);
                      }}
                      closeModal={() => setPaymentDateModalIsOpen(false)}
                      value={watch('paymentDate')}
                    />
                  </Modal>
                )}
              />
            </div>
            <div className="attentionBlock">
              {errors.paymentDate?.message && <WarningMessage message={errors.paymentDate.message} />}
            </div>
          </>
        )}

        <div className={styles.inputInfoSection}>案件名※</div>
        <IonInput
          className="ion-input__commonText"
          type="text"
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('name', e.target.value)}
          maxlength={150}
          {...register('name', {
            required: {
              value: true,
              message: '案件名の入力が必要です',
            },
          })}
        />
        <div className="attentionBlock">{errors.name?.message && <WarningMessage message={errors.name.message} />}</div>

        <div className={styles.inputInfoSection}>案件イメージ</div>

        {croppedImgSrc ? (
          <div className={styles.uploadedImageContainer}>
            <img src={croppedImgSrc} className={styles.uploadedImage} />
            <div className={styles.imageEditButtons}>
              <label htmlFor="projectImageInput">
                <IonIcon icon={refreshOutline} className={styles.refreshImageIcon} />
              </label>
              <IonIcon
                icon={trashOutline}
                className={styles.deleteImageIcon}
                onClick={() => {
                  setImgSrc('');
                  setCroppedImgSrc('');
                  onImageFileDeleted();
                }}
              />
            </div>
          </div>
        ) : (
          <IonButton fill="outline" className={styles.imageUploadButton}>
            <label htmlFor="projectImageInput" className={styles.fileInputLabel}>
              <IonIcon icon={addOutline} className={styles.addImageIcon} />
            </label>
          </IonButton>
        )}
        <input
          id="projectImageInput"
          type="file"
          accept="image/jpeg,image/png"
          hidden
          {...register('imageFile', {
            onChange: onImageFileChange,
            validate: (fileList: FileList | null): string | true => {
              if (fileList && fileList.length && fileList[0].size > IMAGE_FILE_MAX_SIZE) {
                return '画像ファイルサイズ容量は5MB以下にしてください';
              }
              return true;
            },
          })}
        />
        <div className="attentionBlock">
          {errors.imageFile?.message && <WarningMessage message={errors.imageFile.message} />}
        </div>

        <Modal
          isOpen={imageCropperModalIsOpen}
          onRequestClose={() => setImageCropperModalIsOpen(false)}
          style={modalStyles}
        >
          <ImageCropperModal
            onClose={() => setImageCropperModalIsOpen(false)}
            imgSrc={imgSrc}
            setCroppedImgSrc={setCroppedImgSrc}
            setCroppedImageFileBlob={setCroppedImageFileBlob}
          />
        </Modal>

        <div className={styles.clientInputSection}>
          <div>クライアント※</div>
          <div className={styles.clientLinks}>
            <Link
              className={styles.clientLink}
              to={`${location.pathname}/client/register${location.search}`}
              onClick={(e) => {
                // iOS Safriの一部の機種はLinkのデフォルト動きは物理遷移になってしまうため、history.pushを利用する
                e.preventDefault();
                history.push(`${location.pathname}/client/register${location.search}`);
              }}
            >
              新規登録
            </Link>
            {watch('clientId') ? (
              <div>
                <span className={styles.clientLinkSeparator}>|</span>
                <Link
                  className={styles.clientLink}
                  to={`${location.pathname}/client/${watch('clientId')}/edit${location.search}`}
                  onClick={(e) => {
                    // iOS Safariの一部の機種はLinkのデフォルト動きは物理遷移になってしまうため、history.pushを利用する
                    e.preventDefault();
                    history.push(`${location.pathname}/client/${watch('clientId')}/edit${location.search}`);
                  }}
                >
                  編集
                </Link>
              </div>
            ) : null}
          </div>
        </div>

        <Controller
          control={control}
          name="clientId"
          rules={{
            validate: (value: number | null): string | true => {
              // 未入力でも数字0が入ってしまうためvalidateでチェックする
              if (!value) {
                return 'クライアントの入力が必要です';
              }
              return true;
            },
          }}
          render={({ field: { value } }) => (
            <IonSelect
              value={value}
              className={styles.selectInput}
              onIonChange={(e) => setValue('clientId', Number(e.target.value))}
            >
              {clients.map((client) => (
                <IonSelectOption key={client.id} value={client.id}>
                  {client.name} {client.staff}
                </IonSelectOption>
              ))}
            </IonSelect>
          )}
        />
        <div className="attentionBlock">
          {errors.clientId?.message && !watch('clientId') && <WarningMessage message={errors.clientId.message} />}
        </div>

        <div className={styles.inputInfoSection}>
          契約金額<span className={styles.contractPriceTax}>（税別）</span>※
        </div>
        <IonInput
          className="ion-input__commonText"
          type="number"
          inputMode="decimal"
          onKeyDown={(e) => {
            // 「e」と「-」の入力を禁止する
            if (e.key === 'e' || e.key === '-') {
              e.preventDefault();
            }
          }}
          onIonInput={(e) => {
            const value = removeHyphenAndE(String(e.target.value));
            // 最大桁数を制限
            if (value.length) {
              setValue('contractPrice', Number(value.slice(0, 10)));
            } else {
              setValue('contractPrice', null);
            }
          }}
          maxlength={10}
          {...register('contractPrice', {
            validate: (value: number | null): string | true => {
              if (!value) {
                return '契約金額の入力が必要です';
              }
              if (value >= 1000000000) {
                return '契約金額には1,000,000,000未満の数字を入力してください';
              }
              return true;
            },
          })}
        />
        <div className="attentionBlock">
          {errors.contractPrice?.message && <WarningMessage message={errors.contractPrice.message} />}
        </div>

        <div className={styles.inputInfoSection}>詳細</div>
        <IonTextarea
          className={styles.detailInputTextArea}
          rows={8}
          maxlength={10_000}
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('description', e.target.value)}
          {...register('description', {})}
        />
        <div className="attentionBlock">
          {errors.description?.message && <WarningMessage message={errors.description.message} />}
        </div>

        <div className={styles.inputInfoSection}>契約書ファイル</div>
        <div className={styles.contractFileSection}>
          {contractFileSrc ? (
            <div className={styles.contractFileUpdateOrDeleteSection}>
              <label htmlFor="projectContractFileInput" className={styles.refreshContractFileLabel}>
                <IonIcon icon={refreshOutline} className={styles.refreshContractFileIcon} />
              </label>
              {/* 拡張子を大文字で表示 */}
              <div className={styles.contractFileExtensionText}>{contractFileName.split('.').pop()?.toUpperCase()}</div>
              <IonIcon
                icon={trashOutline}
                className={styles.deleteContractFileIcon}
                onClick={() => {
                  setContractFileName('');
                  setContractFileSrc('');
                  onContractFileDeleted();
                }}
              />
            </div>
          ) : (
            <IonButton fill="outline" className={styles.contractFileUploadButton}>
              <label htmlFor="projectContractFileInput" className={styles.fileInputLabel}>
                <span className={styles.addContractFileIcon} />
              </label>
            </IonButton>
          )}
        </div>
        <input
          id="projectContractFileInput"
          type="file"
          accept={CONTRACT_FILE_ALLOWED_FILE_TYPES.join(',')}
          hidden
          {...register('contractFile', {
            onChange: onContractFileChange,
            validate: (fileList: FileList | null): string | true => {
              if (fileList && fileList.length && fileList[0].size > CONTRACT_FILE_MAX_SIZE) {
                return 'ファイルサイズは10MB以下にしてください';
              }
              return true;
            },
          })}
        />
        <div className="attentionBlock">
          {errors.contractFile?.message && <WarningMessage message={errors.contractFile.message} />}
        </div>

        <div className={styles.inputInfoSection}>入金予定日</div>
        <p className={styles.paymentDueDateDescription}>
          スケジュールをもとに翌月末が自動設定されています。 変更の必要があれば編集してください。
        </p>
        <div className={styles.paymentDueSelectSection}>
          <div className={styles.paymentDueDateText}>{convertDateFormat(watch('paymentDueDate'))}</div>
          <IonButton
            fill="clear"
            onClick={() => {
              setPaymentDueDateModalIsOpen(true);
            }}
          >
            <IonIcon icon={calendarOutline} className={styles.dateCalendarIcon} />
          </IonButton>

          <Controller
            control={control}
            name="paymentDueDate"
            defaultValue={dayjs().add(1, 'M').endOf('month').toISOString()}
            render={() => (
              <Modal
                isOpen={paymentDueDateModalIsOpen}
                onRequestClose={() => setPaymentDueDateModalIsOpen(false)}
                style={modalStyles}
              >
                <SelectDateModal
                  onChange={(e) => {
                    typeof e.target.value == 'string' && setValue('paymentDueDate', e.target.value);
                  }}
                  closeModal={() => setPaymentDueDateModalIsOpen(false)}
                  value={watch('paymentDueDate') ?? dayjs().add(1, 'M').endOf('month').toISOString()}
                />
              </Modal>
            )}
          />
        </div>
      </div>
    </div>
  );
};
