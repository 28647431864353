import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuthInfo } from 'auth';

export const IndexPage: React.FC = () => {
  const { authInfo, isAuthChecking } = useAuthInfo();
  const history = useHistory();

  useEffect(() => {
    if (isAuthChecking) return;

    if (authInfo.userId) {
      // 既にログイン済ならトップページへ
      history.replace('/project');
    } else {
      // 未ログインならログインページへ
      history.replace('/login');
    }
  }, [authInfo, isAuthChecking]);

  return null;
};
