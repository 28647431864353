import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';

interface PatchUserPasswordRequest {
  currentPassword: string;
  newPassword: string;
}

export const usePasswordChange = () => {
  const { authInfo, currentUser } = useAuthInfo();
  const patchUserPassword = async (data: PatchUserPasswordRequest) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`users/${authInfo.userId}/password`, idToken);
    const res = await apiClient.patch(data).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { patchUserPassword };
};
