import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import axios, { AxiosError } from 'axios';
import mime from 'mime';

export enum ImageFileType {
  Thumbnail = 'project_thumbnail',
  ContractFile = 'project_contract_file',
  ProfileMain = 'profile_main',
  AchievementPhoto = 'achievement_photo',
}

interface PostPresignedUrlResponse {
  fileKey: string;
  presignedUrl: string;
  contentType: string;
}

export const useUploadFile = () => {
  const { currentUser } = useAuthInfo();
  const uploadFile = async (type: ImageFileType, file: File, croppedImageFileBlob: Blob | null = null) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient('presigned_url', idToken);

    const extension = mime.getExtension(file.type);

    const res = await apiClient
      .post<PostPresignedUrlResponse>({ type, extension })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });

    // https://ks-rogers.slack.com/archives/GGDP53AJG/p1685710811266279?thread_ts=1685280047.899869&cid=GGDP53AJG
    //  サーバーとクライアントでの、拡張子からcontent-typeを割り出すロジックの差を解消するため、
    // サーバー側で署名付きURLを発行した際のcontent-typeをレスポンスで追加で返すようにして、それをクライアント側のアップロード時に利用する
    const { fileKey, presignedUrl, contentType } = res.data;

    // ファイルアップロード先はbackend apiではないため、axiosをそのまま利用する。
    await axios
      .put(`${presignedUrl}`, croppedImageFileBlob || file, {
        headers: {
          'content-type': contentType,
        },
      })
      .catch((e) => {
        throw e;
      });

    return fileKey;
  };

  return { uploadFile };
};
