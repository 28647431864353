// iOS機種であるかを返す
export const isIos = () => {
  return /iPhone|iPad/i.test(navigator.userAgent);
};

// Android機種であるかを返す
export const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
};

// スマホであるかを返す
export const isSmartPhone = () => {
  return isIos() || isAndroid();
};
