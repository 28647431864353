import { atom } from 'jotai';

type SaleHistory = {
  isNeedRefresh: boolean;
};
// 売上管理画面ストアのデフォルト値
export const DEFAULT_SALE_HISTORY_ATOM = {
  isNeedRefresh: false,
};
// 売上管理画面ストア
export const saleHistoryAtom = atom<SaleHistory>(DEFAULT_SALE_HISTORY_ATOM);

type SaleUnpaid = {
  isNeedRefresh: boolean;
};
// 未入金リスト画面ストアのデフォルト値
export const DEFAULT_SALE_UNPAID_ATOM = {
  isNeedRefresh: false,
};
// 未入金リスト画面ストア
export const saleUnpaidAtom = atom<SaleUnpaid>(DEFAULT_SALE_UNPAID_ATOM);
