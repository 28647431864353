import React, { useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFooter, useIonToast } from '@ionic/react';
import { AxiosError } from 'axios';

import { useAuthInfo } from 'auth';
import { useGetProfileUser } from 'api/hooks/profile/useGetProfileUser';

import { Top } from 'components/profile_setting/Top';
import { TabBar } from 'components/common/TabBar';

import { ProfileUser } from 'models';
import { useLocation } from 'react-router';

import { withLogin } from 'pages/hocs/withLogin';

export const ProfileSettingPage: React.FC = withLogin(() => {
  const [profileUser, setProfileUser] = useState<ProfileUser>();

  const { authInfo } = useAuthInfo();
  const { getProfileUser } = useGetProfileUser();
  const [presentToast] = useIonToast();
  const { pathname } = useLocation();

  const getProfileUserInfo = (userId: number) => {
    getProfileUser(userId)
      .then((res) => {
        setProfileUser(res);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  useEffect(() => {
    if (authInfo.userId) {
      getProfileUserInfo(authInfo.userId);
    }
  }, [pathname]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonTitle>宣伝用プロフィール</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Top profileUser={profileUser} />
      </IonContent>
      <IonFooter>
        <TabBar selectedTab="profile_setting" />
      </IonFooter>
    </IonPage>
  );
});
