import { FC, useState } from 'react';
import { useHistory } from 'react-router';

import styles from './ProjectItem.module.scss';
import { Project, ProjectStatus } from 'models/project';
import { useIonAlert, useIonToast } from '@ionic/react';
import { AxiosError } from 'axios';
import { useUpdateProject } from 'api/hooks/project/useUpdateProject';
import dayjs from 'dayjs';

interface ProjectItemProps {
  project: Project;
}

export const ProjectItem: FC<ProjectItemProps> = ({ project: project }) => {
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const history = useHistory();

  const [projectStatus, setProjectStatus] = useState(project.status);

  const { patchProject } = useUpdateProject();

  const returnToUnpaid = () => {
    presentAlert({
      mode: 'ios',
      header: '確認',
      message: '本当に未入金のステータスに戻しますか？',
      buttons: [
        {
          text: '戻る',
        },
        {
          text: '　未入金に変更　',
          handler: () => {
            execReturnToUnpaid();
          },
        },
      ],
    });
  };

  const execReturnToUnpaid = () => {
    const patchProjectParam = {
      name: project.name,
      status: ProjectStatus.Contracted,
      endDate: project.endDate,
      clientId: project.client.id,
      paymentDate: null,
      contractPrice: project.contractPrice,
    };
    patchProject(project.id, patchProjectParam)
      .then(() => {
        setProjectStatus(ProjectStatus.Contracted);

        presentToast({
          message: '未入金に変更しました',
          duration: 2000,
        });
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  return (
    <div
      className={styles.wrap}
      onClick={() => {
        history.push({
          pathname: `/project/${project.id}`,
          search: `?returnUrl=${history.location.pathname}`,
        });
      }}
    >
      <div className={styles.topFlex}>
        <p className={styles.salesDate}>
          {project.startDate ? `${dayjs(project.startDate).format('YYYY年M月D日')}〜` : ''}
          {dayjs(project.endDate).format('YYYY年M月D日')}
        </p>
        {projectStatus === ProjectStatus.Paid && <p className={styles.paymentStatus}>入金済み</p>}
      </div>
      <p className={styles.projectName}>{project.name}</p>
      <p className={styles.clientName}>{project.client?.name}</p>
      <div className={styles.bottomFlex}>
        <p className={styles.contractPrice}>{project.contractPrice.toLocaleString()}円</p>
        {projectStatus === ProjectStatus.Paid && (
          <button
            className={styles.returnToUnpaid}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              returnToUnpaid();
            }}
          >
            未入金に戻す
          </button>
        )}
      </div>
    </div>
  );
};
