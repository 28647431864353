import React from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { PasswordSettingForm } from 'components/password/PasswordSettingForm';
import { BackButton } from 'components/common/buttons/BackButton';

export const PasswordSettingPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/login" />
          </IonButtons>
          <IonTitle>パスワードの再設定</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PasswordSettingForm />
      </IonContent>
    </IonPage>
  );
};
