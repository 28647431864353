import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';

interface DeleteUserRequest {
  email: string;
  password: string;
}

export const useDeleteUser = () => {
  const { authInfo, currentUser } = useAuthInfo();
  const deleteUser = async (data: DeleteUserRequest) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`users/${authInfo.userId}`, idToken);
    const res = await apiClient.delete(data).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { deleteUser };
};
