import React from 'react';
import { IonIcon, IonImg, IonItem } from '@ionic/react';

import styles from './MailChangeComplete.module.scss';

export const MailChangeComplete: React.FC = () => {
  const goToLogin = () => {
    window.location.replace('/login');
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.logo}>
        <IonImg className={styles.img} src={'assets/common/img__complete.svg'} alt="complete" />
      </div>
      <p className={styles.note}>
        メールアドレスの変更が
        <br />
        完了しました。
      </p>
      <div className={styles.links}>
        <IonItem className={styles.link} onClick={goToLogin} lines="none">
          ログイン
          <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
        </IonItem>
      </div>
    </div>
  );
};
