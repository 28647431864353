import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Client } from 'models';

export const useGetClients = () => {
  const { currentUser } = useAuthInfo();

  const getClients = async () => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`clients`, idToken);
    const res = await apiClient.get<Client[]>().catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { getClients };
};
