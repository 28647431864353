import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';

import { AuthContext } from './authContext';

export const useRedirectIfLoggedIn = () => {
  const { authInfo, isAuthChecking } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (isAuthChecking) return;
    // 既にログイン済ならトップページへ
    if (authInfo.userId) {
      history.replace('/project');
    }
  }, [authInfo, isAuthChecking]);
};
