import { IonButton, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, useIonToast } from '@ionic/react';
import { FC, useEffect, useRef, useState } from 'react';
import { Project } from 'models/project';
import styles from './SaleHistories.module.scss';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { ProjectItem } from './ProjectItem';
import { useGetSales } from 'api/hooks/sale/useGetSales';
import { useGetSaleHistories } from 'api/hooks/sale/useGetSaleHistories';
import { AxiosError } from 'axios';
import { useDownloadSaleFile } from 'api/hooks/sale/useDownloadSaleFile';

import { useAtom } from 'jotai';
import { saleHistoryAtom } from 'stores/sale';

const SALE_HISTORIES_LIMIT = 20;

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

export const SaleHistories: FC = () => {
  const [saleHistoryAtomValue, setSaleHistoryAtomValue] = useAtom(saleHistoryAtom);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [monthSales, setMonthSales] = useState<number[]>([]);
  const [totalSales, setTotalSales] = useState(0);
  const [saleHistories, setSaleHistories] = useState<Project[]>([]);
  const [isInitSearched, setIsInitSearched] = useState(false);
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);

  const { getSales } = useGetSales();
  const { getSaleHistories } = useGetSaleHistories();
  const { downloadSaleFile } = useDownloadSaleFile();

  const [presentToast] = useIonToast();

  const fetchMoreSaleHistories = () => {
    getSaleHistories(currentYear, SALE_HISTORIES_LIMIT, saleHistories.length)
      .then((res) => {
        setSaleHistories([...saleHistories, ...res]);
        infiniteScrollRef.current?.complete();
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          drawTicks: false,
          drawOnChartArea: false,
        },
        offset: true,
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
          count: 6,
        },
        grid: {
          color: '#00A5C333',
          drawTicks: false,
        },
        offset: true,
        border: {
          display: false,
        },
      },
    },
  };

  const labels = new Array<string>(12).fill('');

  const data = {
    labels,
    datasets: [
      {
        data: monthSales,
        borderColor: '#00A5C3',
        backgroundColor: '#ffffff',
        borderWidth: 1,
        pointRadius: 5.5,
      },
    ],
  };

  const handleClickDownloadButton = () => {
    downloadSaleFile(currentYear).catch((e: AxiosError<{ message: string; type: string }>) => {
      presentToast({
        message: e.response?.data?.message,
        duration: 2000,
      });
    });
  };

  // 売上データを検索する
  const fetchSales = (currentYearParam: number) => {
    getSales(currentYearParam)
      .then((res) => {
        setMonthSales(res.monthSales);
        setTotalSales(res.totalSales);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });

    getSaleHistories(currentYearParam, SALE_HISTORIES_LIMIT, 0)
      .then((res) => {
        setSaleHistories(res);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  // 遷移先画面でデータが更新されたため売上データを再検索する
  const fetchSalesForRefresh = (currentYearParam: number, limit: number) => {
    getSales(currentYearParam)
      .then((res) => {
        setMonthSales(res.monthSales);
        setTotalSales(res.totalSales);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });

    getSaleHistories(currentYearParam, limit, 0)
      .then((res) => {
        setSaleHistories(res);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  // 初期表示および対象年を変更した場合、売上データを検索する
  useEffect(() => {
    fetchSales(currentYear);
    setIsInitSearched(true);
  }, [currentYear]);

  // 未入金一覧画面で入金確認した場合、または案件情報編集画面で更新した場合、再検索する
  useEffect(() => {
    if (saleHistoryAtomValue.isNeedRefresh && isInitSearched) {
      // リフレッシュの場合は取得済の件数分を取得する
      const limit = saleHistories.length || SALE_HISTORIES_LIMIT;
      setSaleHistoryAtomValue((prevState) => ({ ...prevState, isNeedRefresh: false }));
      // 念のためクリアする
      setMonthSales([]);
      setTotalSales(0);
      setSaleHistories([]);
      // 売上データを検索する
      fetchSalesForRefresh(currentYear, limit);
    }
  }, [saleHistoryAtomValue.isNeedRefresh]);

  const isNoSaleData = monthSales.length > 0 && monthSales.every((sale) => sale === 0);
  return (
    <>
      <div className={styles.wrapGraphArea}>
        {isNoSaleData ? (
          <div className={styles.noSaleData}>
            <p>データがありません</p>
          </div>
        ) : (
          <Line options={options} data={data} width={window.innerWidth - 36} height={250} />
        )}
        <div className={styles.chooseYearButtonArea}>
          <IonButton
            fill="clear"
            className={styles.chooseYearButton}
            onClick={() => setCurrentYear((currentYear) => Math.max(currentYear - 1, 0))}
          >
            <IonIcon icon={chevronBackOutline} />
          </IonButton>
          <span className={styles.currentYearSalesText}>
            {currentYear}年 合計￥{totalSales.toLocaleString()}
          </span>
          <IonButton
            fill="clear"
            className={styles.chooseYearButton}
            onClick={() => setCurrentYear((currentYear) => Math.min(currentYear + 1, new Date().getFullYear()))}
          >
            <IonIcon icon={chevronForwardOutline} />
          </IonButton>
        </div>
      </div>
      <div className={styles.wrapProjectsArea}>
        <div className={styles.projectListTitle}>一覧</div>
        <ul className={styles.projectItemList}>
          {saleHistories.map((project) => (
            <li key={project.id}>
              <ProjectItem project={project} />
            </li>
          ))}
        </ul>
      </div>
      <IonInfiniteScroll onIonInfinite={fetchMoreSaleHistories} ref={infiniteScrollRef}>
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>
      <IonButton className={styles.downloadButton} onClick={handleClickDownloadButton}>
        ダウンロード
      </IonButton>
    </>
  );
};
