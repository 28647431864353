import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Client } from 'models';

export const useClientGet = () => {
  const { currentUser } = useAuthInfo();
  const getClientInfo = async (id: number) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`clients/${id}`, idToken);
    const res = await apiClient.get<Client>().catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { getClientInfo };
};
