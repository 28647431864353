import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';

export interface PutPageStyleParams {
  textColor: string;
  backgroundColor: string;
}

export const useUpdatePageStyle = () => {
  const { authInfo, currentUser } = useAuthInfo();
  const putPageStyle = async (params: PutPageStyleParams) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`users/${authInfo.userId}/page_style`, idToken);
    const res = await apiClient.put(params).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { putPageStyle };
};
