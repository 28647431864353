import { IBasicAuthInfo } from './basicAuthContext';

const ADMIN_BASIC_AUTH_KEY_CREDENTIALS = 'ADMIN_BASIC_AUTH_KEY_CREDENTIALS';
const ADMIN_BASIC_AUTH_KEY_EXPIRES_AT = 'ADMIN_BASIC_AUTH_KEY_EXPIRES_AT';

export const getCredentialsFromLocalStorage = (): Partial<IBasicAuthInfo> => {
  const credentials = window.localStorage.getItem(ADMIN_BASIC_AUTH_KEY_CREDENTIALS) || '';
  const expiresAt = parseInt(window.localStorage.getItem(ADMIN_BASIC_AUTH_KEY_EXPIRES_AT) || '', 10) || 0;
  // 期限切れの場合
  if (Date.now() > expiresAt) {
    return {
      credentials: '',
      expiresAt: 0,
    };
  }
  // 期限ぎれてない場合
  return {
    credentials,
    expiresAt,
  };
};

export const setCredentialsToLocalStorage = ({ credentials, expiresAt }: Partial<IBasicAuthInfo>): void => {
  window.localStorage.setItem(ADMIN_BASIC_AUTH_KEY_CREDENTIALS, credentials || '');
  window.localStorage.setItem(ADMIN_BASIC_AUTH_KEY_EXPIRES_AT, `${expiresAt}`);
};

export const removeCredentialsFromLocalStorage = (): void => {
  window.localStorage.removeItem(ADMIN_BASIC_AUTH_KEY_CREDENTIALS);
  window.localStorage.removeItem(ADMIN_BASIC_AUTH_KEY_EXPIRES_AT);
};
