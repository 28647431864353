import { AxiosError } from 'axios';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { IonImg, useIonAlert, useIonLoading, useIonToast } from '@ionic/react';

import styles from './PhotoList.module.scss';

import { IMAGE_FILE_MAX_SIZE } from 'consts';
import { ProfilePhoto } from 'models';
import { useGetPhoto } from 'api/hooks/profile/useGetPhoto';
import { useUpdatePhoto } from 'api/hooks/profile/useUpdatePhoto';
import { ImageFileType, useUploadFile } from 'api/hooks/upload/useUploadFile';

const groupArray = (array: number[], chunkSize: number) => {
  if (chunkSize < 1) return [array];

  const groupedArray = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    groupedArray.push(array.slice(i, i + chunkSize));
  }
  return groupedArray;
};

interface PhotoListProps {
  photoImgSize: number;
  columnCnt: number;
}

export const PhotoList: FC<PhotoListProps> = ({ photoImgSize, columnCnt }) => {
  const [photoList, setPhotoList] = useState<ProfilePhoto[]>([]);
  const [isFetched, setIsFetched] = useState(false);
  const [updateWaitingFiles, setUpdateWaitingFiles] = useState<
    { displayNumber: number; file: File; selectedPhotoUrl: string }[]
  >([]);

  const { getPhoto } = useGetPhoto();
  const { updatePhoto } = useUpdatePhoto();
  const { uploadFile } = useUploadFile();

  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();

  const history = useHistory();
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const unblockRef = useRef(() => {});

  // フォトの枠は14固定なので配列として用意
  const photoBox = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

  const isPhotoError = (file: File): boolean => {
    if (file.size > IMAGE_FILE_MAX_SIZE) {
      return false;
    }
    return true;
  };

  // 更新処理・削除処理の場合、選択した枠のphotoのみが更新されるようにする
  const replaceLatestPhotoList = (displayNumber: number, photoImageUrl: string) => {
    const targetPhoto = photoList.find((photo) => photo.displayNumber === displayNumber);
    const latestUpdateWaitingList = updateWaitingFiles.filter((photo) => photo.displayNumber !== displayNumber);
    setUpdateWaitingFiles(latestUpdateWaitingList);

    // APIで取得したデータの中に引数と同じdisplayNumberがある場合は上書き
    if (targetPhoto) {
      const updatedPhotoList = photoList.map((photo) => {
        if (photo.displayNumber === displayNumber) {
          return {
            ...photo,
            photoImageUrl,
          };
        }
        return photo;
      });
      setPhotoList(updatedPhotoList);
      return;
    }

    // idは仮の定数を格納。実際のIDは画面リロード時に取得されるDBの値に差し替えられる。
    const tmpIdNumber = 99999999;
    setPhotoList((prevPhotoList) => [...prevPhotoList, { id: tmpIdNumber, displayNumber, photoImageUrl }]);
  };

  const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>, displayNumber: number) => {
    if (e.target.files?.length) {
      const selectedPhoto = e.target.files[0];
      if (!isPhotoError(selectedPhoto)) {
        const message = `画像ファイルサイズ容量は5MB以下にしてください`;
        presentToast({
          message,
          duration: 2000,
        });
        return;
      }

      presentLoading({
        duration: 1000,
      });

      // この時点ではアップロードしていないためURLを取得しsrcで使用する
      const reader = new FileReader();
      reader.addEventListener('load', (e) => {
        if (reader.result) {
          const selectedPhotoUrl = e.target?.result ? e.target.result.toString() : '';
          // 複数回登録しても大丈夫にする
          const excludeAlreadySetWaitingFIles = updateWaitingFiles.filter(
            (waitingPhoto) => waitingPhoto.displayNumber !== displayNumber
          );
          setUpdateWaitingFiles([
            ...excludeAlreadySetWaitingFIles,
            { displayNumber, file: selectedPhoto, selectedPhotoUrl },
          ]);
        }
      });
      reader.readAsDataURL(e.target.files[0]);

      dismissLoading();
    }
  };

  const uploadHandler = async (displayNumber: number) => {
    const selectedWaitingPhoto = updateWaitingFiles.find((items) => items.displayNumber === displayNumber);
    presentLoading({
      duration: 1000,
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const photoFileKey = await uploadFile(ImageFileType.AchievementPhoto, selectedWaitingPhoto!.file).catch(
      (e: AxiosError<{ message: string; type: string }>) => {
        dismissLoading();
        presentAlert({
          header: 'ファイルアップロードエラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
        return Promise.reject(e);
      }
    );

    await updatePhoto({ displayNumber, photoImageKey: photoFileKey })
      .then((res) => {
        presentToast({
          message: '画像を登録しました。',
          duration: 2000,
        });
        replaceLatestPhotoList(displayNumber, res.photoImageUrl);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        dismissLoading();
        presentAlert({
          header: '更新エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      });

    dismissLoading();
  };

  const deleteHandler = (displayNumber: number) => {
    const targetItem = photoList.find((item) => item.displayNumber === displayNumber);
    const targetLocalItem = updateWaitingFiles.find((item) => item.displayNumber === displayNumber);
    if (!targetItem?.photoImageUrl && !targetLocalItem?.selectedPhotoUrl) {
      presentToast({
        message: '登録されている写真がありません。',
        duration: 2000,
      });
      return;
    }
    presentAlert({
      header: '確認',
      message: '画像を削除します。よろしいですか？',
      buttons: [
        { text: 'キャンセル' },
        {
          text: 'OK',
          handler: () => {
            if (targetLocalItem) {
              const excludeUpdateWaitingFiles = updateWaitingFiles.filter(
                (item) => item.displayNumber !== displayNumber
              );
              setUpdateWaitingFiles(excludeUpdateWaitingFiles);
              presentToast({
                message: '画像を削除しました。',
                duration: 2000,
              });
              return;
            }
            updatePhoto({ displayNumber, photoImageKey: '' })
              .then(() => {
                presentToast({
                  message: '画像を削除しました。',
                  duration: 2000,
                });
                replaceLatestPhotoList(displayNumber, '');
              })
              .catch((e: AxiosError<{ message: string; type: string }>) => {
                presentToast({
                  message: e.response?.data?.message,
                  duration: 2000,
                });
              });
          },
        },
      ],
    });
  };

  const showBackConfirmationAlert = (navigate: () => void, cancel: () => void) => {
    presentAlert({
      mode: 'ios',
      header: '確認',
      message: '保存せずに移動すると入力内容が破棄されますが良いですか？',
      buttons: [
        {
          text: '　移動する　',
          handler: navigate,
        },
        {
          text: '　フォト画面に戻る　',
          handler: cancel,
        },
      ],
    });
  };

  useEffect(() => {
    getPhoto()
      .then((val) => {
        setPhotoList(val);
        setIsFetched(true);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        // 404未設定の場合はエラーメッセージを表示しない
        if (e.response?.status === 404) {
          // 初期表示はフォトのデータがないが、初期値を表示
          setPhotoList([]);
          setIsFetched(true);
          return;
        }

        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  }, []);

  useEffect(() => {
    if (pathname === '/profile_setting/photo') {
      // ブラウザバック・戻るボタン での遷移時にアラートを表示する。
      unblockRef.current = history.block((location, action) => {
        // フォームに一つでも値が変更されている時のみアラートを表示する
        if (updateWaitingFiles.length) {
          showBackConfirmationAlert(
            () => {
              unblockRef.current();
              if (action === 'POP') {
                history.goBack();
              } else if (action === 'REPLACE') {
                history.replace(location.pathname);
              } else {
                history.push(location.pathname);
              }
            },
            () => {
              // react-routerのバグで詳細へ戻るなのにREPLACEになってしまうことがある
              // フォト画面へpushするよう履歴スタックをやり直す
              if (action === 'REPLACE') {
                history.push('/profile_setting/photo');
              }
            }
          );

          return false;
        }
      });
    }

    return () => {
      unblockRef.current();
    };
  }, [pathname, updateWaitingFiles]);

  const renderPhotoCard = (boxNumber: number) => {
    const photoData = photoList.find((photo) => photo.displayNumber === boxNumber);
    const isUpdateWaitingFile = updateWaitingFiles.find((item) => item.displayNumber === boxNumber);
    return (
      <div className={styles.photoCard} key={`${boxNumber}-${photoData?.id}`}>
        {photoData?.photoImageUrl || isUpdateWaitingFile ? (
          <>
            <label htmlFor={`photoAddInput${boxNumber}`} className={styles.changeCircle}>
              <IonImg src={'assets/profile_setting/icn__changePhoto.svg'} className={styles.changePhotoIcon} />
            </label>
            <input
              id={`photoAddInput${boxNumber}`}
              type="file"
              accept="image/jpg,image/jpeg,image/png"
              value=""
              hidden
              onChange={(e) => handlePhotoChange(e, boxNumber)}
              onClick={(e) => {
                // valueを初期化する
                e.currentTarget.value = '';
              }}
            />
            <IonImg src={isUpdateWaitingFile?.selectedPhotoUrl || photoData?.photoImageUrl} className={styles.photo} />
            {isUpdateWaitingFile ? (
              <button className={styles.uploadCircle} onClick={() => uploadHandler(boxNumber)}>
                <IonImg src={'assets/profile_setting/icn__uploadPhoto.svg'} className={styles.uploadPhotoIcon} />
              </button>
            ) : null}
            <button className={styles.trashCircle} onClick={() => deleteHandler(boxNumber)}>
              <IonImg src={'assets/profile_setting/icn__trashPhoto.svg'} className={styles.trashPhotoIcon} />
            </button>
          </>
        ) : (
          <>
            <label htmlFor={`photoAddInput${boxNumber}`}>
              <IonImg src={'assets/profile_setting/icn__addPhoto.svg'} className={styles.addPhotoIcon} />
            </label>
            <input
              id={`photoAddInput${boxNumber}`}
              type="file"
              accept="image/jpg,image/jpeg,image/png"
              hidden
              onChange={(e) => handlePhotoChange(e, boxNumber)}
            />
          </>
        )}
      </div>
    );
  };

  // 一瞬photoが表示されてしまう問題を避けるよう、初期検索が終わるまで描画しない
  if (!isFetched) return null;

  const photoGroups = groupArray(photoBox, columnCnt);

  const style: { [key: string]: string } = {
    '--photo-img-size': `${photoImgSize}px`,
  };

  return (
    <div className={styles.wrap} style={style}>
      {photoGroups.map((photoGroup: number[], index) => {
        const photos = photoGroup.map((boxNumber) => {
          return renderPhotoCard(boxNumber);
        });
        return (
          <div className={styles.photoRow} key={`photoGroup${index}`}>
            {photos}
          </div>
        );
      })}
    </div>
  );
};
