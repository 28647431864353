import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Client, ClientFormValues } from 'models';

export const useClientEdit = () => {
  const { currentUser } = useAuthInfo();
  const patchClientInfo = async (id: string, data: ClientFormValues) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`clients/${id}`, idToken);
    const res = await apiClient.patch<Client>(data).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res;
  };

  return { patchClientInfo };
};
