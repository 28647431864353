import React from 'react';

import { IonIcon, IonItem } from '@ionic/react';
import { useHistory } from 'react-router';

import { auth } from 'utils/firebase';
import { TERM_URL, PRIVACY_POLICY_URL, INQUIRY_URL } from 'consts/app';

import styles from './Setting.module.scss';

export const Setting: React.FC = () => {
  const history = useHistory();
  const goToMailSetting = () => {
    history.push('/setting/mail');
  };

  const goToPasswordSetting = () => {
    history.push('/setting/password');
  };

  const goToPlanSetting = () => {
    history.push('/plan');
  };

  const goToWithdrawal = () => {
    history.push('/withdrawal');
  };

  const goToTerm = () => {
    window.open(TERM_URL, '_blank', 'noopener noreferrer');
  };

  const goToPrivacyPolicy = () => {
    window.open(PRIVACY_POLICY_URL, '_blank', 'noopener noreferrer');
  };

  const goToInquiry = () => {
    window.open(INQUIRY_URL, '_blank', 'noopener noreferrer');
  };

  const logout = () => {
    auth.signOut().finally(() => {
      window.location.href = '/login';
    });
  };

  return (
    <div className={styles.links}>
      <IonItem className={styles.link} onClick={goToMailSetting} lines="none">
        メールアドレスの変更
        <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
      </IonItem>
      <IonItem className={styles.link} onClick={goToPasswordSetting} lines="none">
        パスワード変更
        <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
      </IonItem>
      <IonItem className={styles.link} onClick={goToPlanSetting} lines="none">
        プラン設定
        <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
      </IonItem>
      <IonItem className={styles.link} onClick={goToWithdrawal} lines="none">
        退会
        <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
      </IonItem>
      <IonItem className={styles.link} onClick={goToTerm} lines="none">
        利用規約
        <IonIcon className={styles.browser} slot="end" src="assets/common/icn__browser-normal.svg" />
      </IonItem>
      <IonItem className={styles.link} onClick={goToPrivacyPolicy} lines="none">
        個人情報保護に関する告知文
        <IonIcon className={styles.browser} slot="end" src="assets/common/icn__browser-normal.svg" />
      </IonItem>
      <IonItem className={styles.link} onClick={goToInquiry} lines="none">
        お問い合わせ
        <IonIcon className={styles.browser} slot="end" src="assets/common/icn__browser-normal.svg" />
      </IonItem>
      <IonItem className={`${styles.link} ${styles.logout}`} onClick={logout} lines="none">
        ログアウト
      </IonItem>
    </div>
  );
};
