// 対象文字列が平仮名であるかを返す
export const isHiragana = (value: string) => {
  // 平仮名の正規表現パターン
  const hiraganaPattern = /^[\u3040-\u309Fー]+$/;

  return hiraganaPattern.test(value);
};

// 文字列から "-" と "e" を除去
export const removeHyphenAndE = (value: string) => {
  // 正規表現で "-" と "e" を検索し、空文字列に置き換える
  return value?.replace(/[-e]/g, '');
};
