import React from 'react';

import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { AuthProvider } from 'auth';
import { BasicAuthProvider } from 'basicAuth';

import { IndexPage } from 'pages';
import { WelcomePage } from 'pages/welcome';
import { LoginPage } from 'pages/login';
import { UserRegisterPage } from 'pages/user/register';
import { UserRegisterCompletePage } from 'pages/user/register_complete';
import { PasswordResetPage } from 'pages/password_reset';
import { PasswordSettingPage } from 'pages/password_setting';
import { ClientRegisterPage } from 'pages/client/register';
import { ClientEditPage } from 'pages/client/edit';
import { SettingPage } from 'pages/setting';
import { ProjectPage } from 'pages/project';
import { ProjectDetailPage } from 'pages/project/detail';
import { ProjectRegisterPage } from 'pages/project/register';
import { ProjectEditPage } from 'pages/project/edit';
import { SettingMailPage } from 'pages/setting/mail';
import { SettingMailCompletePage } from 'pages/setting/mail_complete';
import { SettingPasswordPage } from 'pages/setting/password';
import { SettingPlanPage } from 'pages/setting/plan';
import { SettingWithdrawalPage } from 'pages/setting/withdrawal';
import { SaleHistoryPage } from 'pages/sale/history';
import { SaleUnpaidPage } from 'pages/sale/unpaid';
import { ChatClientPage } from 'pages/chat/client';
import { ChatModelPage } from 'pages/chat/model';
import { AdminTopPage } from 'pages/admin';
import { AdminUserPage } from 'pages/admin/user';
import { ProfileSettingPage } from 'pages/profile_setting';
import { ProfileSettingStylePage } from 'pages/profile_setting/style';
import { ProfileSettingDetailPage } from 'pages/profile_setting/detail';
import { ProfileSettingPhotoPage } from 'pages/profile_setting/photo';
import { NotFoundPage } from 'pages/404';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <AuthProvider>
      <BasicAuthProvider>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/welcome" component={WelcomePage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/user/register" component={UserRegisterPage} />
            <Route exact path="/user/register_complete" component={UserRegisterCompletePage} />
            <Route exact path="/password_reset" component={PasswordResetPage} />
            <Route exact path="/password_setting" component={PasswordSettingPage} />
            <Route exact path="/setting" component={SettingPage} />
            <Route exact path="/project" component={ProjectPage} />
            <Route exact path="/project/:projectId" component={ProjectDetailPage} />
            <Route exact path="/project/:projectId/edit" component={ProjectEditPage} />
            <Route exact path="/project/:projectId/edit/client/:id/edit" component={ClientEditPage} />
            <Route exact path="/project/:projectId/edit/client/register" component={ClientRegisterPage} />
            <Route exact path="/project/register" component={ProjectRegisterPage} />
            <Route exact path="/project/register/client/:id/edit" component={ClientEditPage} />
            <Route exact path="/project/register/client/register" component={ClientRegisterPage} />
            <Route exact path="/setting/mail_complete" component={SettingMailCompletePage} />
            <Route exact path="/setting/mail" component={SettingMailPage} />
            <Route exact path="/setting/password" component={SettingPasswordPage} />
            <Route exact path="/plan" component={SettingPlanPage} />
            <Route exact path="/withdrawal" component={SettingWithdrawalPage} />
            <Route exact path="/sale/history" component={SaleHistoryPage} />
            <Route exact path="/sale/unpaid" component={SaleUnpaidPage} />
            <Route exact path="/chat/:chatRoomKey/client" component={ChatClientPage} />
            <Route exact path="/chat/:chatRoomKey/model" component={ChatModelPage} />
            <Route exact path="/admin" component={AdminTopPage} />
            <Route exact path="/admin/user" component={AdminUserPage} />
            <Route exact path="/profile_setting" component={ProfileSettingPage} />
            <Route exact path="/profile_setting/style" component={ProfileSettingStylePage} />
            <Route exact path="/profile_setting/detail" component={ProfileSettingDetailPage} />
            <Route exact path="/profile_setting/photo" component={ProfileSettingPhotoPage} />
            <Route component={NotFoundPage} />
          </IonRouterOutlet>
        </IonReactRouter>
      </BasicAuthProvider>
    </AuthProvider>
  </IonApp>
);

export default App;
