import React from 'react';
import { IonIcon, IonItem } from '@ionic/react';

import { WarningMessage } from 'components/common/WarningMessage';

import styles from './UserRegisterError.module.scss';

interface UserRegisterErrorProps {
  errorMessage: string;
  isActivated: boolean;
}

export const UserRegisterError: React.FC<UserRegisterErrorProps> = ({ errorMessage, isActivated }) => {
  const goToLogin = () => {
    window.location.replace('/login');
  };

  return (
    <div className={styles.wrap}>
      <div className={`attentionBlock ${styles.error}`}>
        <WarningMessage message={errorMessage} />
      </div>
      {isActivated && (
        <div className={styles.links}>
          <IonItem className={styles.link} onClick={goToLogin} lines="none">
            ログイン
            <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
          </IonItem>
        </div>
      )}
    </div>
  );
};
