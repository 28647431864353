import { ApiClient } from 'api/apiClient';
import { AxiosError } from 'axios';

import { ProfileUser } from 'models';

export const useGetProfileUser = () => {
  const getProfileUser = async (userId: number) => {
    const apiClient = new ApiClient(`profiles/users/${userId}`, '');
    const res = await apiClient.get<ProfileUser>().catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { getProfileUser };
};
