import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';

interface PostActivateRequest {
  id: string;
  activationCode: string;
}

export const useActivateUser = () => {
  const postActivate = async (data: PostActivateRequest) => {
    const apiClient = new ApiClient('activate', '');
    const res = await apiClient.post(data).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { postActivate };
};
