import React from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { PasswordResetForm } from 'components/password/PasswordResetForm';
import { BackButton } from 'components/common/buttons/BackButton';

export const PasswordResetPage: React.FC = () => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/login" />
          </IonButtons>
          <IonTitle>パスワードのリセット</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PasswordResetForm />
      </IonContent>
    </IonPage>
  );
};
