import React from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { PasswordChangeForm } from 'components/setting/PasswordChangeForm';
import { BackButton } from 'components/common/buttons/BackButton';

import { withLogin } from 'pages/hocs/withLogin';

export const SettingPasswordPage: React.FC = withLogin(() => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/setting" />
          </IonButtons>
          <IonTitle>パスワードの変更</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PasswordChangeForm />
      </IonContent>
    </IonPage>
  );
});
