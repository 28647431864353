import React, { useEffect, useState } from 'react';
import { useAuthInfo } from 'auth';

import { REDIRECT_URL } from 'consts';
import { removeAuthInfoFromStorage } from 'auth/storage';

export const withLogin = (WrappedComponent: React.FC) => {
  const WithLogin = () => {
    const [isMounted, setIsMounted] = useState(false);
    const { authInfo, currentUser, isAuthChecking } = useAuthInfo();

    useEffect(() => {
      if (isAuthChecking) return;

      if (!authInfo.userId || !currentUser) {
        // Token認証情報をクリアする
        removeAuthInfoFromStorage();

        // 手動ログアウトや退会などの場合は、リダイレクト処理を行わない
        if (!isMounted) {
          // 今現在のURLを記録してログイン画面へリダイレクトする
          const currentUrl = window.location.href.replace(window.location.origin, '');
          window.sessionStorage.setItem(REDIRECT_URL, currentUrl);
          window.location.href = '/login';
        }
      } else {
        setIsMounted(true);
      }
    }, [authInfo, currentUser, isAuthChecking, isMounted]);

    return isAuthChecking || !authInfo.userId || !currentUser ? null : <WrappedComponent />;
  };

  return WithLogin;
};
