import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { ProfilePhoto } from 'models';

export const useGetPhoto = () => {
  const { authInfo, currentUser } = useAuthInfo();
  const getPhoto = async () => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`users/${authInfo.userId}/achievement_photos`, idToken);
    const res = await apiClient.get<ProfilePhoto[]>().catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { getPhoto };
};
