import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { BackButton } from 'components/common/buttons/BackButton';
import { UnpaidProjects } from 'components/sales/UnpaidProjects';
import { FC } from 'react';

import { withLogin } from 'pages/hocs/withLogin';

export const SaleUnpaidPage: FC = withLogin(() => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/sale/history" />
          </IonButtons>
          <IonTitle>未入金リスト</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <UnpaidProjects />
      </IonContent>
    </IonPage>
  );
});
