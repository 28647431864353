import React from 'react';
import styles from './ProjectDetail.module.scss';
import dayjs from 'dayjs';
import { Project } from 'models';
import { IonButton } from '@ionic/react';
import { PROJECT_STATUS_CONTRACTED, PROJECT_STATUS_DEPOSIT_COMPLETED, PROJECT_STATUS_NEGOTIATION } from 'consts';
import classnames from 'classnames';

const convertDateFormat = (date: string) => {
  return dayjs(date).format('YYYY/MM/DD');
};

interface ProjectDetailProps {
  project: Project;
}

export const ProjectDetail: React.FC<ProjectDetailProps> = ({ project }) => {
  return (
    <div className={styles.detailPageLayout}>
      {project.status === PROJECT_STATUS_NEGOTIATION && <div className={styles.projectStatusNegotiation}>交渉中</div>}
      {project.status === PROJECT_STATUS_CONTRACTED && <div className={styles.projectStatusContracted}>契約済</div>}
      {project.status === PROJECT_STATUS_DEPOSIT_COMPLETED && (
        <div className={styles.projectStatusDepositCompleted}>入金済み</div>
      )}
      <div className={styles.projectTitle}>{project.name}</div>
      <div className={styles.projectCompany}>{project.client?.name}</div>
      <img
        className={classnames(
          styles.projectImage,
          project.thumbnailFileUrl == null ? styles.projectDefaultImage : null
        )}
        src={project.thumbnailFileUrl || (project.id ? '/assets/project/default_img.svg' : '')} // 案件詳細が取得されるまでにデフォルトイメージを表示しない
      />
      <div className={styles.columnFlex}>
        <div>
          <div className={styles.columnTitle}>契約金額</div>
          <div className={styles.priceText}>
            {project.contractPrice && project.contractPrice.toLocaleString()}
            <span className={styles.priceUnit}>円</span>
          </div>
        </div>
        <div>
          <div className={styles.columnTitle}>日程</div>
          {project.startDate && <div className={styles.littleText}>開始日：{convertDateFormat(project.startDate)}</div>}
          <div className={styles.littleText}>終了日：{convertDateFormat(project.endDate)}</div>
        </div>
      </div>
      <div>
        <div className={styles.columnTitle}>詳細</div>
        <div className={styles.description}>{project.description}</div>
      </div>
      <IonButton
        fill="outline"
        className={styles.contractFileButton}
        disabled={project.contractFileUrl === null}
        onClick={() => {
          window.open(project.contractFileUrl, '_blank', 'noopener noreferrer');
        }}
      >
        契約書ファイル
      </IonButton>
      <div>
        <div className={styles.columnTitle}>入金予定日</div>
        <div className={styles.littleText}>{convertDateFormat(project.paymentDueDate)}</div>
      </div>
    </div>
  );
};
