import React from 'react';

import { IonIcon, IonItem, IonButton } from '@ionic/react';
import { useHistory } from 'react-router';

import { ProfileUser } from 'models';

import styles from './Top.module.scss';

interface TopProps {
  profileUser?: ProfileUser;
}

export const Top: React.FC<TopProps> = ({ profileUser }) => {
  const history = useHistory();

  const handleClickProfileButton = () => {
    window.open(
      `${process.env.REACT_APP_MANAGEMENT_PROFILE_HOST}/${profileUser?.accountName}`,
      '_blank',
      'noopener noreferrer'
    );
  };

  const goToProfileDetail = () => {
    history.push('/profile_setting/detail');
  };

  const goToPhoto = () => {
    history.push('/profile_setting/photo');
  };

  const goToStyle = () => {
    history.push('/profile_setting/style');
  };

  return (
    <div className={styles.links}>
      <IonItem className={styles.blank} lines="none">
        <IonButton
          fill="clear"
          className={styles.profileBtn}
          onClick={handleClickProfileButton}
          disabled={!profileUser?.accountName}
        >
          <IonIcon className={styles.browser} src="assets/profile_setting/icn__browser.svg" />
        </IonButton>
      </IonItem>
      <IonItem className={styles.link} onClick={goToProfileDetail} lines="none">
        <IonIcon slot="start" src="assets/profile_setting/icn__profile.svg" />
        プロフィール情報
        <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
      </IonItem>
      <IonItem className={styles.link} onClick={goToPhoto} lines="none">
        <IonIcon slot="start" src="assets/profile_setting/icn__photo.svg" />
        フォト
        <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
      </IonItem>
      <IonItem className={styles.link} onClick={goToStyle} lines="none">
        <IonIcon slot="start" src="assets/profile_setting/icn__profileText.svg" />
        ページスタイル
        <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
      </IonItem>
    </div>
  );
};
