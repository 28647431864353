import { AxiosError } from 'axios';

import { browserLocalPersistence, signInWithCustomToken } from 'firebase/auth';
import { auth } from 'utils/firebase';

import { ApiClient } from 'api/apiClient';
import { saveAuthInfoToStorage } from 'auth/storage';

import { useAuthInfo } from 'auth';

interface PostSignInRequest {
  email: string;
  password: string;
}

interface PostSignInResponse {
  customToken: string;
  userId: number;
}

export const useSignIn = () => {
  const { updateAuthInfo } = useAuthInfo();

  const postSignIn = async (data: PostSignInRequest): Promise<PostSignInResponse> => {
    const apiClient = new ApiClient('signin', '');
    const res = await apiClient
      .post<PostSignInResponse>(data)
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    const { customToken, userId } = res.data;

    // SDK でログイン
    await auth.setPersistence(browserLocalPersistence);
    const userCredential = await signInWithCustomToken(auth, customToken);

    const authInfo = {
      userId,
    };
    // コンテキストの認証情報を更新
    updateAuthInfo(authInfo, userCredential.user);
    // ログイン状態を維持するために認証情報をストレージに保存する
    saveAuthInfoToStorage(authInfo);
    return res.data;
  };

  return { postSignIn };
};
