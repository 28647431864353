import React, { useRef } from 'react';
import { ProjectList } from 'components/project/ProjectList';
import { ProjectFab } from 'components/project/ProjectFab';
import { IonContent, IonFooter, IonHeader, IonPage, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { TabBar } from 'components/common/TabBar';

import { withLogin } from 'pages/hocs/withLogin';

import { useAtom } from 'jotai';
import { projectListAtom } from 'stores/project';

export const ProjectPage: React.FC = withLogin(() => {
  const [projectListAtomValue, setProjectListAtomValue] = useAtom(projectListAtom);
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  useIonViewWillEnter(() => {
    if (projectListAtomValue.isScrollToTop && contentRef.current) {
      // 案件登録された場合、登録された案件を表示するために一番上にスクロールする
      contentRef.current.scrollToTop();
      setProjectListAtomValue((prevState) => ({ ...prevState, isScrollToTop: false }));
    }
  }, [projectListAtomValue.isScrollToTop]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonTitle>案件リスト</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent overflow-scroll="true" ref={contentRef}>
        <ProjectList />
      </IonContent>
      <ProjectFab />
      <IonFooter>
        <TabBar selectedTab="project" />
      </IonFooter>
    </IonPage>
  );
});
