import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useHistory, useLocation, useParams } from 'react-router';
import { useIonToast } from '@ionic/react';
import { useAtom } from 'jotai';
import { IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { fireStore } from 'utils/firebase';
import { doc, DocumentReference, getDoc } from '@firebase/firestore';

import styles from './index.module.scss';
import { ProjectMessageFab } from 'components/project/ProjectMessageFab';
import { ProjectDetail } from 'components/project/ProjectDetail';
import { withLogin } from 'pages/hocs/withLogin';
import { useGetProject } from 'api/hooks/project/useGetProject';
import { Project, Chat } from 'models';

import { projectDetailAtom } from 'stores/project';
import { BackButton } from 'components/common/buttons/BackButton';

export const ProjectDetailPage: React.FC = withLogin(() => {
  const [presentToast] = useIonToast();
  const [item, setItem] = useState<Project>({} as Project);
  const [unread, setUnread] = useState(false);

  const params = useParams<{ projectId: string }>();
  const { getProject } = useGetProject();
  const history = useHistory();
  const [projectDetailAtomValue, setProjectDetailAtomValue] = useAtom(projectDetailAtom);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const fetchChatRoom = async (chatRoomKey: string) => {
    const chatDoc = doc(fireStore, 'chats', chatRoomKey) as DocumentReference<Chat>;
    const chat = await getDoc<Chat>(chatDoc);
    return chat.data();
  };

  const fetchProject = () => {
    getProject(Number(params.projectId))
      .then((project) => {
        setItem(project);
        if (project.client.chatRoomKey) {
          // 未読フラグのためchatRoom情報を取得
          fetchChatRoom(project.client.chatRoomKey).then((chat) => {
            setUnread(chat?.unread || false);
          });
        }
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  useEffect(() => {
    fetchProject();
  }, []);

  // 案件編集画面で保存した場合、再検索する
  useEffect(() => {
    if (projectDetailAtomValue.isNeedRefresh) {
      setProjectDetailAtomValue((prevState) => ({ ...prevState, isNeedRefresh: false }));
      fetchProject();
    }
  }, [projectDetailAtomValue.isNeedRefresh]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref={searchParams.get('returnUrl') || '/project'} />
            <IonTitle>案件詳細</IonTitle>
            <IonButton
              className={styles.editButton}
              onClick={() => history.push(`/project/${params.projectId}/edit${location.search}`)}
            >
              編集
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent overflow-scroll="true">
        <ProjectDetail project={item} />
      </IonContent>
      <ProjectMessageFab chatRoomKey={item.client?.chatRoomKey} unread={unread} />
    </IonPage>
  );
});
