import React, { useState } from 'react';
import {
  IonButton,
  IonInput,
  useIonAlert,
  useIonLoading,
  IonList,
  IonListHeader,
  IonItem,
  IonLabel,
} from '@ionic/react';
import { AxiosError } from 'axios';

import { useSearchUser, useDeleteUser } from 'api/hooks/admin';
import { User } from 'models/admin';
import { useForm } from 'react-hook-form';

import { WarningMessage } from 'components/common/WarningMessage';

import styles from './UserSearchForm.module.scss';

interface UserSearchFormValues {
  keyword: string;
}

export const UserSearchForm: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { getAdminUsers } = useSearchUser();
  const { deleteUser } = useDeleteUser();

  const [presentAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UserSearchFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  const searchUsers = async (data: UserSearchFormValues): Promise<void> => {
    presentLoading({
      duration: 2000,
    });

    // ユーザー検索API コール
    getAdminUsers(data)
      .then((userList) => {
        setUsers(userList);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentAlert({
          header: '検索エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      })
      .finally(() => {
        dismissLoading();
      });
  };

  const handleDeleteUser = async (id: number, truncate: boolean): Promise<void> => {
    presentLoading({
      duration: 2000,
    });

    // ユーザー削除API コール
    deleteUser(id, truncate)
      .then(() => {
        setUsers(users.filter((user) => user.id !== id));
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentAlert({
          header: truncate ? '削除エラー' : '退会エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      })
      .finally(() => {
        dismissLoading();
      });
  };

  const goToProfile = (user: User) => {
    // プロフィールは別タブで開く
    window.open(
      `${process.env.REACT_APP_MANAGEMENT_PROFILE_HOST}/${user.accountName}`,
      '_blank',
      'noopener noreferrer'
    );
  };

  return (
    <>
      <div className={styles.wrap}>
        <div className="mypage__inputBlock">
          <p className={styles.note}>メールアドレス、アカウント名で検索できます。</p>
          <IonInput
            className="ion-input__commonText"
            type="text"
            maxlength={150}
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('keyword', e.target.value)}
            {...register('keyword', {
              required: {
                value: true,
                message: '未入力です',
              },
            })}
          />
          <div className="attentionBlock">
            {errors.keyword?.message && <WarningMessage message={errors.keyword.message} />}
          </div>
        </div>
        <div className={styles.buttons}>
          <IonButton className={styles.button} onClick={handleSubmit(searchUsers)}>
            検索
          </IonButton>
        </div>
      </div>
      {!!users.length && (
        <IonList>
          <IonListHeader className={styles.searchResultTitle}>
            <IonLabel>検索結果</IonLabel>
          </IonListHeader>
          {users.map((user: User) => (
            <IonItem key={user.id} className={styles.searchResultItem}>
              <IonLabel className={styles.user}>
                {user.email}
                <br />
                {user.accountName}
              </IonLabel>
              <IonButton
                fill="clear"
                className={styles.profileBtn}
                disabled={!user.accountName?.trim()}
                onClick={() => {
                  goToProfile(user);
                }}
              >
                プロフページ
              </IonButton>
              <IonButton
                fill="clear"
                className={styles.withdrawBtn}
                onClick={() => {
                  handleDeleteUser(user.id, false);
                }}
              >
                退会
              </IonButton>
              <IonButton
                fill="clear"
                className={styles.deleteBtn}
                onClick={() => {
                  handleDeleteUser(user.id, true);
                }}
              >
                削除
              </IonButton>
            </IonItem>
          ))}
        </IonList>
      )}
    </>
  );
};
