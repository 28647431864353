import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from '@ionic/react';

import styles from './ClientEditPage.module.scss';
import { BackButton } from 'components/common/buttons/BackButton';

import { useClientChatUpdate } from 'api/hooks/client/useClientChatUpdate';
import { useClientEdit } from 'api/hooks/client/useClientEdit';
import { useClientGet } from 'api/hooks/client/useClientGet';
import { AxiosError } from 'axios';
import { EditForm } from 'components/client/EditForm';
import { useAtom } from 'jotai';
import { Client, ClientFormValues } from 'models';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router';
import { projectEditAtom } from 'stores/project';
import { withLogin } from 'pages/hocs/withLogin';

export const ClientEditPage: FC = withLogin(() => {
  const [hasEmail, setHasEmail] = useState(false);
  const [originalClient, setOriginalClient] = useState<Client>();
  const params = useParams<{ id: string }>();

  const [presentToast] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [, setProjectEditAtomValue] = useAtom(projectEditAtom);
  const history = useHistory();
  const location = useLocation();
  const returnPathname = location.pathname.split('/').slice(0, -3).join('/');
  const returnUrl = `${returnPathname}${location.search}`;

  const { getClientInfo } = useClientGet();
  const { updateClientChat } = useClientChatUpdate();
  const { patchClientInfo } = useClientEdit();

  useEffect(() => {
    getClientInfo(Number(params.id))
      .then((res) => {
        setValue('name', res.name);
        setValue('departmentName', res.departmentName);
        setValue('staff', res.staff);
        setValue('address', res.address);
        setValue('phone', res.phone);
        setValue('email', res.email);
        setHasEmail(res.email ? true : false);
        // 未保存確認する際に元々のデータに対して変更あるかを判断するためにレスポンスデータを保持する
        setOriginalClient(res);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  }, []);

  const execPatchClient = (data: ClientFormValues) => {
    // emailとphoneは空文字の場合バックエンド側のバリデーションに引っかかるため、
    // ここでnullに置き換える処理が必要
    const requestData: ClientFormValues = {
      ...data,
      email: data.email || null,
      phone: data.phone || null,
    };
    patchClientInfo(params.id, requestData)
      .then(() => {
        setProjectEditAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
        history.replace(returnUrl);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  const execUpdateChat = () => {
    updateClientChat(params.id)
      .then(() => {
        presentToast({
          message: 'チャットURLが更新されました',
          duration: 2000,
        });
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  const clientChatUpdate = async () => {
    presentAlert({
      mode: 'ios',
      header: '確認',
      message:
        'URLを更新すると以前のURLからはアクセスできなくなります。\n更新した場合、連絡先メールアドレスにURLが通知されます。',
      buttons: [
        {
          text: '　　キャンセル　　',
        },
        {
          text: '　　更新　　',
          handler: () => {
            execUpdateChat();
          },
        },
      ],
    });
  };

  const clientEdit = async (data: ClientFormValues) => {
    if (hasEmail && !data.email) {
      presentAlert({
        mode: 'ios',
        header: '確認',
        message:
          'メールアドレスを削除してもチャットは残ります。また、次回メールアドレスを登録すると、チャットは新規作成され、今のチャットを引き続き利用することができなくなります。',
        buttons: [
          {
            text: '　編集に戻る　',
          },
          {
            text: '　このまま保存　',
            handler: () => {
              execPatchClient(data);
            },
          },
        ],
      });
      return;
    }
    if (!hasEmail && !data.email) {
      presentAlert({
        mode: 'ios',
        header: '確認',
        message: 'メールアドレスの登録がない場合、チャットURLは発行されません。',
        buttons: [
          {
            text: '　編集に戻る　',
          },
          {
            text: '　このまま保存　',
            handler: () => {
              execPatchClient(data);
            },
          },
        ],
      });
      return;
    }
    execPatchClient(data);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ClientFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  return (
    <>
      <IonPage>
        <IonHeader mode="ios">
          <IonToolbar>
            <IonButtons className="header">
              <BackButton defaultHref={returnUrl} />
              <IonTitle className={styles.title}>クライアント情報の編集</IonTitle>
              <IonButton className={styles.saveButton} onClick={handleSubmit(clientEdit)}>
                保存
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <EditForm
            register={register}
            setValue={setValue}
            errors={errors}
            watch={watch}
            chatUpdate={clientChatUpdate}
            hasEmail={hasEmail}
            originalClient={originalClient}
          />
        </IonContent>
      </IonPage>
    </>
  );
});
