import { DatetimeCustomEvent, IonButton, IonDatetime, IonIcon } from '@ionic/react';
import { FC } from 'react';
import styles from './SelectDateModal.module.scss';
import { closeOutline } from 'ionicons/icons';

interface SelectDateModalProps {
  onChange: (e: DatetimeCustomEvent) => void;
  closeModal: () => void;
  value: string | null;
}

export const SelectDateModal: FC<SelectDateModalProps> = ({ onChange, closeModal, value }) => {
  return (
    <div className={styles.wrap}>
      <IonDatetime
        presentation="date"
        size="cover"
        className={styles.datePicker}
        onIonChange={onChange}
        value={value}
      />
      <IonButton className={styles.confirmButton} onClick={closeModal}>
        確定
      </IonButton>

      <IonButton className={styles.closeButton} onClick={closeModal}>
        <IonIcon icon={closeOutline} className={styles.closeIcon} />
      </IonButton>
    </div>
  );
};
