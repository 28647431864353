import {
  collection,
  doc,
  query,
  CollectionReference,
  DocumentReference,
  orderBy,
  where,
  onSnapshot,
  Unsubscribe,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';

import { fireStore } from 'utils/firebase';
import { Chat, Message } from 'models';
import { useEffect, useState } from 'react';
import { SENDER_CLIENT, SENDER_CREATOR } from 'consts';

export const useGetMessage = (chatRoomKey: string, readerType: typeof SENDER_CREATOR | typeof SENDER_CLIENT) => {
  let unsubscribe: Unsubscribe;
  const [messages, setMessages] = useState<Message[]>([]);

  const getMessage = () => {
    const chatDoc = doc(fireStore, 'chats', chatRoomKey) as DocumentReference<Chat>;
    const messageCollection = collection(fireStore, 'chats', chatDoc.id, 'messages') as CollectionReference<Message>;
    const messageQuery = query(messageCollection, where('deletedAt', '==', null), orderBy('createdAt'));
    unsubscribe = onSnapshot(messageQuery, async (realData) => {
      const realTimeMessages: Message[] = realData.docs.map((doc) => {
        return doc.data();
      });
      setMessages(realTimeMessages);
      // modelがチャットを開いた時点でunreadをfalseに変更
      if (readerType === SENDER_CREATOR) {
        await updateDoc(chatDoc, { unread: false, updatedAt: serverTimestamp() });
      }
    });
  };

  useEffect(() => {
    getMessage();
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return { messages };
};
