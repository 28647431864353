import React from 'react';
import { IonIcon, IonImg, IonItem } from '@ionic/react';

import styles from './UserRegisterComplete.module.scss';

export const UserRegisterComplete: React.FC = () => {
  const goToLogin = () => {
    window.location.replace('/login');
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.logo}>
        <IonImg src={'assets/welcome/logo.png'} alt="COLORFULLY" />
      </div>
      <p className={styles.note}>
        ご登録ありがとうございます🎉
        <br />
        ログイン画面にお進みください。
      </p>
      <div className={styles.links}>
        <IonItem className={styles.link} onClick={goToLogin} lines="none">
          ログイン
          <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
        </IonItem>
      </div>
    </div>
  );
};
