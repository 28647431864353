import React from 'react';
import { useHistory } from 'react-router';
import {
  IonButtons,
  IonContent,
  IonItem,
  IonIcon,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonImg,
} from '@ionic/react';

import { withBasicAuth } from 'pages/hocs/withBasicAuth';

import styles from './AdminTop.module.scss';

export const AdminTopPage: React.FC = withBasicAuth(() => {
  const history = useHistory();

  const goToUserAdmin = () => {
    history.push('/admin/user');
  };

  const goToProfileAdmin = () => {
    history.push('/admin/profile');
  };

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.header}>
        <IonToolbar mode="ios">
          <IonButtons slot="end" className={styles.headerRightButton}>
            <IonImg src={'assets/admin/icn__browser.svg'} />
          </IonButtons>
          <IonTitle>管理者用</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className={styles.links}>
          <IonItem className={styles.link} onClick={goToUserAdmin} lines="none">
            ユーザー管理
            <IonIcon className={styles.browser} slot="end" src="assets/common/arrow1.svg" />
          </IonItem>
          <IonItem className={styles.link} onClick={goToProfileAdmin} lines="none">
            プロフィールコンテンツ管理
            <IonIcon className={styles.browser} slot="end" src="assets/common/arrow1.svg" />
          </IonItem>
        </div>
      </IonContent>
    </IonPage>
  );
});
