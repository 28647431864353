import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';

export const useClientChatUpdate = () => {
  const { currentUser } = useAuthInfo();
  const updateClientChat = async (id: string) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`clients/${id}/chat_room_key`, idToken);
    const res = await apiClient
      .post<{ chatRoomKey: string }>()
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res;
  };

  return { updateClientChat };
};
