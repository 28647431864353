import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Client, ClientFormValues } from 'models';

export const useClientRegister = () => {
  const { currentUser } = useAuthInfo();
  const postClientInfo = async (data: ClientFormValues) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient('clients', idToken);
    const res = await apiClient.post<Client>(data).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res;
  };

  return { postClientInfo };
};
