import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Project } from 'models/project';

export const useGetUnpaidProjects = () => {
  const { currentUser } = useAuthInfo();
  const getUnpaidProjects = async (limit: number, offset: number) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient('sales/unpaids', idToken);
    const res = await apiClient
      .get<Project[]>({ limit: limit, offset: offset })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res.data;
  };

  return { getUnpaidProjects };
};
