import { useContext } from 'react';
import { BasicAuthContext } from './basicAuthContext';

export const useBasicAuthInfo = () => {
  const { authInfo, isAuthChecking, updateAuthInfo } = useContext(BasicAuthContext);

  return {
    authInfo,
    isAuthChecking,
    updateAuthInfo,
  };
};
