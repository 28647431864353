import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';

export const useDeleteProject = () => {
  const { currentUser } = useAuthInfo();
  const deleteProject = async (id: number) => {
    const idToken = await currentUser?.getIdToken();

    const apiClient = new ApiClient(`projects/${id}`, idToken);
    const res = await apiClient.delete().catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { deleteProject };
};
