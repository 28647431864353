import { IonContent, IonHeader, IonFooter, IonPage, IonTitle, IonToolbar, IonButtons, IonIcon } from '@ionic/react';
import { SaleHistories } from 'components/sales/SaleHistories';
import { FC } from 'react';
import { TabBar } from 'components/common/TabBar';
import styles from './index.module.scss';

import { withLogin } from 'pages/hocs/withLogin';
import { Link } from 'react-router-dom';
import { chevronForwardOutline } from 'ionicons/icons';

export const SaleHistoryPage: FC = withLogin(() => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar className="header">
          <IonTitle>売上管理</IonTitle>
          <IonButtons slot="end">
            <Link className={styles.unpaidLink} to={`/sale/unpaid`}>
              <div className={styles.linkContents}>
                未入金
                <IonIcon icon={chevronForwardOutline} />
              </div>
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <SaleHistories />
      </IonContent>
      <IonFooter>
        <TabBar selectedTab="sale" />
      </IonFooter>
    </IonPage>
  );
});
