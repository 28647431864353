import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  IonContent,
  IonButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
  useIonLoading,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router';

import styles from './index.module.scss';
import { withLogin } from 'pages/hocs/withLogin';
import { BackButton } from 'components/common/buttons/BackButton';
import { StyleContent } from 'components/profile_setting/style/StyleContent';

import { useUpdatePageStyle } from 'api/hooks/pageStyle/useUpdatePageStyle';
import { AxiosError } from 'axios';
import { useGetPageStyle } from 'api/hooks/pageStyle/useGetPageStyle';
import { ColorResult } from 'react-color';

import { ProfileStyle } from 'models/profileSetting';

const INIT_FONT_COLOR_VALUE = '#111111';
const INIT_BACK_GROUND_COLOR_VALUE = '#ffffff';

export const ProfileSettingStylePage: React.FC = withLogin(() => {
  const { getPageStyle } = useGetPageStyle();
  const { putPageStyle } = useUpdatePageStyle();
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [fontColor, setFontColor] = useState<string>('');
  const [backGroundColor, setBackGroundColor] = useState<string>('');
  const [fontColorPickerOpen, setFontColorPickerOpen] = useState<boolean>(false);
  const [backGroundColorOpen, setBackGroundColorOpen] = useState<boolean>(false);

  const history = useHistory();
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const unblockRef = useRef(() => {});
  const [originalStyle, setOriginalStyle] = useState<ProfileStyle>();

  const handleFontColorChange = useCallback((color: ColorResult) => {
    setFontColor(color.hex);
  }, []);

  const handleBackGroundColorChange = useCallback((color: ColorResult) => {
    setBackGroundColor(color.hex);
  }, []);

  const handleResetFontColor = () => {
    setFontColor(originalStyle?.textColor || INIT_FONT_COLOR_VALUE);
  };

  const handleResetBackGroundColor = () => {
    setBackGroundColor(originalStyle?.backgroundColor || INIT_BACK_GROUND_COLOR_VALUE);
  };

  const executeGetPageStyle = () => {
    getPageStyle()
      .then((res) => {
        // 未保存確認する際に元々のデータに対して変更あるかを判断するためにレスポンスデータを保持する
        setOriginalStyle(res);

        setFontColor(res?.textColor || INIT_FONT_COLOR_VALUE);
        setBackGroundColor(res?.backgroundColor || INIT_BACK_GROUND_COLOR_VALUE);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        // 404未設定の場合はエラーメッセージを表示しない
        if (e.response?.status === 404) {
          // 初期表示はページスタイルデータがないが、初期値を表示
          setFontColor(INIT_FONT_COLOR_VALUE);
          setBackGroundColor(INIT_BACK_GROUND_COLOR_VALUE);
          return;
        }

        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  const savePageStyle = () => {
    presentLoading({
      duration: 2000,
    });

    putPageStyle({ textColor: fontColor, backgroundColor: backGroundColor })
      .then(() => {
        presentToast({ message: '変更内容が保存されました', duration: 2000 });
        executeGetPageStyle();
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentAlert({
          header: '更新エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      })
      .finally(() => {
        dismissLoading();
      });
  };

  useEffect(() => {
    executeGetPageStyle();
  }, []);

  const showBackConfirmationAlert = (navigate: () => void, cancel: () => void) => {
    presentAlert({
      mode: 'ios',
      header: '確認',
      message: '保存せずに移動すると入力内容が破棄されますが良いですか？',
      buttons: [
        {
          text: '　移動する　',
          handler: navigate,
        },
        {
          text: '　ページスタイル画面に戻る　',
          handler: cancel,
        },
      ],
    });
  };

  // 入力フォーム内容に一つでも変更されたか
  const isValueChanged = (originalStyle: ProfileStyle | undefined) => {
    // 初期値なし、デフォルト値のまま変更してない場合、未保存モーダルを表示しない
    if (
      !originalStyle?.textColor &&
      !originalStyle?.backgroundColor &&
      fontColor === INIT_FONT_COLOR_VALUE &&
      backGroundColor === INIT_BACK_GROUND_COLOR_VALUE
    ) {
      return false;
    }

    return (
      fontColor !== (originalStyle?.textColor || INIT_FONT_COLOR_VALUE) ||
      backGroundColor !== (originalStyle?.backgroundColor || INIT_BACK_GROUND_COLOR_VALUE)
    );
  };

  useEffect(() => {
    // ページスタイル画面場合のみ、登録する
    if (pathname === '/profile_setting/style') {
      // ブラウザバック・戻るボタン での遷移時にアラートを表示する。
      unblockRef.current = history.block((location, action) => {
        // フォームに一つでも値が変更されている かつ ページスタイル画面からTop画面への遷移の時のみアラートを表示する
        if (isValueChanged(originalStyle) && location.pathname === '/profile_setting') {
          showBackConfirmationAlert(
            () => {
              unblockRef.current();
              if (action === 'POP') {
                history.goBack();
              } else if (action === 'REPLACE') {
                history.replace(location.pathname);
              } else {
                history.push(location.pathname);
              }
            },
            () => {
              // react-routerのバグでTopへ戻るなのにREPLACEになってしまうことがある
              // Top画面へpushするよう履歴スタックをやり直す
              if (action === 'REPLACE') {
                history.push(pathname);
              }
            }
          );

          return false;
        }
      });
    }

    return () => {
      unblockRef.current();
    };
  }, [pathname, originalStyle, fontColor, backGroundColor]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/profile_setting" />
            <IonTitle>ページスタイル</IonTitle>
            <IonButton className={styles.saveButton} onClick={savePageStyle}>
              保存
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StyleContent
          fontColor={fontColor}
          backGroundColor={backGroundColor}
          fontColorPickerOpen={fontColorPickerOpen}
          setFontColorPickerOpen={setFontColorPickerOpen}
          backGroundColorOpen={backGroundColorOpen}
          setBackGroundColorOpen={setBackGroundColorOpen}
          handleFontColorChange={handleFontColorChange}
          handleBackGroundColorChange={handleBackGroundColorChange}
          handleResetFontColor={handleResetFontColor}
          handleResetBackGroundColor={handleResetBackGroundColor}
        />
      </IonContent>
    </IonPage>
  );
});
