import { default as axios, AxiosResponse, AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import * as convertKeys from 'convert-keys';
import { REDIRECT_URL } from 'consts';

import { auth } from 'utils/firebase';

/**
 * @interface
 */
interface Error {
  code?: number;
  status?: number | string;
  message?: string;
}

/**
 * エラーオプション
 * @interface
 */
export interface ErrorOptions {
  // エラー処理
  // 差し込み
  onError: (err: Error) => void;
}

/**
 * ページネーション
 * @interface
 */
export interface Pagination {
  count: number;
  current_page: number;
  has_next: boolean;
  has_prev: boolean;
  limit: number;
  total_page: number;
}

export class ApiClient {
  private url: string;
  private idToken?: string;
  private ignoreError: boolean;
  private readonly axiosInstance: AxiosInstance;

  /**
   * API通信共通エラーを処理する
   */
  private handleError = async (e: AxiosError<{ message: string; type: string }>) => {
    if (this.ignoreError) throw e;

    // 401未認証エラーの場合
    if (e.response?.status === 401) {
      await auth.signOut().finally(() => {
        // 今現在のURLを記録してログイン画面へリダイレクトする
        const currentUrl = window.location.href.replace(window.location.origin, '');
        window.sessionStorage.setItem(REDIRECT_URL, currentUrl);
        window.location.href = '/login';
      });
    }

    throw e;
  };

  public constructor(
    path: string,
    idToken?: string,
    baseUrl = process.env.REACT_APP_BACK_END_API_ENDPOINT,
    ignoreError = false
  ) {
    this.url = `${baseUrl}${path}`;
    this.idToken = idToken;
    this.ignoreError = ignoreError;
    this.axiosInstance = axios.create({
      headers: this.idToken
        ? {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.idToken}`,
          }
        : {
            'Content-Type': 'application/json',
          },
    });
  }

  public async get<T>(params: object = {}, option: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.axiosInstance
      .get(this.url, { params: convertKeys.toSnake(params), ...option })
      .then((res) => {
        res.data = convertKeys.toCamel(res.data);
        return res;
      })
      .catch(this.handleError);
  }

  public async post<T>(params?: object, option: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    const translatedParams = params ? convertKeys.toSnake(params) : undefined;
    return this.axiosInstance
      .post(this.url, translatedParams, option)
      .then((res) => {
        res.data = convertKeys.toCamel(res.data);
        return res;
      })
      .catch(this.handleError);
  }

  public async put<T>(params: object, option: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.axiosInstance
      .put(this.url, convertKeys.toSnake(params), option)
      .then((res) => {
        res.data = convertKeys.toCamel(res.data);
        return res;
      })
      .catch(this.handleError);
  }

  public async patch<T>(params: object, option: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return this.axiosInstance
      .patch(this.url, convertKeys.toSnake(params), option)
      .then((res) => {
        res.data = convertKeys.toCamel(res.data);
        return res;
      })
      .catch(this.handleError);
  }

  public async delete<T>(params?: object, option: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    const reuqestBody = params
      ? {
          data: convertKeys.toSnake(params),
        }
      : null;
    return this.axiosInstance
      .delete(this.url, {
        ...option,
        ...reuqestBody,
      })
      .then((res) => {
        res.data = convertKeys.toCamel(res.data);
        return res;
      })
      .catch(this.handleError);
  }
}
