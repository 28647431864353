import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';

interface PatchUserMailRequest {
  currentEmail: string;
  password: string;
  newEmail: string;
}

export const useMailChange = () => {
  const { authInfo, currentUser } = useAuthInfo();
  const patchUserMail = async (data: PatchUserMailRequest) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`users/${authInfo.userId}/email`, idToken);
    const res = await apiClient.patch(data).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { patchUserMail };
};
