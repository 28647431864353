import { IonIcon, IonImg, useIonToast } from '@ionic/react';
import { useGetFirebaseFile } from 'api/hooks/chat/useGetFirebaseFile';
import { IMAGE_TYPES } from 'consts';
import { FC, useEffect, useState } from 'react';

import styles from './ChatAttachmentFiles.module.scss';

interface ChatAttachmentFilesProps {
  contentURL: string;
  fileName: string;
}

export const ChatAttachmentFiles: FC<ChatAttachmentFilesProps> = ({ contentURL, fileName }) => {
  const [presentToast] = useIonToast();
  const { getFileDownload } = useGetFirebaseFile();
  const [fileType, setFileType] = useState<string>('');
  const [fileUrl, setFileUrl] = useState<string>('');
  const [fileBlob, setFileBlob] = useState<Blob>();
  useEffect(() => {
    // ファイル拡張子の取得
    const getFileType = contentURL.split('.').pop() ?? '';
    setFileType(getFileType);

    getFileDownload(contentURL)
      .then(({ fileBlob, fileURL }) => {
        setFileBlob(fileBlob);
        setFileUrl(fileURL);
      })
      .catch((e) => {
        console.error(e);
        presentToast({
          message: '通信エラーが発生しました。しばらくたってからもう一度お試しください。',
          duration: 2000,
        });
      });
  }, []);

  const onClickHandler = (fileName: string) => {
    if (!fileBlob) return;
    const anchorElement = document.createElement('a');
    anchorElement.download = fileName;
    anchorElement.href = URL.createObjectURL(fileBlob);
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
  };

  if (fileType === 'pdf') {
    return (
      <a className={styles.pdfFile} href={fileUrl} target="_blank" rel="noreferrer">
        <IonIcon className={styles.icon} slot="start" src="/assets/chat/icn__picture.svg" />
        <div className={styles.fileName}>{fileName}</div>
      </a>
    );
  }

  if (IMAGE_TYPES.includes(fileType)) {
    return (
      <a className={styles.imageFile} href={fileUrl} target="_blank" rel="noreferrer">
        <IonImg src={fileUrl} slot="start" />
      </a>
    );
  }

  return (
    <a
      className={styles.otherFile}
      href={fileUrl}
      onClick={(e) => {
        e.preventDefault();
        onClickHandler(fileName);
      }}
    >
      <IonIcon className={styles.icon} slot="start" src="/assets/chat/icn__picture.svg" />
      <div className={styles.fileName}>{fileName}</div>
    </a>
  );
};
