import React from 'react';
import { IonButton, IonIcon, IonInput, useIonAlert, useIonLoading } from '@ionic/react';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router';

import { usePasswordSetting } from 'api/hooks/password/usePasswordSetting';
import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { WarningMessage } from 'components/common/WarningMessage';

import styles from './PasswordSettingForm.module.scss';

interface PasswordSettingFormValues {
  password: string;
  passwordConfirm: string;
}

export const PasswordSettingForm: React.FC = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const { patchPasswordSetting } = usePasswordSetting();
  const [presentAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PasswordSettingFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  // 入力中の要素のRef、パスワードToggleボタンクリックした後にフォーカスを元に戻すために利用する
  const [inputtingElement, setInputtingElement] = useState<(EventTarget & HTMLIonInputElement) | null>(null);
  // Blur後に記憶した入力中要素をクリアするか
  // ・他の入力項目にフォーカスまたはToogleボタンをクリックした場合はクリアしない
  // ・それ以外の場合は、クリアする
  const shouldClearInputRef = useRef(true);

  const handleInputBlur = () => {
    // パスワードToggleボタンのクリックであるかを判断するために遅延する
    setTimeout(() => {
      // パスワードToggleボタンがクリックされなかった場合、フォーカスを戻す必要がないため、入力中項目をクリアする
      if (shouldClearInputRef.current) {
        setInputtingElement(null);
      }
      shouldClearInputRef.current = true;
    }, 300);
  };

  const handleClickTogglePassword = () => {
    inputtingElement?.focus();
    shouldClearInputRef.current = false;
    setPasswordShown(!passwordShown);
  };

  const updatePassword = async (data: PasswordSettingFormValues): Promise<void> => {
    presentLoading({
      duration: 2000,
    });

    const searchParams = new URLSearchParams(location.search);
    const reqData = {
      id: searchParams.get('id') || '',
      token: searchParams.get('token') || '',
      password: data.password,
    };

    // パスワード変更API コール
    patchPasswordSetting(reqData)
      .then(() => {
        presentAlert({
          header: '送信完了',
          message: 'パスワード変更が完了しました。',
          buttons: [
            {
              text: 'ログイン画面に移動',
              handler: () => {
                window.location.replace('/login');
              },
            },
          ],
          mode: 'ios',
          backdropDismiss: false,
        });
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentAlert({
          header: '送信エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      })
      .finally(() => {
        dismissLoading();
      });
  };

  return (
    <div className={styles.wrap}>
      <div className="mypage__inputBlock">
        <div className="input__infoSection">パスワード</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            placeholder="半角英数8文字以上"
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('password', e.target.value)}
            clearOnEdit={false}
            type={passwordShown ? 'text' : 'password'}
            maxlength={150}
            {...register('password', {
              required: {
                value: true,
                message: '未入力です',
              },
              pattern: {
                value: /^[a-zA-Z0-9]{8,}$/,
                message: '半角文字で8文字以上の入力が必要です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
          {passwordShown ? (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye-close.svg"
            />
          ) : (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye.svg"
            />
          )}
        </div>
        <div className="attentionBlock">
          {errors.password?.message && <WarningMessage message={errors.password.message} />}
        </div>
      </div>
      <div className="mypage__inputBlock">
        <div className="input__infoSection">パスワード再入力</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            placeholder="半角英数8文字以上"
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('passwordConfirm', e.target.value)}
            clearOnEdit={false}
            type={passwordShown ? 'text' : 'password'}
            maxlength={150}
            {...register('passwordConfirm', {
              validate: (value, formValues) => value === formValues['password'],
              required: {
                value: true,
                message: '未入力です',
              },
              pattern: {
                value: /^[a-zA-Z0-9]{8,}$/,
                message: '半角文字で8文字以上の入力が必要です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
          {passwordShown ? (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye-close.svg"
            />
          ) : (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye.svg"
            />
          )}
        </div>
        <div className="attentionBlock">
          {errors.passwordConfirm?.message && <WarningMessage message={errors.passwordConfirm.message} />}
        </div>
        <div className="attentionBlock">
          {getValues('passwordConfirm') &&
            !errors.password?.message &&
            !errors.passwordConfirm?.message &&
            getValues('password') !== getValues('passwordConfirm') && <WarningMessage message="入力内容が異なります" />}
        </div>
      </div>
      <div className={styles.buttons}>
        <IonButton className={styles.button} onClick={handleSubmit(updatePassword)}>
          送信
        </IonButton>
      </div>
    </div>
  );
};
