import { AxiosError } from 'axios';
import { closeOutline } from 'ionicons/icons';
import { IonButton, IonDatetime, IonIcon, useIonToast } from '@ionic/react';
import { FC, useState } from 'react';

import styles from './SelectPaidDateModal.module.scss';
import { useUpdateProject } from 'api/hooks/project/useUpdateProject';
import { Project, ProjectStatus } from 'models';

import { useAtom } from 'jotai';
import { projectListAtom } from 'stores/project';
import { saleHistoryAtom } from 'stores/sale';

interface SelectPaidDateModalProps {
  project: Project;
  closeModal: () => void;
  refreshProjects: () => void;
}

export const SelectPaidDateModal: FC<SelectPaidDateModalProps> = ({ project, closeModal, refreshProjects }) => {
  const [, setProjectListAtomValue] = useAtom(projectListAtom);
  const [, setSaleHistoryAtomValue] = useAtom(saleHistoryAtom);

  const { patchProject } = useUpdateProject();
  const [selectedDate, setSelectedDate] = useState<string | null>(null);

  const [presentToast] = useIonToast();

  const updatePaidDate = () => {
    if (selectedDate === null) {
      presentToast({
        message: '入金日を選択してください。',
        duration: 2000,
      });
      return;
    }

    const patchProjectParam = {
      name: project.name,
      status: ProjectStatus.Paid,
      endDate: project.endDate,
      clientId: project.client.id,
      paymentDate: selectedDate,
      contractPrice: project.contractPrice,
    };
    patchProject(project.id, patchProjectParam)
      .then(() => {
        presentToast({
          message: '入金確認が完了しました',
          duration: 2000,
        });

        // 案件詳細画面と案件一覧画面をリフレッシュする
        setProjectListAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
        // 売上管理画面のデータをリフレッシュさせる
        setSaleHistoryAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));

        refreshProjects();
        closeModal();
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  return (
    <div className={styles.wrap}>
      <p className={styles.explanation}>入金された日を選択してください。</p>
      <IonDatetime
        presentation="date"
        size="cover"
        className={styles.datePicker}
        // IonDatetimeで選択された日付は "2023-04-20T01:30:00+09:00" の形式
        onIonChange={(e) => setSelectedDate((e.detail.value as string).substring(0, 10))}
      />
      <IonButton className={styles.confirmButton} onClick={updatePaidDate}>
        確定
      </IonButton>

      <IonButton className={styles.closeButton} onClick={closeModal}>
        <IonIcon icon={closeOutline} className={styles.closeIcon} />
      </IonButton>
    </div>
  );
};
