import React from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { WithdrawalForm } from 'components/setting/WithdrawalForm';
import { BackButton } from 'components/common/buttons/BackButton';

import { withLogin } from 'pages/hocs/withLogin';

export const SettingWithdrawalPage: React.FC = withLogin(() => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/setting" />
          </IonButtons>
          <IonTitle>退会</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <WithdrawalForm />
      </IonContent>
    </IonPage>
  );
});
