import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Project } from 'models';

export const useGetProject = () => {
  const { currentUser } = useAuthInfo();
  const getProject = async (id: number) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`projects/${id}`, idToken);
    const res = await apiClient.get<Project>().catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { getProject };
};
