import { IonImg } from '@ionic/react';
import { FC } from 'react';
import Modal from 'react-modal';

import styles from './PhotoQuestionModal.module.scss';

interface IPhotoQuestionModalProps {
  isOpenModal: boolean;
  closeModal: () => void;
}

const modalStyles = {
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    backgroundColor: 'transparent',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '0',
  },
  overlay: {
    backgroundColor: 'rgba(0 ,0 ,0 ,0.8)',
  },
};

export const PhotoQuestionModal: FC<IPhotoQuestionModalProps> = ({ isOpenModal, closeModal }) => {
  return (
    <Modal isOpen={isOpenModal} onRequestClose={closeModal} style={modalStyles}>
      <div className={styles.modalCardWrap}>
        <div className={styles.modalCard}>
          <IonImg src={'assets/profile_setting/icn__manager.svg'} alt="manager" />
          <p className={styles.modalTypography}>フォトについて</p>
        </div>
        <ul className={styles.modalDescription}>
          <li>写真を選んだ後、青い矢印アイコンタップでアップロードされます</li>
          <li>アップロードできるのは静止画のみです。動画はアップロードできません</li>
        </ul>
      </div>
    </Modal>
  );
};
