import { atom } from 'jotai';

export type ProjectFormValuesAtom = {
  id: number | null;
  name: string | null;
  status: number | null;
  startDate: string | null;
  endDate: string | null;
  clientId: number | null;
  description: string | null;
  contractPrice: number | null;
  imageFile: FileList | null;
  imageFileSrc?: string | null;
  contractFile: FileList | null;
  contractFileSrc?: string | null;
  contractFileName?: string | null;
  paymentDueDate: string | null;
  paymentDate: string | null;
};

type ProjectRegister = {
  isNeedRefresh: boolean;
};
// プロジェクト登録画面ストアのデフォルト値
export const DEFAULT_PROJECT_REGISTER_ATOM = {
  isNeedRefresh: false,
};
// プロジェクト登録画面ストア
export const projectRegisterAtom = atom<ProjectRegister>(DEFAULT_PROJECT_REGISTER_ATOM);

type ProjectEdit = {
  isNeedRefresh: boolean;
};
// プロジェクト編集画面ストアのデフォルト値
export const DEFAULT_PROJECT_EDIT_ATOM = {
  isNeedRefresh: false,
};
// プロジェクト編集画面ストア
export const projectEditAtom = atom<ProjectEdit>(DEFAULT_PROJECT_EDIT_ATOM);

type ProjectDetail = {
  isNeedRefresh: boolean;
};
// プロジェクト詳細画面ストアのデフォルト値
export const DEFAULT_PROJECT_DETAIL_ATOM = {
  isNeedRefresh: false,
};
// プロジェクト一覧画面ストア
export const projectDetailAtom = atom<ProjectDetail>(DEFAULT_PROJECT_DETAIL_ATOM);

type ProjectList = {
  isNeedRefresh: boolean;
  isScrollToTop: boolean;
};
// プロジェクト一覧画面ストアのデフォルト値
export const DEFAULT_PROJECT_LIST_ATOM = {
  isNeedRefresh: false,
  isScrollToTop: false,
};
// プロジェクト一覧画面ストア
export const projectListAtom = atom<ProjectList>(DEFAULT_PROJECT_LIST_ATOM);
