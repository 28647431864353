import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';
import { NotifyEmail } from 'models';

interface PostNotifyEmailRequest {
  subject: string;
  text: string;
  to: string;
  token: string;
  chatRoomKey: string;
}

type PostNotifyEmailResponse = NotifyEmail;

export const usePostNotifyEmail = () => {
  const { currentUser } = useAuthInfo();

  const postNotifyEmail = async (request: PostNotifyEmailRequest) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient('notifyMail', idToken, process.env.REACT_APP_FIREBASE_FUNCTIONS_ENDPOINT);
    const res = await apiClient
      .post<PostNotifyEmailResponse>(request)
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res.data;
  };

  return { postNotifyEmail };
};
