import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from '@ionic/react';

import { FC, useEffect, useState, useRef } from 'react';

import styles from './index.module.scss';

import { BackButton } from 'components/common/buttons/BackButton';
import { ProfileForm } from 'components/profile_setting/ProfileForm';

import { withLogin } from 'pages/hocs/withLogin';
import { useForm } from 'react-hook-form';
import { ProfileFormValues, ProfileDetail } from 'models/profileSetting';
import { useHistory, useLocation } from 'react-router';

import { AxiosError } from 'axios';
import { useGetProfile } from 'api/hooks/profile/useGetProfile';
import { useUpdateProfile } from 'api/hooks/profile/useUpdateProfile';
import { ImageFileType, useUploadFile } from 'api/hooks/upload/useUploadFile';

// undefeindや空白や未設定を区別しない
const isDifferentString = (value1: string | null | undefined, value2: string | null | undefined) => {
  return (value1 || '') !== (value2 || '');
};

// nullや0や未設定を区別しない
const isDifferentNum = (value1: number | null | undefined, value2: number | null | undefined) => {
  return (value1 || null) !== (value2 || null);
};

export const ProfileSettingDetailPage: FC = withLogin(() => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<ProfileFormValues>({ mode: 'all' });

  const { uploadFile } = useUploadFile();
  const { putProfile } = useUpdateProfile();
  const { getProfile } = useGetProfile();

  const history = useHistory();
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const unblockRef = useRef(() => {});

  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();

  const [imageFileUrl, setImageFileUrl] = useState<string | null>(null);
  const [croppedImageFileBlob, setCroppedImageFileBlob] = useState<Blob | null>(null);
  const [isImageFileDeleted, setIsImageFileDeleted] = useState(false);

  const [originalProfile, setOriginalProfile] = useState<ProfileDetail>();
  const [fetched, setFetched] = useState(false);

  const executeGetProfile = () => {
    getProfile()
      .then((profile) => {
        // 未保存確認する際に元々のデータに対して変更あるかを判断するためにレスポンスデータを保持する
        setOriginalProfile(profile);
        // 入力Formの各項目に設定する
        setImageFileUrl(profile.profileMainImageUrl);
        setValue('accountName', profile.accountName);
        setValue('businessName', profile.businessName);
        setValue('businessNameFurigana', profile.businessNameFurigana);
        setValue('height', profile.height);
        setValue('weight', profile.weight);
        setValue('footSize', profile.footSize);
        setValue('bustSize', profile.bustSize);
        setValue('waistSize', profile.waistSize);
        setValue('hipSize', profile.hipSize);
        setValue('selfIntroduction', profile.selfIntroduction);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        // 404未設定の場合はエラーメッセージを表示しない
        if (e.response?.status === 404) return;
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      })
      .finally(() => {
        setFetched(true);
      });
  };

  const saveProfile = async (data: ProfileFormValues): Promise<void> => {
    presentLoading({
      duration: 2000,
    });

    // 画像アップロードAPI コール
    let imageFileKey: string | null = null;
    if (data.imageFile?.length && croppedImageFileBlob) {
      // トリミング時にサムネイル画像は必ずpng形式に変換される
      imageFileKey = await uploadFile(ImageFileType.ProfileMain, data.imageFile[0], croppedImageFileBlob).catch(
        (e: AxiosError<{ message: string; type: string }>) => {
          dismissLoading();
          presentAlert({
            header: '更新エラー',
            message: e.response?.data?.message,
            buttons: [
              {
                text: 'OK',
              },
            ],
            mode: 'ios',
          });
          return Promise.reject(e);
        }
      );
    }

    // プロフィール更新APIコール
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    let putProfileParams = {
      accountName: data.accountName,
      businessName: data.businessName!,
      businessNameFurigana: data.businessNameFurigana!,
      height: data.height || null,
      weight: data.weight || null,
      footSize: data.footSize || null,
      bustSize: data.bustSize || null,
      waistSize: data.waistSize || null,
      hipSize: data.hipSize || null,
      selfIntroduction: data.selfIntroduction,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    if (imageFileKey !== null) {
      // イメージファイル更新ありの場合
      putProfileParams = { ...putProfileParams, ...{ profileMainImageKey: imageFileKey } };
    } else if (imageFileUrl && isImageFileDeleted) {
      // イメージファイルが削除された場合
      putProfileParams = { ...putProfileParams, ...{ profileMainImageKey: null } };
    }

    putProfile(putProfileParams)
      .then(() => {
        presentToast({ message: '変更内容が保存されました', duration: 2000 });
        // 未保存確認を邪魔しないように再検索する
        executeGetProfile();
        // 未保存確認を邪魔しないように初期値の画像ファイル選択なしに取り戻す
        setValue('imageFile', null);
        // 未保存確認を邪魔しないように削除済フラグをfalseに取り戻す
        setIsImageFileDeleted(false);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentAlert({
          header: '更新エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      })
      .finally(() => {
        dismissLoading();
      });
  };

  useEffect(() => {
    executeGetProfile();
  }, []);

  const showBackConfirmationAlert = (navigate: () => void, cancel: () => void) => {
    presentAlert({
      mode: 'ios',
      header: '確認',
      message: '保存せずに移動すると入力内容が破棄されますが良いですか？',
      buttons: [
        {
          text: '　移動する　',
          handler: navigate,
        },
        {
          text: '　プロフィール情報画面に戻る　',
          handler: cancel,
        },
      ],
    });
  };

  // ブラウザのタブを閉じた際にダイアログを表示する。
  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeunload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, []);

  // 入力フォーム内容に一つでも変更されたか
  const isValueChanged = (originalProfile: ProfileDetail | undefined) => {
    // 次のいずれの場合は画像変更ありとする
    //  1.画像ファイル選択あり、既存画像なし
    //  2.画像ファイル選択あり、既存画像あり
    //  3.画像ファイル選択なし、既存画像あるが削除した
    const isImageChanged =
      (watch('imageFile')?.length && croppedImageFileBlob) ||
      (originalProfile?.profileMainImageUrl && isImageFileDeleted);

    return (
      isImageChanged ||
      isDifferentString(watch('accountName'), originalProfile?.accountName) ||
      isDifferentString(watch('businessName'), originalProfile?.businessName) ||
      isDifferentString(watch('businessNameFurigana'), originalProfile?.businessNameFurigana) ||
      isDifferentNum(watch('height'), originalProfile?.height) ||
      isDifferentNum(watch('weight'), originalProfile?.weight) ||
      isDifferentNum(watch('footSize'), originalProfile?.footSize) ||
      isDifferentNum(watch('bustSize'), originalProfile?.bustSize) ||
      isDifferentNum(watch('waistSize'), originalProfile?.waistSize) ||
      isDifferentNum(watch('hipSize'), originalProfile?.hipSize) ||
      isDifferentString(watch('selfIntroduction'), originalProfile?.selfIntroduction)
    );
  };

  useEffect(() => {
    // プロフィール情報画面場合のみ、登録する
    if (pathname === '/profile_setting/detail') {
      // ブラウザバック・戻るボタン での遷移時にアラートを表示する。
      unblockRef.current = history.block((location, action) => {
        // フォームに一つでも値が変更されている かつ プロフィール情報画面からTop画面への遷移の時のみアラートを表示する
        if (isValueChanged(originalProfile) && location.pathname === '/profile_setting') {
          showBackConfirmationAlert(
            () => {
              unblockRef.current();
              if (action === 'POP') {
                history.goBack();
              } else if (action === 'REPLACE') {
                history.replace(location.pathname);
              } else {
                history.push(location.pathname);
              }
            },
            () => {
              // react-routerのバグでTopへ戻るなのにREPLACEになってしまうことがある
              // Top画面へpushするよう履歴スタックをやり直す
              if (action === 'REPLACE') {
                history.push(pathname);
              }
            }
          );

          return false;
        }
      });
    }

    return () => {
      unblockRef.current();
    };
  }, [pathname, originalProfile, isImageFileDeleted]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/profile_setting" />
            <IonTitle>プロフィール情報</IonTitle>
            <IonButton className={styles.saveButton} onClick={handleSubmit(saveProfile)}>
              保存
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ProfileForm
          setCroppedImageFileBlob={setCroppedImageFileBlob}
          onImageFileDeleted={() => {
            setIsImageFileDeleted(true);
          }}
          register={register}
          setValue={setValue}
          control={control}
          errors={errors}
          imageFileUrl={imageFileUrl}
          fetched={fetched}
        />
      </IonContent>
    </IonPage>
  );
});
