import React from 'react';
import { IonButton, IonCheckbox, IonIcon, IonInput, IonItem, IonLabel, useIonAlert, useIonLoading } from '@ionic/react';
import { AxiosError } from 'axios';

import { useRegisterUser } from 'api/hooks/user/useRegisterUser';
import { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

import { WarningMessage } from 'components/common/WarningMessage';

import { TERM_URL } from 'consts/app';

import styles from './UserRegisterForm.module.scss';

interface UserRegisterFormValues {
  email: string;
  password: string;
  name: string;
}

export const UserRegisterForm: React.FC = () => {
  const [checkedAgreeterms, setCheckedAgreeterms] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [registerError, setRegisterError] = useState(false);

  const { postUser } = useRegisterUser();
  const history = useHistory();
  const [presentAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UserRegisterFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  // 入力中の要素のRef、パスワードToggleボタンクリックした後にフォーカスを元に戻すために利用する
  const [inputtingElement, setInputtingElement] = useState<(EventTarget & HTMLIonInputElement) | null>(null);
  // Blur後に記憶した入力中要素をクリアするか
  // ・他の入力項目にフォーカスまたはToogleボタンをクリックした場合はクリアしない
  // ・それ以外の場合は、クリアする
  const shouldClearInputRef = useRef(true);

  const handleInputBlur = () => {
    // パスワードToggleボタンのクリックであるかを判断するために遅延する
    setTimeout(() => {
      // パスワードToggleボタンがクリックされなかった場合、フォーカスを戻す必要がないため、入力中項目をクリアする
      if (shouldClearInputRef.current) {
        setInputtingElement(null);
      }
      shouldClearInputRef.current = true;
    }, 300);
  };

  const handleClickTogglePassword = () => {
    inputtingElement?.focus();
    shouldClearInputRef.current = false;
    setPasswordShown(!passwordShown);
  };

  const signUp = async (data: UserRegisterFormValues): Promise<void> => {
    if (!checkedAgreeterms) {
      presentAlert({
        header: '確認',
        message: '利用規約を確認してください',
        buttons: [
          {
            text: 'OK',
          },
        ],
        mode: 'ios',
      });
      return;
    }

    setRegisterError(false);

    presentLoading({
      duration: 2000,
    });

    // ユーザ登録API コール
    postUser(data)
      .then(() => {
        presentAlert({
          header: '送信完了',
          message:
            'ご登録のメールアドレス宛に、認証のためのメールを送信しました。<br />24時間以内にリンクをクリックして認証を終えてください。',
          buttons: [
            {
              text: 'ログイン画面に移動',
              handler: () => {
                history.push('/login');
              },
            },
          ],
          mode: 'ios',
          backdropDismiss: false,
        });
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        if (e.response?.status === 400) {
          setRegisterError(true);
        } else {
          presentAlert({
            header: '登録エラー',
            message: e.response?.data?.message,
            buttons: [
              {
                text: 'OK',
              },
            ],
            mode: 'ios',
          });
        }
      })
      .finally(() => {
        dismissLoading();
      });
  };

  const goToTerm = () => {
    window.open(TERM_URL, '_blank', 'noopener noreferrer');
  };

  return (
    <div className={styles.wrap}>
      {/* メールアドレス */}
      <div className="mypage__inputBlock">
        <div className="input__infoSection">メールアドレス</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="example@colorfully.jp"
          type="email"
          maxlength={150}
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('email', e.target.value)}
          {...register('email', {
            required: {
              value: true,
              message: '未入力です',
            },
            pattern: {
              value: /[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z.-_]{2,}/,
              message: '正しいメールアドレスを入力してください',
            },
          })}
          onFocus={(e) => {
            setInputtingElement(e.target);
            shouldClearInputRef.current = false;
          }}
          onBlur={handleInputBlur}
        />
        <div className="attentionBlock">
          {errors.email?.message && <WarningMessage message={errors.email.message} />}
        </div>
        <div className="attentionBlock">
          {!errors.email?.message && registerError && (
            <WarningMessage message="登録済みか、その他の理由で登録できないメールアドレスです" />
          )}
        </div>
      </div>
      {/* パスワード */}
      <div className="mypage__inputBlock">
        <div className="input__infoSection">パスワード</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            placeholder="半角英数8文字以上"
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('password', e.target.value)}
            clearOnEdit={false}
            type={passwordShown ? 'text' : 'password'}
            maxlength={150}
            {...register('password', {
              required: {
                value: true,
                message: '未入力です',
              },
              pattern: {
                value: /^[a-zA-Z0-9]{8,}$/,
                message: '半角文字で8文字以上の入力が必要です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
          {passwordShown ? (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye-close.svg"
            />
          ) : (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye.svg"
            />
          )}
        </div>
        <div className="attentionBlock">
          {errors.password?.message && <WarningMessage message={errors.password.message} />}
        </div>
      </div>
      {/* 活動名 */}
      <div className="mypage__inputBlock">
        <div className="input__infoSection">活動名</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('name', e.target.value)}
            clearOnEdit={false}
            type="text"
            maxlength={150}
            {...register('name', {
              required: {
                value: true,
                message: '未入力です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
        </div>
        <div className="attentionBlock">{errors.name?.message && <WarningMessage message={errors.name.message} />}</div>
      </div>
      {/* 利用規約 */}
      <IonItem className={styles.terms} lines="none" detail={false} detail-icon={false}>
        <div className={styles.center} slot="start">
          <IonCheckbox
            className={styles.checkbox}
            mode="ios"
            checked={checkedAgreeterms}
            onIonChange={(e) => setCheckedAgreeterms(e.detail.checked)}
          />
          <IonLabel className={styles.label}>利用規約に同意する</IonLabel>
        </div>
      </IonItem>
      {/* 登録ボタン */}
      <div className={styles.buttons}>
        <IonButton className={styles.button} onClick={handleSubmit(signUp)} disabled={!checkedAgreeterms}>
          登録
        </IonButton>
      </div>
      {/* その他リンク等 */}
      <div className={styles.links}>
        <IonItem className={styles.link} onClick={goToTerm} lines="none">
          利用規約
          <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
        </IonItem>
      </div>
    </div>
  );
};
