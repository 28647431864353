import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useAuthInfo } from 'auth';

export const withoutLogin = (WrappedComponent: React.FC) => {
  const WithoutLogin = () => {
    const { authInfo, currentUser, isAuthChecking } = useAuthInfo();
    const history = useHistory();

    useEffect(() => {
      if (isAuthChecking) return;

      // 既にログイン済ならトップページへ
      if (authInfo.userId && currentUser) {
        history.replace('/project');
      }
    }, [authInfo, currentUser, isAuthChecking]);

    return isAuthChecking || (authInfo.userId && currentUser) ? null : <WrappedComponent />;
  };

  return WithoutLogin;
};
