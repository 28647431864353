import React from 'react';
import { IonButton, IonIcon, IonInput, useIonAlert, useIonLoading } from '@ionic/react';
import { AxiosError } from 'axios';
import classNames from 'classnames';

import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { usePasswordChange } from 'api/hooks/setting/usePasswordChange';
import { auth } from 'utils/firebase';
import { isIos } from 'utils/device';

import { WarningMessage } from 'components/common/WarningMessage';

import styles from './PasswordChangeForm.module.scss';

interface PasswordChangeFormValues {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export const PasswordChangeForm: React.FC = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [isCurrentPasswordError, setIsCurrentPasswordError] = useState(false);
  const [isNewPasswordConfirmFocused, setIsNewPasswordConfirmFocused] = useState(false);

  const { patchUserPassword } = usePasswordChange();
  const [presentAlert] = useIonAlert();
  const [presentLoading, dismissLoading] = useIonLoading();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<PasswordChangeFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  // 入力中の要素のRef、パスワードToggleボタンクリックした後にフォーカスを元に戻すために利用する
  const [inputtingElement, setInputtingElement] = useState<(EventTarget & HTMLIonInputElement) | null>(null);
  // Blur後に記憶した入力中要素をクリアするか
  // ・他の入力項目にフォーカスまたはToogleボタンをクリックした場合はクリアしない
  // ・それ以外の場合は、クリアする
  const shouldClearInputRef = useRef(true);

  const handleInputBlur = () => {
    // パスワードToggleボタンのクリックであるかを判断するために遅延する
    setTimeout(() => {
      // パスワードToggleボタンがクリックされなかった場合、フォーカスを戻す必要がないため、入力中項目をクリアする
      if (shouldClearInputRef.current) {
        setInputtingElement(null);
      }
      shouldClearInputRef.current = true;
    }, 300);
  };

  const handleClickTogglePassword = () => {
    inputtingElement?.focus();
    shouldClearInputRef.current = false;
    setPasswordShown(!passwordShown);
  };

  const updatePassword = async (data: PasswordChangeFormValues): Promise<void> => {
    presentLoading({
      duration: 2000,
    });
    setIsCurrentPasswordError(false);

    const reqData = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
    };

    // パスワード変更API コール
    patchUserPassword(reqData)
      .then(() => {
        presentAlert({
          header: '変更完了',
          message: 'パスワード変更が完了しました。',
          buttons: [
            {
              text: 'ログイン画面に移動',
              handler: () => {
                auth.signOut().finally(() => {
                  window.location.replace('/login');
                });
              },
            },
          ],
          mode: 'ios',
          backdropDismiss: false,
        });
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        if (e.response?.status === 400) {
          setIsCurrentPasswordError(true);
        } else {
          presentAlert({
            header: '変更エラー',
            message: e.response?.data?.message,
            buttons: [
              {
                text: 'OK',
              },
            ],
            mode: 'ios',
          });
        }
      })
      .finally(() => {
        dismissLoading();
      });
  };

  const focusedCls = isIos() && isNewPasswordConfirmFocused ? styles.focused : '';

  return (
    <div className={classNames(styles.wrap, focusedCls)}>
      <div className="mypage__inputBlock">
        <div className="input__infoSection">現在のパスワード</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            placeholder="半角英数8文字以上"
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('currentPassword', e.target.value)}
            clearOnEdit={false}
            type={passwordShown ? 'text' : 'password'}
            maxlength={150}
            {...register('currentPassword', {
              required: {
                value: true,
                message: '未入力です',
              },
              pattern: {
                value: /^[a-zA-Z0-9]{8,}$/,
                message: '半角文字で8文字以上の入力が必要です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
          {passwordShown ? (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye-close.svg"
            />
          ) : (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye.svg"
            />
          )}
        </div>
        <div className="attentionBlock">
          {errors.currentPassword?.message && <WarningMessage message={errors.currentPassword.message} />}
        </div>
        <div className="attentionBlock">
          {!errors.currentPassword?.message && isCurrentPasswordError && (
            <WarningMessage message="現在のパスワードが不正です" />
          )}
        </div>
      </div>
      <div className="mypage__inputBlock">
        <div className="input__infoSection">新しいパスワード</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            placeholder="半角英数8文字以上"
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('newPassword', e.target.value)}
            clearOnEdit={false}
            type={passwordShown ? 'text' : 'password'}
            maxlength={150}
            {...register('newPassword', {
              required: {
                value: true,
                message: '未入力です',
              },
              pattern: {
                value: /^[a-zA-Z0-9]{8,}$/,
                message: '半角文字で8文字以上の入力が必要です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
          {passwordShown ? (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye-close.svg"
            />
          ) : (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye.svg"
            />
          )}
        </div>
        <div className="attentionBlock">
          {errors.newPassword?.message && <WarningMessage message={errors.newPassword.message} />}
        </div>
      </div>
      <div className="mypage__inputBlock">
        <div className="input__infoSection">新しいパスワード再入力</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            placeholder="半角英数8文字以上"
            onIonFocus={() => {
              setIsNewPasswordConfirmFocused(true);
            }}
            onIonBlur={() => {
              setIsNewPasswordConfirmFocused(false);
            }}
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('newPasswordConfirm', e.target.value)}
            clearOnEdit={false}
            type={passwordShown ? 'text' : 'password'}
            maxlength={150}
            {...register('newPasswordConfirm', {
              validate: (value, formValues) => value === formValues['newPassword'],
              required: {
                value: true,
                message: '未入力です',
              },
              pattern: {
                value: /^[a-zA-Z0-9]{8,}$/,
                message: '半角文字で8文字以上の入力が必要です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
          {passwordShown ? (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye-close.svg"
            />
          ) : (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye.svg"
            />
          )}
        </div>
        <div className="attentionBlock">
          {errors.newPasswordConfirm?.message && <WarningMessage message={errors.newPasswordConfirm.message} />}
        </div>
        <div className="attentionBlock">
          {getValues('newPasswordConfirm') &&
            !errors.newPassword?.message &&
            !errors.newPasswordConfirm?.message &&
            getValues('newPassword') !== getValues('newPasswordConfirm') && (
              <WarningMessage message="入力内容が異なります" />
            )}
        </div>
      </div>
      <div className={styles.buttons}>
        <IonButton className={styles.button} onClick={handleSubmit(updatePassword)}>
          変更
        </IonButton>
      </div>
    </div>
  );
};
