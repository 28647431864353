import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from '@ionic/react';
import { FC, useEffect, useRef, useState } from 'react';

import { withLogin } from 'pages/hocs/withLogin';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { useUploadFile } from 'api/hooks/upload/useUploadFile';
import { ImageFileType } from 'api/hooks/upload/useUploadFile';
import { useRegisterProject } from 'api/hooks/project/useRegisterProject';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { projectListAtom } from 'stores/project';
import { ProjectFormValues } from 'models';
import { ProjectForm } from 'components/project/ProjectForm';

import './index.css';
import styles from './index.module.scss';
import { BackButton } from 'components/common/buttons/BackButton';

export const ProjectRegisterPage: FC = withLogin(() => {
  const [croppedImageFileBlob, setCroppedImageFileBlob] = useState<Blob | null>(null);
  const [, setProjectListAtomValue] = useAtom(projectListAtom);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<ProjectFormValues>({ mode: 'all' });

  const { uploadFile } = useUploadFile();
  const { postProject } = useRegisterProject();

  const history = useHistory();
  const { pathname } = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const unblockRef = useRef(() => {});

  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();

  const registerProject = async (data: ProjectFormValues): Promise<void> => {
    presentLoading({
      duration: 2000,
    });

    // 画像アップロードAPI コール
    let imageFileKey: string | null = null;
    let contractFileKey: string | null = null;
    await Promise.all([
      // トリミング時にサムネイル画像は必ずpng形式に変換される
      data.imageFile?.length ? uploadFile(ImageFileType.Thumbnail, data.imageFile[0], croppedImageFileBlob) : null,
      data.contractFile?.length ? uploadFile(ImageFileType.ContractFile, data.contractFile[0]) : null,
    ])
      .then((res) => {
        imageFileKey = res[0];
        contractFileKey = res[1];
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        dismissLoading();
        presentAlert({
          header: '登録エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
        return Promise.reject(e);
      });

    // 案件登録APIコール
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    postProject({
      name: data.name!,
      status: data.status!,
      startDate: data.startDate ? dayjs(data.startDate).format('YYYY-MM-DD') : null,
      endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
      clientId: data.clientId!,
      description: data.description,
      contractPrice: data.contractPrice!,
      thumbnailFileKey: imageFileKey,
      contractFileKey: contractFileKey,
      paymentDueDate: dayjs(data.paymentDueDate).format('YYYY-MM-DD'),
      paymentDate: null,
    })
      .then((project) => {
        presentToast({ message: '新規登録が完了しました', duration: 2000 });
        setProjectListAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true, isScrollToTop: true }));

        // 未保存確認チェック処理を解除
        unblockRef.current();
        // 詳細画面へ遷移する
        return history.replace(`/project/${project.id}`);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentAlert({
          header: '登録エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      })
      .finally(() => {
        dismissLoading();
      });
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  };

  const showBackConfirmationAlert = (navigate: () => void, cancel: () => void) => {
    presentAlert({
      mode: 'ios',
      header: '確認',
      message: '保存せずに移動すると入力内容が破棄されますが良いですか？',
      buttons: [
        {
          text: '　移動する　',
          handler: navigate,
        },
        {
          text: '　編集画面に戻る　',
          handler: cancel,
        },
      ],
    });
  };

  // ブラウザのタブを閉じた際にダイアログを表示する。
  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeunload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, []);

  // フォームに一つでも値が入力されているか
  const isValueInputted = () => {
    return (
      watch('startDate') ||
      watch('endDate') ||
      watch('status') ||
      watch('name') ||
      watch('imageFile')?.length !== 0 ||
      watch('clientId') ||
      watch('contractPrice') ||
      watch('description') ||
      watch('contractFile')?.length !== 0
    );
  };

  useEffect(() => {
    // 案件登録画面場合のみ、登録する
    if (pathname === '/project/register') {
      // ブラウザバック・戻るボタン での遷移時にアラートを表示する。
      unblockRef.current = history.block((location, action) => {
        // フォームに一つでも値が入力されている かつ 案件登録画面から案件一覧画面への遷移の時のみアラートを表示する
        if (isValueInputted() && location.pathname === '/project') {
          showBackConfirmationAlert(
            () => {
              unblockRef.current();
              if (action === 'POP') {
                history.goBack();
              } else if (action === 'REPLACE') {
                history.replace(location.pathname);
              } else {
                history.push(location.pathname);
              }
            },
            () => {
              // react-routerのバグで一覧へ戻るなのにREPLACEになってしまうことがある
              // 案件登録画面へpushするよう履歴スタックをやり直す
              if (action === 'REPLACE') {
                history.push('/project/register');
              }
            }
          );

          return false;
        }
      });
    }

    return () => {
      unblockRef.current();
    };
  }, [pathname]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButtons className="header">
            <BackButton defaultHref="/project" />
            <IonTitle className={styles.title}>新規案件の登録</IonTitle>
            <IonButton className={styles.saveButton} onClick={handleSubmit(registerProject)}>
              保存
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ProjectForm
          setCroppedImageFileBlob={setCroppedImageFileBlob}
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
          errors={errors}
        />
      </IonContent>
    </IonPage>
  );
});
