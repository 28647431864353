import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import Cropper, { Area, MediaSize } from 'react-easy-crop';
import { IonButton, IonIcon } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import styles from './ImageCropperModal.module.scss';

type Props = {
  setCroppedImageFileBlob: (blob: Blob) => void;
  onClose: () => void;
  imgSrc: string;
  setCroppedImgSrc: Dispatch<SetStateAction<string>>;
};

// 正円で表示するなので1にする
export const ASPECT_RATIO = 34 / 34;
export const CROP_WIDTH = 340;

const ImageCropperModal: React.FC<Props> = ({ setCroppedImageFileBlob, onClose, imgSrc, setCroppedImgSrc }) => {
  /** 画像の拡大縮小倍率 */
  const [zoom, setZoom] = useState(1);
  /** 画像拡大縮小の最小値 */
  const [minZoom, setMinZoom] = useState(1);
  /** 切り取る領域の情報 */
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  /** 切り取る領域の情報 */
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  /**
   * Cropper側で画像データ読み込み完了
   * Zoomの最小値をセットしZoomの値も更新
   */
  const onMediaLoaded = useCallback((mediaSize: MediaSize) => {
    const { width, height } = mediaSize;
    const mediaAspectRadio = width / height;
    if (mediaAspectRadio > ASPECT_RATIO) {
      // 縦幅に合わせてZoomを指定
      const result = CROP_WIDTH / ASPECT_RATIO / height;
      setZoom(result);
      setMinZoom(result);
      return;
    }
    // 横幅に合わせてZoomを指定
    const result = CROP_WIDTH / width;
    setZoom(result);
    setMinZoom(result);
  }, []);

  /**
   * 切り取り完了後、切り取り領域の情報をセット
   */
  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  /**
   * 切り取り後の画像を生成し画面に表示
   */
  const setCroppedImage = useCallback(async () => {
    if (!croppedAreaPixels) return;
    try {
      const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
      setCroppedImgSrc(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imgSrc]);

  /**
   * urlをもとにimage要素を作成
   */
  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });

  /**
   * 画像トリミングを行い新たな画像urlを作成
   */
  async function getCroppedImg(imageSrc: string, pixelCrop: Area): Promise<string> {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return '';
    }

    // canvasサイズを設定
    canvas.width = image.width;
    canvas.height = image.height;

    // canvas上に画像を描画
    ctx.drawImage(image, 0, 0);

    // トリミング後の画像を抽出
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // canvasのサイズ指定(切り取り後の画像サイズに更新)
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // 抽出した画像データをcanvasの左隅に貼り付け
    ctx.putImageData(data, 0, 0);

    // canvasを画像に変換
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        if (file !== null) {
          setCroppedImageFileBlob(file);
          resolve(URL.createObjectURL(file));
        }
      }, 'image/png');
    });
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.cropContainer}>
        <div className={styles.cropSpace}>
          <Cropper
            image={imgSrc}
            crop={crop}
            zoom={zoom}
            minZoom={minZoom}
            maxZoom={minZoom + 3}
            aspect={ASPECT_RATIO}
            cropShape="round"
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropSize={{
              width: CROP_WIDTH,
              height: CROP_WIDTH / ASPECT_RATIO,
            }}
            classes={{
              containerClassName: 'container',
              cropAreaClassName: 'crop-area',
              mediaClassName: 'media',
            }}
            onMediaLoaded={onMediaLoaded}
            showGrid={false}
          />
        </div>
      </div>
      <IonButton
        className={styles.confirmButton}
        onClick={() => {
          setCroppedImage();
          onClose();
        }}
      >
        確定
      </IonButton>

      <IonButton className={styles.closeButton} onClick={onClose}>
        <IonIcon icon={closeOutline} className={styles.closeIcon} />
      </IonButton>
    </div>
  );
};
export default ImageCropperModal;
