import React from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { UserSearchForm } from 'components/admin/user/UserSearchForm';
import { BackButton } from 'components/common/buttons/BackButton';

import { withBasicAuth } from 'pages/hocs/withBasicAuth';

export const AdminUserPage: React.FC = withBasicAuth(() => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/admin" />
          </IonButtons>
          <IonTitle>ユーザー管理</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <UserSearchForm />
      </IonContent>
    </IonPage>
  );
});
