import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Project } from 'models';

interface getProjectsParameters {
  sort?: number;
  limit?: number;
  offset?: number;
}

export const useGetProjects = () => {
  const { currentUser } = useAuthInfo();
  const getProjects = async (data: getProjectsParameters) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`projects`, idToken);
    const res = await apiClient
      .get<Project[]>({ sort: data.sort, limit: data.limit, offset: data.offset })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res.data;
  };

  return { getProjects };
};
