import React from 'react';
import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import { LoginForm } from 'components/login/LoginForm';
import { BackButton } from 'components/common/buttons/BackButton';

import { withoutLogin } from 'pages/hocs/withoutLogin';

export const LoginPage: React.FC = withoutLogin(() => {
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/welcome" />
          </IonButtons>
          <IonTitle>ログイン</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <LoginForm />
      </IonContent>
    </IonPage>
  );
});
