import React from 'react';
import { useHistory } from 'react-router';
import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { add } from 'ionicons/icons';

import styles from './ProjectFab.module.scss';

export const ProjectFab: React.FC = () => {
  const history = useHistory();
  const goToProjectRegister = () => {
    history.push('/project/register');
  };

  return (
    <IonFab vertical="bottom" horizontal="end" className={styles.projectFab}>
      <IonFabButton className={styles.projectFabButton} onClick={goToProjectRegister}>
        <IonIcon icon={add} className={styles.projectFabButtonIcon} />
      </IonFabButton>
    </IonFab>
  );
};
