import React from 'react';

import { IonButton, IonIcon, IonInput, IonItem } from '@ionic/react';
import { useSignIn } from 'api/hooks/signin';
import { useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

import { WarningMessage } from 'components/common/WarningMessage';

import { REDIRECT_URL } from 'consts';

import styles from './LoginForm.module.scss';

interface LoginFormValues {
  email: string;
  password: string;
}

export const LoginForm: React.FC = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { postSignIn } = useSignIn();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  // 入力中の要素のRef、パスワードToggleボタンクリックした後にフォーカスを元に戻すために利用する
  const [inputtingElement, setInputtingElement] = useState<(EventTarget & HTMLIonInputElement) | null>(null);
  // Blur後に記憶した入力中要素をクリアするか
  // ・他の入力項目にフォーカスまたはToogleボタンをクリックした場合はクリアしない
  // ・それ以外の場合は、クリアする
  const shouldClearInputRef = useRef(true);

  const handleInputBlur = () => {
    // パスワードToggleボタンのクリックであるかを判断するために遅延する
    setTimeout(() => {
      // パスワードToggleボタンがクリックされなかった場合、フォーカスを戻す必要がないため、入力中項目をクリアする
      if (shouldClearInputRef.current) {
        setInputtingElement(null);
      }
      shouldClearInputRef.current = true;
    }, 300);
  };

  const handleClickTogglePassword = () => {
    inputtingElement?.focus();
    shouldClearInputRef.current = false;
    setPasswordShown(!passwordShown);
  };

  const signIn = async (data: LoginFormValues): Promise<void> => {
    setDisabled(true);

    // ログインAPI コール
    postSignIn(data)
      .then(() => {
        setLoginError(false);
        const redirectUrl = window.sessionStorage.getItem(REDIRECT_URL);
        if (redirectUrl) {
          // リダイレクトURLが存在する場合は保存されたURLをクリアしてリダイレクトする
          window.sessionStorage.removeItem(REDIRECT_URL);
          window.location.href = redirectUrl;
        } else {
          // それ以外の場合は案件一覧画面に遷移する
          history.push('/project');
        }
      })
      .catch(() => {
        setLoginError(true);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const goToResetPassword = () => {
    history.push('/password_reset');
  };

  const goToUserRegister = () => {
    history.push('/user/register');
  };

  return (
    <div className={styles.wrap}>
      {/* メールアドレス */}
      <div className="mypage__inputBlock">
        <div className="input__infoSection">メールアドレス</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="example@colorfully.jp"
          type="email"
          maxlength={150}
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('email', e.target.value)}
          {...register('email', {
            required: {
              value: true,
              message: '未入力です',
            },
            pattern: {
              value: /[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z.-_]{2,}/,
              message: '正しいメールアドレスを入力してください',
            },
          })}
          onFocus={(e) => {
            setInputtingElement(e.target);
            shouldClearInputRef.current = false;
          }}
          onBlur={handleInputBlur}
        />
        <div className="attentionBlock">
          {errors.email?.message && <WarningMessage message={errors.email.message} />}
        </div>
      </div>
      {/* パスワード */}
      <div className="mypage__inputBlock">
        <div className="input__infoSection">パスワード</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            placeholder="半角英数8文字以上"
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('password', e.target.value)}
            clearOnEdit={false}
            type={passwordShown ? 'text' : 'password'}
            maxlength={150}
            {...register('password', {
              required: {
                value: true,
                message: '未入力です',
              },
              pattern: {
                value: /^[a-zA-Z0-9]{8,}$/,
                message: '半角文字で8文字以上の入力が必要です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
          {passwordShown ? (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye-close.svg"
            />
          ) : (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye.svg"
            />
          )}
        </div>
        <div className="attentionBlock">
          {errors.password?.message && <WarningMessage message={errors.password.message} />}
        </div>
        <div className="attentionBlock">
          {loginError && !errors.email?.message && !errors.password?.message && (
            <WarningMessage message="メールアドレス or パスワードが正しくありません。または、 メール認証が済んでいないなどで存在しないアカウントです" />
          )}
        </div>
      </div>
      {/* ログインボタン */}
      <div className={styles.buttons}>
        <IonButton className={styles.button} disabled={disabled} onClick={handleSubmit(signIn)}>
          ログイン
        </IonButton>
      </div>
      {/* その他リンク等 */}
      <div className={styles.links}>
        <IonItem className={styles.link} onClick={goToResetPassword} lines="none">
          パスワードを忘れた方
          <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
        </IonItem>
        <IonItem className={styles.link} onClick={goToUserRegister} lines="none">
          ユーザー登録
          <IonIcon className={styles.arrow} slot="end" src="assets/common/arrow1.svg" />
        </IonItem>
      </div>
    </div>
  );
};
