import { FC, useEffect, useRef, useState } from 'react';
import { useAtom } from 'jotai';

import styles from './UnpaidProjects.module.scss';
import { UnpaidProjectItem } from './UnpaidProjectItem';
import { Project } from 'models';
import { useGetUnpaidProjects } from 'api/hooks/sale/useGetUnpaidProjects';
import { AxiosError } from 'axios';
import { IonInfiniteScroll, IonInfiniteScrollContent, useIonToast } from '@ionic/react';

import { saleUnpaidAtom } from 'stores/sale';

const UNPAID_PROJECTS_LIMIT = 20;

export const UnpaidProjects: FC = () => {
  const [unpaidProjects, setUnpaidProjects] = useState<Project[]>([]);
  const [isInitSearched, setIsInitSearched] = useState(false);
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);
  const [saleUnpaidAtomValue, setSaleUnpaidAtomValue] = useAtom(saleUnpaidAtom);

  const { getUnpaidProjects } = useGetUnpaidProjects();

  const [presentToast] = useIonToast();

  const getInitialProjects = () => {
    getUnpaidProjects(UNPAID_PROJECTS_LIMIT, 0)
      .then((projects) => {
        setUnpaidProjects(projects);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  useEffect(() => {
    getInitialProjects();
    setIsInitSearched(true);
  }, []);

  // 案件情報編集画面で更新した場合、再検索する
  useEffect(() => {
    if (saleUnpaidAtomValue.isNeedRefresh && isInitSearched) {
      // リフレッシュの場合は取得済の件数分を取得する
      const limit = unpaidProjects.length || UNPAID_PROJECTS_LIMIT;
      setSaleUnpaidAtomValue((prevState) => ({ ...prevState, isNeedRefresh: false }));
      // 念のためクリアする
      setUnpaidProjects([]);
      // 再検索する
      getUnpaidProjects(limit, 0)
        .then((projects) => {
          setUnpaidProjects(projects);
        })
        .catch((e: AxiosError<{ message: string; type: string }>) => {
          presentToast({
            message: e.response?.data?.message,
            duration: 2000,
          });
        });
    }
  }, [saleUnpaidAtomValue.isNeedRefresh]);

  // 無限スクロール時の追加データ取得に使用
  const fetchMoreUnpaidProjects = () => {
    getUnpaidProjects(UNPAID_PROJECTS_LIMIT, unpaidProjects.length)
      .then((projects) => {
        setUnpaidProjects([...unpaidProjects, ...projects]);
        infiniteScrollRef.current?.complete();
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  return (
    <ul className={styles.projectItemList}>
      {unpaidProjects.map((project) => (
        <li key={project.id}>
          <UnpaidProjectItem project={project} refreshProjects={getInitialProjects} />
        </li>
      ))}

      <IonInfiniteScroll onIonInfinite={fetchMoreUnpaidProjects} ref={infiniteScrollRef}>
        <IonInfiniteScrollContent />
      </IonInfiniteScroll>
    </ul>
  );
};
