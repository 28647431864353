import React from 'react';
import { ChromePicker, ColorResult } from 'react-color';

import styles from './StyleContent.module.scss';

interface StyleContentProps {
  fontColor: string;
  backGroundColor: string;
  fontColorPickerOpen: boolean;
  setFontColorPickerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  backGroundColorOpen: boolean;
  setBackGroundColorOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleFontColorChange: (color: ColorResult) => void;
  handleBackGroundColorChange: (color: ColorResult) => void;
  handleResetFontColor: () => void;
  handleResetBackGroundColor: () => void;
}

export const StyleContent: React.FC<StyleContentProps> = ({
  fontColor,
  backGroundColor,
  fontColorPickerOpen,
  setFontColorPickerOpen,
  backGroundColorOpen,
  setBackGroundColorOpen,
  handleFontColorChange,
  handleBackGroundColorChange,
  handleResetFontColor,
  handleResetBackGroundColor,
}) => {
  return (
    <div
      className={styles.pageMargin}
      onClick={() => {
        fontColorPickerOpen && setFontColorPickerOpen(false);
        backGroundColorOpen && setBackGroundColorOpen(false);
      }}
    >
      <div className={styles.itemMargin}>
        <div className={styles.colorPickerTitle}>文字色</div>
        <div className={styles.arrangePickerContent}>
          <div
            className={styles.colorPickerBox}
            onClick={() => {
              setFontColorPickerOpen(!fontColorPickerOpen);
            }}
          >
            {fontColor}
          </div>
          <div className={styles.resetButton} onClick={handleResetFontColor}>
            リセット
          </div>
        </div>
        {fontColorPickerOpen && <ChromePicker disableAlpha={true} onChange={handleFontColorChange} color={fontColor} />}
      </div>
      <div className={styles.itemMargin}>
        <div className={styles.colorPickerTitle}>背景色</div>
        <div className={styles.arrangePickerContent}>
          <div
            className={styles.colorPickerBox}
            onClick={() => {
              setBackGroundColorOpen(!backGroundColorOpen);
            }}
          >
            {backGroundColor}
          </div>
          <div className={styles.resetButton} onClick={handleResetBackGroundColor}>
            リセット
          </div>
        </div>
        {backGroundColorOpen && (
          <ChromePicker disableAlpha={true} onChange={handleBackGroundColorChange} color={backGroundColor} />
        )}
      </div>
    </div>
  );
};
