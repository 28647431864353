import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';

interface PatchProjectParams {
  name: string;
  status: number;
  startDate?: string | null;
  endDate: string;
  clientId: number;
  description?: string;
  contractPrice: number;
  paymentDueDate?: string | null;
  paymentDate?: string | null;
}

export const useUpdateProject = () => {
  const { currentUser } = useAuthInfo();
  const patchProject = async (id: number, params: PatchProjectParams) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`projects/${id}`, idToken);
    const res = await apiClient.patch(params).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { patchProject };
};
