import React from 'react';
import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { IonButton, IonIcon, IonInput, useIonAlert } from '@ionic/react';
import { AxiosError } from 'axios';

import { useDeleteUser } from 'api/hooks/setting/useDeleteUser';
import { auth } from 'utils/firebase';

import { WarningMessage } from 'components/common/WarningMessage';

import styles from './WithdrawalForm.module.scss';

export interface WithdrawalFormValues {
  email: string;
  password: string;
}

export const WithdrawalForm: React.FC = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [isServerValidationlError, setIsServerValidationlError] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { deleteUser } = useDeleteUser();
  const [presentAlert] = useIonAlert();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<WithdrawalFormValues>({ mode: 'onBlur', reValidateMode: 'onBlur' });

  // 入力中の要素のRef、パスワードToggleボタンクリックした後にフォーカスを元に戻すために利用する
  const [inputtingElement, setInputtingElement] = useState<(EventTarget & HTMLIonInputElement) | null>(null);
  // Blur後に記憶した入力中要素をクリアするか
  // ・他の入力項目にフォーカスまたはToogleボタンをクリックした場合はクリアしない
  // ・それ以外の場合は、クリアする
  const shouldClearInputRef = useRef(true);

  const handleInputBlur = () => {
    // パスワードToggleボタンのクリックであるかを判断するために遅延する
    setTimeout(() => {
      // パスワードToggleボタンがクリックされなかった場合、フォーカスを戻す必要がないため、入力中項目をクリアする
      if (shouldClearInputRef.current) {
        setInputtingElement(null);
      }
      shouldClearInputRef.current = true;
    }, 300);
  };

  const handleClickTogglePassword = () => {
    inputtingElement?.focus();
    shouldClearInputRef.current = false;
    setPasswordShown(!passwordShown);
  };

  const executeWithdrawal = (data: WithdrawalFormValues) => {
    setIsDeleting(true);

    setIsServerValidationlError(false);

    // ユーザ退会API コール
    deleteUser(data)
      .then(() => {
        presentAlert({
          header: '退会完了',
          message: '退会手続きが完了しました',
          buttons: [
            {
              text: 'OK',
              handler: () => {
                auth.signOut().finally(() => {
                  window.location.replace('/welcome');
                });
              },
            },
          ],
          mode: 'ios',
          backdropDismiss: false,
        });
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        if (e.response?.data.type === 'ValidationError') {
          setIsServerValidationlError(true);
        } else {
          presentAlert({
            header: '退会エラー',
            message: e.response?.data?.message,
            buttons: [
              {
                text: 'OK',
              },
            ],
            mode: 'ios',
          });
        }
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const withdrawal = async (data: WithdrawalFormValues): Promise<void> => {
    presentAlert({
      mode: 'ios',
      header: '最終確認',
      message: '登録データはすべて削除されます',
      buttons: [
        {
          text: '　　キャンセル　　',
          cssClass: styles.alertCancelBtn,
        },
        {
          text: '　　退会　　',
          handler: () => {
            executeWithdrawal(data);
          },
        },
      ],
    });
  };

  return (
    <div className={styles.wrap}>
      <p className={styles.note}>退会するとすべてのデータが失われますがよろしいでしょうか。</p>
      {/* メールアドレス */}
      <div className="mypage__inputBlock">
        <div className="input__infoSection">メールアドレス</div>
        <IonInput
          className="ion-input__commonText"
          placeholder="example@colorfully.jp"
          type="email"
          maxlength={150}
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('email', e.target.value)}
          {...register('email', {
            required: {
              value: true,
              message: '未入力です',
            },
            pattern: {
              value: /[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z.-_]{2,}/,
              message: '正しいメールアドレスを入力してください',
            },
          })}
          onFocus={(e) => {
            setInputtingElement(e.target);
            shouldClearInputRef.current = false;
          }}
          onBlur={handleInputBlur}
        />
        <div className="attentionBlock">
          {errors.email?.message && <WarningMessage message={errors.email.message} />}
        </div>
        <div className="attentionBlock">
          {isServerValidationlError && <WarningMessage message="入力内容に間違いがあります" />}
        </div>
      </div>
      {/* パスワード */}
      <div className="mypage__inputBlock">
        <div className="input__infoSection">パスワード</div>
        <div className={styles.row}>
          <IonInput
            className="ion-input__commonText"
            placeholder="半角英数8文字以上"
            maxlength={150}
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('password', e.target.value)}
            clearOnEdit={false}
            type={passwordShown ? 'text' : 'password'}
            {...register('password', {
              required: {
                value: true,
                message: '未入力です',
              },
              pattern: {
                value: /^[a-zA-Z0-9]{8,}$/,
                message: '半角文字で8文字以上の入力が必要です',
              },
            })}
            onFocus={(e) => {
              setInputtingElement(e.target);
              shouldClearInputRef.current = false;
            }}
            onBlur={handleInputBlur}
          />
          {passwordShown ? (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye-close.svg"
            />
          ) : (
            <IonIcon
              className={styles.eye}
              onClick={handleClickTogglePassword}
              slot="end"
              src="assets/common/icn__eye.svg"
            />
          )}
        </div>
        <div className="attentionBlock">
          {errors.password?.message && <WarningMessage message={errors.password.message} />}
        </div>
        <div className="attentionBlock">
          {isServerValidationlError && <WarningMessage message="入力内容に間違いがあります" />}
        </div>
      </div>
      <div className={styles.buttons}>
        <IonButton className={styles.button} disabled={isDeleting} onClick={handleSubmit(withdrawal)}>
          退会する
        </IonButton>
      </div>
    </div>
  );
};
