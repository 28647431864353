import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { ProfilePhoto } from 'models';

interface PutPhotoParams {
  displayNumber: number;
  photoImageKey: string;
}

export const useUpdatePhoto = () => {
  const { authInfo, currentUser } = useAuthInfo();
  const updatePhoto = async (data: PutPhotoParams) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`users/${authInfo.userId}/achievement_photos`, idToken);
    const res = await apiClient.put<ProfilePhoto>(data).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { updatePhoto };
};
