import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';

interface PostUserRequest {
  email: string;
  password: string;
  name: string;
}

interface PostUserResponse {
  id: string;
  email: string;
}

export const useRegisterUser = () => {
  const postUser = async (data: PostUserRequest): Promise<PostUserResponse> => {
    const apiClient = new ApiClient('users', '');
    const res = await apiClient
      .post<PostUserResponse>(data)
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res.data;
  };

  return { postUser };
};
