import {
  collection,
  doc,
  runTransaction,
  CollectionReference,
  DocumentReference,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { fireStore } from 'utils/firebase';

import { SENDER_CLIENT, SENDER_CREATOR } from 'consts';
import { Chat, FileType, Message } from 'models';

interface CreateMessageParams {
  chatRoomKey: string;
  senderType: typeof SENDER_CREATOR | typeof SENDER_CLIENT;
  body: string;
  file: FileType[];
}

export const useCreateMessage = () => {
  const createMessage = (params: CreateMessageParams, senderType: typeof SENDER_CREATOR | typeof SENDER_CLIENT) => {
    return runTransaction(fireStore, async (transaction) => {
      const chatDoc = doc(fireStore, 'chats', params.chatRoomKey) as DocumentReference<Chat>;
      // クライアントからのメッセージの場合のみ未読フラグを更新
      if (senderType === SENDER_CLIENT) {
        await updateDoc(chatDoc, { unread: true, updatedAt: serverTimestamp() });
      }
      const messageCollection = collection(fireStore, 'chats', chatDoc.id, 'messages') as CollectionReference<Message>;
      const messageDoc = doc(messageCollection) as DocumentReference<Message>;
      transaction.set(messageDoc, {
        senderType: params.senderType,
        body: params.body,
        attachmentFiles: params.file,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        deletedAt: null,
        sendEmailAt: null,
      });
    });
  };

  return { createMessage };
};
