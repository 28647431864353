import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';
import { useBasicAuthInfo } from 'basicAuth';

export const useDeleteUser = () => {
  const { authInfo } = useBasicAuthInfo();

  const deleteUser = async (id: number, truncate: boolean) => {
    const apiClient = new ApiClient(`admin/users/${id}?truncate=${truncate}`, '');
    const res = await apiClient
      .delete(
        {},
        {
          headers: {
            Authorization: `Basic ${authInfo.credentials}`,
          },
        }
      )
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res.data;
  };

  return { deleteUser };
};
