import { useAuthInfo } from 'auth';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { dayjs } from 'utils/dayjs';

export const useDownloadSaleFile = () => {
  const { currentUser } = useAuthInfo();
  const downloadSaleFile = async (year: number) => {
    const idToken = await currentUser?.getIdToken();

    // ApiClientを使うとCSVファイルのダウンロードがうまくいかないので、ここだけaxiosを直接利用する。
    axios
      .get(`${process.env.REACT_APP_BACK_END_API_ENDPOINT}sales/file`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        params: {
          year: year,
        },
        responseType: 'blob',
      })
      .then((res) => {
        const mineType = res.headers['content-type'];
        // axiosでレスポンスヘッダcontent-dispositionを取得できないためフロント側で生成する
        // const name = res.headers['content-disposition'];
        // 2023-saleshistory-202306052230.xlsx
        const now = dayjs();
        const name = `${year}-saleshistory-${now.format('YYYYMMDDHHmm')}.xlsx`;
        const blob = new Blob([res.data], { type: mineType });
        saveAs(blob, name);
      })
      .catch((e) => {
        throw e;
      });
  };

  return { downloadSaleFile };
};
