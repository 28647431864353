import { IonButton, IonIcon, IonImg, IonInput, IonSelect, IonSelectOption, IonTextarea } from '@ionic/react';
import { FC, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import {
  Control,
  Controller,
  DeepMap,
  DeepPartial,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import classNames from 'classnames';

import { isHiragana } from 'utils/string';
import ImageCropperModal from 'components/common/ImageCropperModal';
import { WarningMessage } from 'components/common/WarningMessage';

import styles from './ProfileForm.module.scss';

import { refreshOutline, trashOutline } from 'ionicons/icons';
import { ProfileFormValues } from 'models/profileSetting';

import { isSmartPhone } from 'utils/device';
import { removeHyphenAndE } from 'utils/string';

import { FOOT_SIZES, IMAGE_FILE_MAX_SIZE } from 'consts/profileSetting';

// 整数最大３桁、小数第一位までは入力できる
const convertNum = (value: string) => {
  const arr = value.split('.');
  // 小数点なしの場合
  if (arr.length === 1) return Number(arr[0].slice(0, 3));
  // 小数点ありの場合
  return Number(`${arr[0].slice(0, 3)}.${arr[1].slice(0, 1)}`);
};

interface ProfileFormProps {
  setCroppedImageFileBlob: (blob: Blob) => void;
  onImageFileDeleted: () => void;
  register: UseFormRegister<ProfileFormValues>;
  setValue: UseFormSetValue<ProfileFormValues>;
  control: Control<ProfileFormValues>;
  errors: DeepMap<DeepPartial<ProfileFormValues>, FieldError>;
  // 編集フォームのときのみ利用
  imageFileUrl: string | null;
  fetched: boolean;
}

export const ProfileForm: FC<ProfileFormProps> = ({
  setCroppedImageFileBlob,
  onImageFileDeleted = () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  register,
  setValue,
  control,
  errors,
  imageFileUrl,
  fetched,
}) => {
  const [imageCropperModalIsOpen, setImageCropperModalIsOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [croppedImgSrc, setCroppedImgSrc] = useState('');

  const modalStyles = {
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      backgroundColor: 'transparent',
      inset: '0',
      padding: '0',
    },
    overlay: {
      backgroundColor: 'rgba(0 ,0 ,0 ,0.8)',
    },
  };

  /**
   * 画像ファイルアップロード後
   * 画像ファイルのURLをセットしモーダルを表示する
   */
  const onImageFileChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // サイズが5MB以下であることを確認
      if (e.target.files[0].size > IMAGE_FILE_MAX_SIZE) {
        return;
      }

      const reader = new FileReader();
      reader.addEventListener('load', () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || '');
          setImageCropperModalIsOpen(true);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);

  useEffect(() => {
    if (imageFileUrl) {
      setImgSrc(imageFileUrl);
      setCroppedImgSrc(imageFileUrl);
    }
  }, [imageFileUrl]);

  return (
    <div className={classNames(styles.wrap, isSmartPhone() && styles.smartPhone)}>
      <div className={styles.profileInputBlock}>
        <div className={styles.inputInfoSection}>プロフィールメイン画像</div>
        <div className={styles.imageInputSection}>
          {croppedImgSrc ? (
            <div className={styles.uploadedImageContainer}>
              <img src={croppedImgSrc} className={styles.uploadedImage} />
              <div className={styles.imageEditButtons}>
                <label htmlFor="profileImageInput">
                  <IonIcon icon={refreshOutline} className={styles.refreshImageIcon} />
                </label>
                <IonIcon
                  icon={trashOutline}
                  className={styles.deleteImageIcon}
                  onClick={() => {
                    setImgSrc('');
                    setCroppedImgSrc('');
                    onImageFileDeleted();
                    setValue('imageFile', null);
                  }}
                />
              </div>
            </div>
          ) : (
            <IonButton fill="clear" className={styles.imageUploadButton}>
              <label htmlFor="profileImageInput" className={styles.fileInputLabel}>
                {fetched && (
                  <IonImg className={styles.imageUploadImage} src="assets/profile_setting/icn__addImage.png" />
                )}
              </label>
            </IonButton>
          )}
          <input
            id="profileImageInput"
            type="file"
            accept="image/jpeg,image/png"
            hidden
            {...register('imageFile', {
              onChange: onImageFileChange,
              validate: (fileList: FileList | null): string | true => {
                if (fileList && fileList.length && fileList[0].size > IMAGE_FILE_MAX_SIZE) {
                  return '画像ファイルサイズ容量は5MB以下にしてください';
                }
                return true;
              },
            })}
          />
          <div className="attentionBlock">
            {errors.imageFile?.message && <WarningMessage message={errors.imageFile.message} />}
          </div>
        </div>

        <div className={styles.inputInfoSection}>
          アカウント名（プロフページURL）<span className={styles.required}>必須</span>
        </div>
        <div className={styles.accountNameInputSection}>
          <span className={styles.atMark}>@</span>
          <IonInput
            className="ion-input__commonText"
            type="text"
            onIonInput={(e) => typeof e.target.value === 'string' && setValue('accountName', e.target.value)}
            maxlength={14}
            {...register('accountName', {
              required: {
                value: true,
                message: 'アカウント名の入力が必要です',
              },
              pattern: {
                value: /^[A-Za-z0-9_]+$/,
                message: "アカウント名は英数字と'_'(アンダーバー)が使えます",
              },
              validate: (value) => {
                if (value && value.length < 4) {
                  return 'アカウント名は4文字以上にしてください';
                }

                return true;
              },
            })}
          />
        </div>
        <div className="attentionBlock">
          {errors.accountName?.message && <WarningMessage message={errors.accountName.message} />}
        </div>

        <div className={styles.inputInfoSection}>活動名</div>
        <IonInput
          className="ion-input__commonText"
          type="text"
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('businessName', e.target.value)}
          maxlength={150}
          {...register('businessName')}
        />

        <div className={styles.inputInfoSection}>活動名ふりがな</div>
        <IonInput
          className="ion-input__commonText"
          type="text"
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('businessNameFurigana', e.target.value)}
          maxlength={150}
          {...register('businessNameFurigana', {
            validate: (value) => {
              if (value && !isHiragana(value)) {
                return '平仮名以外は使えません';
              }

              return true;
            },
          })}
        />
        <div className="attentionBlock">
          {errors.businessNameFurigana?.message && <WarningMessage message={errors.businessNameFurigana.message} />}
        </div>

        <div className={styles.inputInfoSection}>身長</div>
        <div className={styles.inputSectionWithUnit}>
          <IonInput
            className={`ion-input__commonText ${styles.numberInput}`}
            type="number"
            inputMode="decimal"
            onKeyDown={(e) => {
              // 「e」と「-」の入力を禁止する
              if (e.key === 'e' || e.key === '-') {
                e.preventDefault();
              }
            }}
            onIonInput={(e) => {
              const value = removeHyphenAndE(String(e.target.value));
              if (!value.length) {
                setValue('height', null);
              } else if (value.length) {
                // 整数最大３桁、少数第一位までは入力できる
                setValue('height', convertNum(value));
              }
            }}
            maxlength={5}
            {...register('height')}
          />
          <span className={styles.unit}>cm</span>
        </div>

        <div className={styles.inputInfoSection}>体重</div>
        <div className={styles.inputSectionWithUnit}>
          <IonInput
            className={`ion-input__commonText ${styles.numberInput}`}
            type="number"
            inputMode="decimal"
            onKeyDown={(e) => {
              // 「e」と「-」の入力を禁止する
              if (e.key === 'e' || e.key === '-') {
                e.preventDefault();
              }
            }}
            onIonInput={(e) => {
              const value = removeHyphenAndE(String(e.target.value));
              if (!value.length) {
                setValue('weight', null);
              } else if (value.length) {
                // 整数最大３桁、少数第一位までは入力できる
                setValue('weight', convertNum(value));
              }
            }}
            maxlength={5}
            {...register('weight')}
          />
          <span className={styles.unit}>Kg</span>
        </div>

        <div className={styles.inputInfoSection}>足のサイズ</div>
        <div className={styles.inputSectionWithUnit}>
          <Controller
            control={control}
            name="footSize"
            render={({ field: { value } }) => (
              <IonSelect
                value={value}
                className={styles.selectInput}
                interface="popover"
                onIonChange={(e) => {
                  setValue('footSize', Number(e.target.value));
                }}
              >
                <IonSelectOption value={0}></IonSelectOption>
                {FOOT_SIZES.map((size) => (
                  <IonSelectOption key={size} value={size}>
                    {size}
                  </IonSelectOption>
                ))}
              </IonSelect>
            )}
          />
          <span className={styles.unit}>cm</span>
        </div>

        <div className={styles.inputInfoSection}>スリーサイズ</div>

        <div className={`${styles.inputInfoSection} ${styles.inputInfoSectionBust}`}>バスト</div>
        <div className={styles.inputSectionWithUnit}>
          <IonInput
            className={`ion-input__commonText ${styles.numberInput}`}
            type="number"
            inputMode="decimal"
            onKeyDown={(e) => {
              // 「e」と「-」の入力を禁止する
              if (e.key === 'e' || e.key === '-') {
                e.preventDefault();
              }
            }}
            onIonInput={(e) => {
              const value = removeHyphenAndE(String(e.target.value));
              if (!value.length) {
                setValue('bustSize', null);
              } else if (value.length) {
                // 整数最大３桁、少数第一位までは入力できる
                setValue('bustSize', convertNum(value));
              }
            }}
            maxlength={5}
            {...register('bustSize')}
          />
          <span className={styles.unit}>cm</span>
        </div>

        <div className={styles.inputInfoSection}>ウエスト</div>
        <div className={styles.inputSectionWithUnit}>
          <IonInput
            className={`ion-input__commonText ${styles.numberInput}`}
            type="number"
            inputMode="decimal"
            onKeyDown={(e) => {
              // 「e」と「-」の入力を禁止する
              if (e.key === 'e' || e.key === '-') {
                e.preventDefault();
              }
            }}
            onIonInput={(e) => {
              const value = removeHyphenAndE(String(e.target.value));
              if (!value.length) {
                setValue('waistSize', null);
              } else if (value.length) {
                // 整数最大３桁、少数第一位までは入力できる
                setValue('waistSize', convertNum(value));
              }
            }}
            maxlength={5}
            {...register('waistSize')}
          />
          <span className={styles.unit}>cm</span>
        </div>

        <div className={styles.inputInfoSection}>ヒップ</div>
        <div className={styles.inputSectionWithUnit}>
          <IonInput
            className={`ion-input__commonText ${styles.numberInput}`}
            type="number"
            inputMode="decimal"
            onKeyDown={(e) => {
              // 「e」と「-」の入力を禁止する
              if (e.key === 'e' || e.key === '-') {
                e.preventDefault();
              }
            }}
            onIonInput={(e) => {
              const value = removeHyphenAndE(String(e.target.value));
              if (!value.length) {
                setValue('hipSize', null);
              } else if (value.length) {
                // 整数最大３桁、少数第一位までは入力できる
                setValue('hipSize', convertNum(value));
              }
            }}
            maxlength={5}
            {...register('hipSize')}
          />
          <span className={styles.unit}>cm</span>
        </div>

        <div className={styles.inputInfoSection}>自己紹介・自由テキスト</div>
        <IonTextarea
          className={styles.detailInputTextArea}
          rows={8}
          maxlength={10_000}
          onIonInput={(e) => typeof e.target.value === 'string' && setValue('selfIntroduction', e.target.value)}
          {...register('selfIntroduction', {})}
        />

        <Modal
          isOpen={imageCropperModalIsOpen}
          onRequestClose={() => setImageCropperModalIsOpen(false)}
          style={modalStyles}
        >
          <ImageCropperModal
            onClose={() => setImageCropperModalIsOpen(false)}
            imgSrc={imgSrc}
            setCroppedImgSrc={setCroppedImgSrc}
            setCroppedImageFileBlob={setCroppedImageFileBlob}
          />
        </Modal>
      </div>
    </div>
  );
};
