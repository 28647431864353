import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from '@ionic/react';
import dayjs from 'dayjs';

import { FC, useEffect, useState, useRef } from 'react';

import styles from './index.module.scss';

import { BackButton } from 'components/common/buttons/BackButton';

import { withLogin } from 'pages/hocs/withLogin';
import { useForm } from 'react-hook-form';
import { ProjectFormValues, ProjectStatus, Project } from 'models';
import { useHistory, useParams, useLocation } from 'react-router';

import { AxiosError } from 'axios';
import { useGetProject } from 'api/hooks/project/useGetProject';
import { ProjectForm } from 'components/project/ProjectForm';
import { ImageFileType, useUploadFile } from 'api/hooks/upload/useUploadFile';
import { useUpdateProject } from 'api/hooks/project/useUpdateProject';
import { useAtom } from 'jotai';
import { projectDetailAtom, projectListAtom } from 'stores/project';
import { saleHistoryAtom, saleUnpaidAtom } from 'stores/sale';

import { isSameDate } from 'utils/date';

export const ProjectEditPage: FC = withLogin(() => {
  const params = useParams<{ projectId: string }>();
  const projectId = parseInt(params.projectId, 10);
  const [, setProjectDetailAtomValue] = useAtom(projectDetailAtom);
  const [, setProjectListAtomValue] = useAtom(projectListAtom);
  const [, setSaleHistoryAtomValue] = useAtom(saleHistoryAtom);
  const [, setSaleUnpaidAtomValue] = useAtom(saleUnpaidAtom);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<ProjectFormValues>({ mode: 'all' });

  const { uploadFile } = useUploadFile();
  const { patchProject } = useUpdateProject();
  const { getProject } = useGetProject();

  const history = useHistory();
  const { pathname, search } = useLocation();
  const projectDetailUrl = pathname.replace('/edit', '') + search;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const unblockRef = useRef(() => {});

  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();

  const [imageFileUrl, setImageFileUrl] = useState<string>();
  const [croppedImageFileBlob, setCroppedImageFileBlob] = useState<Blob | null>(null);
  const [contractFileUrl, setContractFileUrl] = useState<string>();
  const [isImageFileDeleted, setIsImageFileDeleted] = useState(false);
  const [isContractFileDeleted, setIsContractFileDeleted] = useState(false);

  const [originalProject, setOriginalProject] = useState<Project>();

  const editProject = async (data: ProjectFormValues): Promise<void> => {
    presentLoading({
      duration: 2000,
    });

    // 画像アップロードAPI コール
    let imageFileKey: string | null = null;
    let contractFileKey: string | null = null;
    await Promise.all([
      // トリミング時にサムネイル画像は必ずpng形式に変換される
      data.imageFile?.length ? uploadFile(ImageFileType.Thumbnail, data.imageFile[0], croppedImageFileBlob) : null,
      data.contractFile?.length ? uploadFile(ImageFileType.ContractFile, data.contractFile[0]) : null,
    ])
      .then((res) => {
        imageFileKey = res[0];
        contractFileKey = res[1];
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        dismissLoading();
        presentAlert({
          header: '更新エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
        return Promise.reject(e);
      });

    // 案件更新APIコール
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    let patchProjectParams = {
      name: data.name!,
      status: data.status!,
      startDate: data.startDate ? dayjs(data.startDate).format('YYYY-MM-DD') : null,
      endDate: dayjs(data.endDate).format('YYYY-MM-DD'),
      description: data.description!,
      contractPrice: data.contractPrice!,
      clientId: data.clientId!,
      paymentDueDate: dayjs(data.paymentDueDate).format('YYYY-MM-DD'),
      paymentDate: data.status === ProjectStatus.Paid ? dayjs(data.paymentDate).format('YYYY-MM-DD') : null,
    };
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    if (imageFileKey !== null) {
      // 案件イメージファイル更新ありの場合
      patchProjectParams = { ...patchProjectParams, ...{ thumbnailFileKey: imageFileKey } };
    } else if (imageFileUrl && isImageFileDeleted) {
      // 案件イメージファイルが削除された場合
      patchProjectParams = { ...patchProjectParams, ...{ thumbnailFileKey: null } };
    }

    if (contractFileKey !== null) {
      // 契約ファイル更新ありの場合
      patchProjectParams = { ...patchProjectParams, ...{ contractFileKey: contractFileKey } };
    } else if (contractFileUrl && isContractFileDeleted) {
      // 契約ファイルが削除された場合
      patchProjectParams = { ...patchProjectParams, ...{ contractFileKey: null } };
    }

    patchProject(projectId, patchProjectParams)
      .then(() => {
        presentToast({ message: '編集内容が保存されました', duration: 2000 });
        // 案件詳細画面と案件一覧画面をリフレッシュする
        setProjectDetailAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
        setProjectListAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
        // 売上管理画面と未入金リスト画面をリフレッシュする
        setSaleHistoryAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
        // 未入金リスト画面のデータをリフレッシュさせる
        setSaleUnpaidAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
        // 未保存確認チェック処理を解除
        unblockRef.current();
        // 案件詳細画面に戻る
        return history.replace(projectDetailUrl);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentAlert({
          header: '更新エラー',
          message: e.response?.data?.message,
          buttons: [
            {
              text: 'OK',
            },
          ],
          mode: 'ios',
        });
      })
      .finally(() => {
        dismissLoading();
      });
  };

  useEffect(() => {
    getProject(projectId)
      .then((project) => {
        // 未保存確認する際に元々のデータに対して変更あるかを判断するためにレスポンスデータを保持する
        setOriginalProject(project);
        // 入力Formの各項目に設定する
        setValue('name', project.name);
        setValue('status', project.status);
        setValue('startDate', project.startDate);
        setValue('endDate', project.endDate);
        setValue('description', project.description);
        setValue('contractPrice', project.contractPrice);
        setValue('clientId', project.client.id);
        setValue('paymentDueDate', project.paymentDueDate);
        setValue('paymentDate', project.paymentDate);
        setImageFileUrl(project.thumbnailFileUrl);
        setContractFileUrl(project.contractFileUrl);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  }, []);

  const showBackConfirmationAlert = (navigate: () => void, cancel: () => void) => {
    presentAlert({
      mode: 'ios',
      header: '確認',
      message: '保存せずに移動すると入力内容が破棄されますが良いですか？',
      buttons: [
        {
          text: '　移動する　',
          handler: navigate,
        },
        {
          text: '　編集画面に戻る　',
          handler: cancel,
        },
      ],
    });
  };

  // ブラウザのタブを閉じた際にダイアログを表示する。
  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeunload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, []);

  // 入力フォーム内容に一つでも変更されたか
  const isValueChanged = (originalProject: Project | undefined) => {
    return (
      !isSameDate(watch('startDate'), originalProject?.startDate || null) ||
      !isSameDate(watch('endDate'), originalProject?.endDate || null) ||
      watch('status') !== originalProject?.status ||
      watch('name') !== originalProject?.name ||
      watch('imageFile')?.length !== 0 ||
      watch('clientId') !== originalProject?.client.id ||
      watch('contractPrice') !== originalProject?.contractPrice ||
      watch('description') !== originalProject?.description ||
      watch('contractFile')?.length !== 0 ||
      !isSameDate(watch('paymentDueDate'), originalProject?.paymentDueDate || null)
    );
  };

  useEffect(() => {
    // 案件編集画面場合のみ、登録する
    if (pathname === `/project/${projectId}/edit`) {
      // ブラウザバック・戻るボタン での遷移時にアラートを表示する。
      unblockRef.current = history.block((location, action) => {
        // フォームに一つでも値が変更されている かつ 案件編集画面から案件詳細画面への遷移の時のみアラートを表示する
        if (
          isValueChanged(originalProject) &&
          location.pathname === `/project/${projectId}` &&
          // 売上一覧・未入金一覧から遷移されてきた場合、ionic/react-routerの問題（バグ？）でここで確認モーダルが表示されるが、画面遷移が止められない。
          // https://github.com/monokrom-inc/managementapp-frontend/issues/223#issuecomment-1575381099
          // ナビゲーション履歴を壊れないように、上記ケースを除外する
          !location.search.includes('/sale')
        ) {
          showBackConfirmationAlert(
            () => {
              unblockRef.current();
              if (action === 'POP') {
                history.goBack();
              } else if (action === 'REPLACE') {
                history.replace(location.pathname);
              } else {
                history.push(location.pathname);
              }
            },
            () => {
              // react-routerのバグで詳細へ戻るなのにREPLACEになってしまうことがある
              // 案件詳細画面へpushするよう履歴スタックをやり直す
              if (action === 'REPLACE') {
                history.push(projectDetailUrl);
              }
            }
          );

          return false;
        }
      });
    }

    return () => {
      unblockRef.current();
    };
  }, [pathname, originalProject]);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButtons className="header">
            <BackButton defaultHref={projectDetailUrl} />
            <IonTitle>案件情報の編集</IonTitle>
            <IonButton className={styles.saveButton} onClick={handleSubmit(editProject)}>
              保存
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ProjectForm
          setCroppedImageFileBlob={setCroppedImageFileBlob}
          onImageFileDeleted={() => {
            setIsImageFileDeleted(true);
          }}
          onContractFileDeleted={() => {
            setIsContractFileDeleted(true);
          }}
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
          errors={errors}
          imageFileUrl={imageFileUrl}
          contractFileUrl={contractFileUrl}
          isEdit
        />
      </IonContent>
    </IonPage>
  );
});
