import dayjs from 'dayjs';

export const convertDateFormat = (date: string | null) => {
  if (date != null) {
    return dayjs(date).format('YYYY/MM/DD');
  }

  return '';
};

// 年月日が同じであるかどうかを返す
export const isSameDate = (date1: string | null, date2: string | null) => {
  if (date1 === null && date2 === null) return true;

  if (date1 !== null && date2 === null) return false;

  if (date1 === null && date2 !== null) return false;

  return convertDateFormat(date1) === convertDateFormat(date2);
};
