import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';

interface PatchPasswordSettingRequest {
  id: string;
  token: string;
  password: string;
}

export const usePasswordSetting = () => {
  const patchPasswordSetting = async (data: PatchPasswordSettingRequest) => {
    const apiClient = new ApiClient('password', '');
    const res = await apiClient.patch(data).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { patchPasswordSetting };
};
