import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { PageStyle } from 'models';

export const useGetPageStyle = () => {
  const { authInfo, currentUser } = useAuthInfo();
  const getPageStyle = async () => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`users/${authInfo.userId}/page_style`, idToken);
    const res = await apiClient.get<PageStyle>().catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { getPageStyle };
};
