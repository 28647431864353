import React from 'react';
import classnames from 'classnames';
import './WarningMessage.css';

interface WarningMessageProps {
  className?: string;
  message: string;
}

export const WarningMessage: React.FC<WarningMessageProps> = ({ message, className }) => {
  return <p className={classnames('attention__commonP1', className)}>{message}</p>;
};
