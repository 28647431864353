import React from 'react';

import { IonButton } from '@ionic/react';

import { INQUIRY_URL } from 'consts/app';

import styles from './Plan.module.scss';

export const Plan: React.FC = () => {
  const goToInquiry = () => {
    window.open(INQUIRY_URL, '_blank', 'noopener noreferrer');
  };

  return (
    <div className={styles.wrap}>
      <p className={styles.plan}>
        Proプランでは共通ヘッダーの非表示などよりパーソナルなプロフィールページをご利用いただくことができます。
        <br />
        <br />
        Pro Plan{'　　：　'}¥440（税込）/月
        <br />
        お支払い方法：クレジットカード決済
      </p>
      <div className={styles.buttons}>
        <IonButton className={styles.button} onClick={goToInquiry}>
          申し込む
        </IonButton>
      </div>
    </div>
  );
};
