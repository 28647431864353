import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';

interface PutProfileParams {
  profileMainImageKey?: string | null;
  accountName: string | null;
  businessName: string | null;
  businessNameFurigana: string | null;
  height: number | null;
  weight: number | null;
  footSize: number | null;
  bustSize: number | null;
  waistSize: number | null;
  hipSize: number | null;
  selfIntroduction: string | null;
}

export const useUpdateProfile = () => {
  const { authInfo, currentUser } = useAuthInfo();
  const putProfile = async (params: PutProfileParams) => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient(`users/${authInfo.userId}/profile`, idToken);
    const res = await apiClient.put(params).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { putProfile };
};
