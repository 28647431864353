import React, { useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonLoading } from '@ionic/react';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router';

import { useActivateUser } from 'api/hooks/user/useActivateUser';

import { MailChangeComplete } from 'components/setting/MailChangeComplete';
import { MailChangeError } from 'components/setting/MailChangeError';

import styles from './MailComplete.module.scss';

export const SettingMailCompletePage: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { postActivate } = useActivateUser();
  const [presentLoading, dismissLoading] = useIonLoading();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const reqData = {
    id: searchParams.get('id') || '',
    activationCode: searchParams.get('activation_code') || '',
  };

  useEffect(() => {
    presentLoading({
      duration: 2000,
    });

    // ユーザーをアクティベートAPI コール
    postActivate(reqData)
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        setErrorMessage(e.response?.data?.message || e.message);
      })
      .finally(() => {
        dismissLoading();
        setChecked(true);
      });
  }, []);

  if (!checked) return null;

  const isActivated = errorMessage?.includes('アクティベーション済みのコードです');
  const title = errorMessage
    ? isActivated
      ? 'メールアドレスの変更済'
      : 'メールアドレスの変更失敗'
    : 'メールアドレスの変更完了';
  const conent = errorMessage ? (
    <MailChangeError errorMessage={errorMessage} isActivated={isActivated} />
  ) : (
    <MailChangeComplete />
  );

  return (
    <IonPage>
      <IonHeader mode="ios" className={styles.header}>
        <IonToolbar mode="ios">
          <IonTitle className={styles.title}>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>{conent}</IonContent>
    </IonPage>
  );
};
