import { uploadBytes, ref } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

import { storage } from 'utils/firebase';

export const useUploadChatFile = () => {
  const uploadChatFile = (file: File, chatRoomKey: string) => {
    // 既存仕様に則りuuidを使用 ref: wemodel useSelectPhotoOrFiles.ts L112
    const uuid = uuidv4();
    // ファイルの拡張子取得
    const fileType = file.name.split('.').pop() ?? '';
    const storageRef = ref(storage, `${chatRoomKey}/${uuid}.${fileType}`);
    return uploadBytes(storageRef, file);
  };

  return { uploadChatFile };
};
