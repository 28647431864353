import { ApiClient } from 'api/apiClient';
import { useAuthInfo } from 'auth';
import { AxiosError } from 'axios';
import { Project } from 'models';

interface PostProjectRequest {
  name: string;
  status: number;
  startDate: string | null;
  endDate: string;
  clientId: number;
  description: string | null;
  contractPrice: number;
  thumbnailFileKey: string | null;
  contractFileKey: string | null;
  paymentDueDate: string | null;
  paymentDate: string | null;
}

export const useRegisterProject = () => {
  const { currentUser } = useAuthInfo();
  const postProject = async (data: PostProjectRequest): Promise<Project> => {
    const idToken = await currentUser?.getIdToken();
    const apiClient = new ApiClient('projects', idToken);
    const res = await apiClient.post<Project>(data).catch((e: AxiosError<{ message: string; type: string }>) => {
      throw e;
    });
    return res.data;
  };

  return { postProject };
};
