import { createContext, useState, useEffect } from 'react';
import type { User } from 'firebase/auth';

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from 'utils/firebase';

import { getAuthInfoFromStorage } from './storage';

export interface IAuthInfo {
  // undefined -> 未ログイン
  // あり -> ログイン済
  userId?: number;
}

interface IAuthContext {
  // undefined -> ログイン未チェック状態
  // User -> ログイン済
  // null -> 未ログイン
  currentUser: User | null | undefined;
  authInfo: IAuthInfo;
  isAuthChecking: boolean;
  updateAuthInfo: (authInfo: IAuthInfo, user: User | null | undefined) => void;
}

const initialContext: IAuthContext = {
  currentUser: undefined,
  authInfo: {
    userId: undefined,
  },
  isAuthChecking: true,
  updateAuthInfo: () => {} /* eslint-disable-line @typescript-eslint/no-empty-function */,
};

interface Props {
  children: JSX.Element;
}

export const AuthContext = createContext<IAuthContext>(initialContext);

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<User | null | undefined>(undefined);
  const [authInfo, setAuthInfo] = useState<IAuthInfo>(initialContext.authInfo);
  const [isAuthChecking, setIsAuthChecking] = useState<boolean>(initialContext.isAuthChecking);

  const updateAuthInfo = (authInfo: IAuthInfo, user: User | null | undefined) => {
    setAuthInfo(authInfo);
    setCurrentUser(user);
  };

  useEffect(() => {
    const authInfo2 = getAuthInfoFromStorage();
    if (authInfo2.userId) {
      setAuthInfo(authInfo2);
    }

    // firebase認証状態が自動的に行われる
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      // チャック完了にする
      setIsAuthChecking(false);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, authInfo, isAuthChecking, updateAuthInfo }}>
      {children}
    </AuthContext.Provider>
  );
};
