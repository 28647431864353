import {
  IonButton,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  useIonToast,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/react';

import React, { useState, useEffect, useRef } from 'react';
import { IonList, IonItem } from '@ionic/react';
import styles from './ProjectList.module.scss';
import { useGetProjects } from 'api/hooks/project/useGetProjects';
import { useDeleteProject } from 'api/hooks/project/useDeleteProject';
import { Chat, Project } from 'models';
import { useHistory } from 'react-router';
import { AxiosError } from 'axios';
import {
  PROJECT_ORDER_REGISTRATION,
  PROJECT_ORDER_SCHEDULE,
  PROJECT_STATUS_CONTRACTED,
  PROJECT_STATUS_DEPOSIT_COMPLETED,
  PROJECT_STATUS_NEGOTIATION,
} from 'consts';
import { useAtom } from 'jotai';
import { projectListAtom } from 'stores/project';
import { fireStore } from 'utils/firebase';
import { doc, DocumentReference, getDoc } from 'firebase/firestore';

const FETCH_PROJECT_LIMIT = 20;

interface ProjectWithUnread extends Project {
  unread?: boolean;
}

export const ProjectList: React.FC = () => {
  const [items, setItems] = useState<ProjectWithUnread[]>([]);
  const [isInitSearched, setIsInitSearched] = useState(false);
  const slidingItemsRefs = useRef<HTMLIonItemSlidingElement[] | null>([]);
  const [order, setOrder] = useState<number>(PROJECT_ORDER_SCHEDULE);
  const infiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);

  const [projectListAtomValue, setProjectListAtomValue] = useAtom(projectListAtom);

  const history = useHistory();
  const [presentToast] = useIonToast();
  const { getProjects } = useGetProjects();
  const { deleteProject } = useDeleteProject();

  const updateProjectUnreadInfo = async (projects: ProjectWithUnread[], items: ProjectWithUnread[]) => {
    const projectWithUnreadArray: ProjectWithUnread[] = await Promise.all(
      projects.map(async (project) => {
        if (!project.client.chatRoomKey) {
          return { ...project };
        }
        const chatDoc = doc(fireStore, 'chats', project.client.chatRoomKey) as DocumentReference<Chat>;
        const chat = await getDoc<Chat>(chatDoc);
        return {
          ...project,
          unread: chat.data()?.unread,
        };
      })
    );
    const map = new Map();
    projectWithUnreadArray.forEach((project) => {
      map.set(project.id, project.unread);
    });
    // 上記firestoreでチャット情報取得済の対象projectのみ、該当unread情報を更新する
    const newItems = items.map((project) => {
      if (map.has(project.id)) {
        return { ...project, unread: map.get(project.id) };
      }
      return project;
    });
    setItems(newItems);
  };

  const loadProjectList = () => {
    // 初期ロードの場合はLIMIT件数分、リフレッシュの場合は取得済の件数分を取得する
    const params = { sort: order, limit: items.length ? items.length + 1 : FETCH_PROJECT_LIMIT, offset: 0 };
    getProjects(params)
      .then((projects) => {
        // 取得済情報をその場ですぐ表示する
        setItems(projects);
        // 未読情報は後で更新する
        updateProjectUnreadInfo(projects, projects);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  const fetchMoreProjects = () => {
    // 無限スクロールの場合は更にLIMIT件数分を取得する
    getProjects({ sort: order, limit: FETCH_PROJECT_LIMIT, offset: items.length })
      .then((projects) => {
        const newItems = [...items, ...projects];
        // 取得済情報をその場ですぐ表示する
        setItems(newItems);
        // 未読情報は後で更新する
        updateProjectUnreadInfo(projects, newItems);
        // 無限スクロール動画表示を消す
        infiniteScrollRef.current?.complete();
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  const handleDeleteProject = async (index: number, id: number) => {
    await deleteProject(id)
      .then(() => {
        presentToast({
          message: '案件が削除されました。',
          duration: 2000,
        });
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });

    slidingItemsRefs.current && slidingItemsRefs.current[index].close();
    setItems(items.filter((project) => project.id !== id));
  };

  useEffect(() => {
    loadProjectList();
    setIsInitSearched(true);
  }, [order]);

  // Refreshフラグがtrueになったら、再検索する
  useEffect(() => {
    if (projectListAtomValue.isNeedRefresh && isInitSearched) {
      setProjectListAtomValue((prevState) => ({ ...prevState, isNeedRefresh: false }));
      loadProjectList();
    }
  }, [projectListAtomValue.isNeedRefresh]);

  return (
    <>
      <div className={styles.orderList}>
        <IonButton
          className={styles.ionButton}
          fill="clear"
          onClick={() => {
            setOrder(PROJECT_ORDER_REGISTRATION);
          }}
        >
          登録順
        </IonButton>
        |
        <IonButton
          className={styles.ionButton}
          fill="clear"
          onClick={() => {
            setOrder(PROJECT_ORDER_SCHEDULE);
          }}
        >
          日程順
        </IonButton>
      </div>
      <IonList>
        {items.map((item, index) => (
          <IonItemSliding
            key={item.id}
            ref={(el) => {
              if (slidingItemsRefs.current && el) {
                slidingItemsRefs.current[index] = el;
              }
            }}
          >
            <IonItem
              lines="full"
              onClick={() => {
                history.push(`/project/${item.id}`);
              }}
            >
              <div className={styles.projectCard}>
                <div>
                  {item.status === PROJECT_STATUS_NEGOTIATION && (
                    <div className={styles.projectStatusWrap}>
                      <div className={styles.projectStatusNegotiation}>交渉中</div>
                      {item.unread ? <div className={styles.projectLatestNotification}>●</div> : null}
                    </div>
                  )}
                  {item.status === PROJECT_STATUS_CONTRACTED && (
                    <div className={styles.projectStatusWrap}>
                      <div className={styles.projectStatusContracted}>契約済</div>
                      {item.unread ? <div className={styles.projectLatestNotification}>●</div> : null}
                    </div>
                  )}
                  {item.status === PROJECT_STATUS_DEPOSIT_COMPLETED && (
                    <div className={styles.projectStatusWrap}>
                      <div className={styles.projectStatusDepositCompleted}>入金済み</div>
                      {item.unread ? <div className={styles.projectLatestNotification}>●</div> : null}
                    </div>
                  )}
                  <div className={styles.projectTitle}>{item.name}</div>
                  <div className={styles.projectCompany}>{item.client.name}</div>
                </div>
                <img src={item.thumbnailFileUrl || '/assets/project/default_img.svg'} className={styles.projectImage} />
              </div>
            </IonItem>
            <IonItemOptions side="end">
              <IonItemOption
                color="danger"
                className={styles.deleteButton}
                onClick={() => {
                  handleDeleteProject(index, item.id);
                }}
              >
                削除
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
      </IonList>
      <IonInfiniteScroll onIonInfinite={fetchMoreProjects} ref={infiniteScrollRef}>
        <IonInfiniteScrollContent></IonInfiniteScrollContent>
      </IonInfiniteScroll>
    </>
  );
};
