import React, { useContext } from 'react';
import { IonIcon, NavContext } from '@ionic/react';
import { chevronBack } from 'ionicons/icons';

import styles from './BackButton.module.scss';

interface BackButtonProps {
  defaultHref?: string;
}

/**
 * @see https://ionicframework.com/docs/ja/api/back-button
 * IonBackButtonではヒストリーに基づいて遷移するため、
 * 常にdefaultHrefに遷移するとは限らないので
 * こちらのコンポーネントでは常にdefaultHrefに遷移できるようにする。
 */
export const BackButton: React.FC<BackButtonProps> = ({ defaultHref = '/' }) => {
  const navContext = useContext(NavContext);
  const onClick = (e: React.MouseEvent) => {
    if (navContext.hasIonicRouter()) {
      e.stopPropagation();
      navContext.goBack(defaultHref, undefined);
    } else if (defaultHref !== undefined) {
      window.location.href = defaultHref;
    }
  };

  return (
    <button aria-label="back" onClick={onClick} className={styles.button}>
      <IonIcon slot="end" icon={chevronBack} className={styles.icon} />
    </button>
  );
};
