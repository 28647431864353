import React from 'react';
import { useBasicAuthInfo } from 'basicAuth';

import { BasicAuthModal } from 'components/BasicAuthModal';

export const withBasicAuth = (WrappedComponent: React.FC) => {
  const WithBasicAuth = () => {
    const { authInfo, isAuthChecking } = useBasicAuthInfo();

    if (isAuthChecking) return null;

    return !authInfo.credentials ? <BasicAuthModal /> : <WrappedComponent />;
  };

  return WithBasicAuth;
};
