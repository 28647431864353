import { AxiosError } from 'axios';

import { ApiClient } from 'api/apiClient';
import { useBasicAuthInfo } from 'basicAuth';
import { User } from 'models/admin';

interface GetAdminUsersRequest {
  keyword: string;
}

type GetAdminUsersResponse = User[];

export const useSearchUser = () => {
  const { authInfo } = useBasicAuthInfo();

  const getAdminUsers = async (data: GetAdminUsersRequest): Promise<GetAdminUsersResponse> => {
    const apiClient = new ApiClient('admin/users', '');
    const res = await apiClient
      .get<GetAdminUsersResponse>(data, {
        headers: {
          Authorization: `Basic ${authInfo.credentials}`,
        },
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        throw e;
      });
    return res.data;
  };

  return { getAdminUsers };
};
