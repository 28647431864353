import React, { useState, useEffect } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonButton, IonImg } from '@ionic/react';

import styles from './index.module.scss';

import { withLogin } from 'pages/hocs/withLogin';
import { BackButton } from 'components/common/buttons/BackButton';
import { PhotoList } from 'components/profile_setting/PhotoList';
import { PhotoQuestionModal } from 'components/profile_setting/PhotoQuestionModal';

export const ProfileSettingPhotoPage: React.FC = withLogin(() => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [columnCnt, setColumnCnt] = useState(0);
  const [photoImgSize, setPhotoImgSize] = useState(0);
  const [isOpenQuestionModal, setIsOpenQuestionModal] = useState<boolean>(false);
  const closeModal = () => setIsOpenQuestionModal(false);

  useEffect(() => {
    const updatePhotoImgSize = () => {
      // カラム数
      let count = Math.round((window.innerWidth - 40 + 14) / (160 + 14));
      // 最低でも２列で表示する
      if (count < 2) {
        // 縮小表示する
        count = 2;
      }

      // フォトカードのサイズ
      const size = Math.round((window.innerWidth - 40 + 14) / count) - 14;

      setIsInitialized(true);
      setColumnCnt(count);
      setPhotoImgSize(size);
    };

    // スマホの場合の画像表示幅を調整する必要があるため、didMount時に設定値を更新する
    updatePhotoImgSize();

    // PCの場合は、画面リサイズが発生する場合があるため、その時はが画像が２列で表示できるように調整する
    window.addEventListener('resize', updatePhotoImgSize);

    return () => {
      window.removeEventListener('resize', updatePhotoImgSize);
    };
  }, []);

  if (!isInitialized) return null;

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <IonButtons className="header">
            <BackButton defaultHref="/profile_setting" />
            <IonTitle className={styles.title}>フォト</IonTitle>
            <IonButton className={styles.question} onClick={() => setIsOpenQuestionModal(true)}>
              <IonImg src={'assets/profile_setting/icn__question.svg'} alt="question" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PhotoList photoImgSize={photoImgSize} columnCnt={columnCnt} />
        <PhotoQuestionModal isOpenModal={isOpenQuestionModal} closeModal={closeModal} />
      </IonContent>
    </IonPage>
  );
});
