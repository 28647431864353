import { AxiosError } from 'axios';
import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonImg,
  IonPage,
  IonText,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import { useAtom } from 'jotai';
import { doc, DocumentReference, getDoc } from '@firebase/firestore';

import { fireStore } from 'utils/firebase';
import { useClientGet } from 'api/hooks/client';
import { useGetProfileUser } from 'api/hooks/profile/useGetProfileUser';
import { withLogin } from 'pages/hocs/withLogin';

import { ChatModelContent } from 'components/chat/ChatModelContent';
import { ChatFooterForm } from 'components/chat/ChatFooterForm';

import { projectDetailAtom } from 'stores/project';
import { Chat, Client, ProfileUser } from 'models';
import { SENDER_CREATOR } from 'consts';

import styles from './ChatModelPage.module.scss';

export const ChatModelPage: FC = withLogin(() => {
  const [, setProjectDetailAtomValue] = useAtom(projectDetailAtom);

  const [client, setClient] = useState<Client>();
  const [profileUser, setProfileUser] = useState<ProfileUser>();

  const location = useLocation<{ returnUrl: string }>();
  const searchParams = new URLSearchParams(location.search);

  const chatRoomKey = useParams<{ chatRoomKey: string }>().chatRoomKey;
  const [presentToast] = useIonToast();
  const { getClientInfo } = useClientGet();
  const { getProfileUser } = useGetProfileUser();

  const getChat = async (chatRoomKey: string) => {
    const chatDoc = doc(fireStore, 'chats', chatRoomKey) as DocumentReference<Chat>;
    const chat = await getDoc<Chat>(chatDoc);
    return chat.data();
  };

  const getProfileUserInfo = (userId: number) => {
    getProfileUser(userId)
      .then((res) => {
        setProfileUser(res);
      })
      .catch((e: AxiosError<{ message: string; type: string }>) => {
        presentToast({
          message: e.response?.data?.message,
          duration: 2000,
        });
      });
  };

  const copyTextToClipboard = () => {
    const fullPath = `${window.location.origin}${location.pathname}`;
    const replaceClientUrl = fullPath.replace(/model$/, 'client');
    navigator.clipboard
      .writeText(replaceClientUrl)
      .then(() => {
        presentToast({
          message: 'クライアント用チャットURLをコピーしました。',
          duration: 2000,
        });
      })
      .catch((e) => {
        console.error(e);
        presentToast({
          message: 'URLのコピーにエラーが発生しました',
          duration: 2000,
        });
      });
  };

  useEffect(() => {
    getChat(chatRoomKey)
      .then((chat) => {
        if (!chat) return;

        // プロフィールユーザー情報を取得
        getProfileUserInfo(chat.userID);

        // クライアント情報を取得
        getClientInfo(chat.clientID)
          .then((res) => {
            setClient(res);
          })
          .catch((e: AxiosError<{ message: string; type: string }>) => {
            presentToast({
              message: e.response?.data?.message,
              duration: 2000,
            });
          });
      })
      .catch((e) => {
        console.error(e);
        presentToast({
          message: '通信エラーが発生しました。しばらくたってからもう一度お試しください。',
          duration: 2000,
        });
      });
  }, []);

  // 案件詳細に戻る時に強制再検索させる
  useEffect(() => {
    return () => {
      setProjectDetailAtomValue((prevState) => ({ ...prevState, isNeedRefresh: true }));
    };
  }, []);

  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar className="header">
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={searchParams.get('returnUrl') || '/project'}
              color={'dark'}
              text=""
              className={styles.headerCloseButton}
              icon={closeOutline}
            />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={() => copyTextToClipboard()}>
              <IonImg src={'assets/chat/icn__share.svg'} alt="URL copy icon" />
            </IonButton>
          </IonButtons>
          <IonText className={styles.headerTitle}>
            <span className={styles.headerTitleSpan}>{client ? `${client.name} | ${client.staff}` : null}</span>
          </IonText>
        </IonToolbar>
      </IonHeader>
      {client ? <ChatModelContent clientName={client.name} /> : null}
      <ChatFooterForm senderType={SENDER_CREATOR} clientID={client?.id} modelName={profileUser?.name || ''} />
    </IonPage>
  );
});
